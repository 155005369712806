import React from 'react'
import styled from 'styled-components'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { FormGroup, FormText, FormFeedback, Label, InputGroup } from 'reactstrap'

import MomentLocaleUtils, {
  formatDate,
  parseDate
} from 'react-day-picker/moment'

const DatePreflight = ({ className, label, help, error, add, required, disabled, readOnly, month, modifiers, ...props }) => {
  const validProps = !error ? { valid: true } : { invalid: true }
  const MONTHS = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ]
  const MONTHS_SHORT = [
    'Ene.',
    'Feb.',
    'Mar.',
    'Abr.',
    'May.',
    'Jun.',
    'Jul.',
    'Ago.',
    'Sep.',
    'Oct.',
    'Nov.',
    'Dic.'
  ]
  const WEEKDAYS_LONG = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miercoles',
    'Jueves',
    'Viernes',
    'Sabado'
  ]
  const WEEKDAYS_SHORT = ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'];


  return (
    <FormGroup style={{ marginBottom: 'none !important' }}>
      {label && <Label>{label}</Label>}
      <InputGroup>
        <DayPickerInput
          {...props}
          parseDate={parseDate}
          format='LL'
          inputProps={{ className, required, disabled, readOnly, style: { zIndex: 9999 } }}
          formatDate={formatDate}
          dayPickerProps={{
            locale: 'Es',
            localeUtils: MomentLocaleUtils,
            month,
            months: MONTHS,
            monthsShort: MONTHS_SHORT,
            weekdaysLong: WEEKDAYS_LONG,
            weekdaysShort: WEEKDAYS_SHORT,
            modifiers
          }}
        />
        {add}
        {(error) && <FormFeedback {...validProps}>{error}</FormFeedback>}
        {help && <FormText>{help}</FormText>}
      </InputGroup>
    </FormGroup>
  )
}

export default styled(DatePreflight)`
  border: 1px solid ${({ color }) => color || '#EA1D23'};
  box-shadow: none;
  outline: none;
  flex: 1;
  width: 100%;
  padding: 6px 12px;
  position: relative;
  border-radius: ${({ add }) => add ? '4px 0 0 4px' : '4px'};
  
  &:disabled {
    background-color: #e9ecef;
  }
`
