import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AuthActions } from './duck'
import { Anchor, Box, Heading } from 'grommet'
import { Box as Div } from 'reflexbox'
import { push } from 'connected-react-router'

import withoutLogin from 'components/HOC/withoutLogin'
import withTheme from 'components/HOC/withTheme'

import LoginForm from 'forms/LoginForm'
import Input from 'components/Controlled/Input'
import Button from 'components/Controlled/Button'
import { Typography } from 'antd'

const { Title } = Typography

const dispatch = {
  login: AuthActions.requestLogin,
  navigate: push
}

const state = state => ({
  loading: state.auth.loading
})

@withTheme
@withoutLogin
@connect(state, dispatch)
class Login extends Component {
  submitLogin = vals => {
    const { email, password, remember = false } = vals

    this.props.login(email, password, remember)
  }

  goToReset = () => {
    this.props.navigate('/login/reset')
  }

  render = () => {
    const { theme, loading } = this.props

    return (
      <LoginForm onSubmit={this.submitLogin}>
        {({ isValid, fields }) => (
          <Div
            style={{ display: 'block!important' }}
            w={1}
          >
            <Title
              level={4}
              style={{ color: theme.primary, marginBottom: '30px' }}
            >
              Inicie sesión para continuar
            </Title>
            <div
              style={{ margin: '20px 0' }}
            >
              <Input
                {...fields.email.props}
                autoComplete='new-password'
                style={{ display: 'block!important' }}
                error={fields.email.error}
                disabled={loading}
                valid={!fields.email.error}
                color={theme.primary}
                bsSize='lg'
                label='Correo'
                type='email'
                required
                placeholder='usuario@emsi.com.mx'
              />
            </div>
            <div
              style={{ margin: '20px 0' }}
            >
              <Input
                {...fields.password.props}
                autoComplete='new-password'
                disabled={loading}
                error={fields.password.error}
                valid={!fields.password.error}
                color={theme.primary}
                label='Contraseña'
                bsSize='lg'
                type='password'
                password
                required
                placeholder='Contraseña'
              />
            </div>
            <Box
              fill
              justify='end'
              style={{ marginBottom: '10px' }}
            >
              <Anchor onClick={this.goToReset}
                style={{ color: '#636363' }}
                margin={{ vertical: 'small' }}>Olvidé mi contraseña</Anchor>
            </Box>
            <Button
              fill
              disabled={!isValid || loading}
              type='submit'
              color={theme.primary}
            >
              Iniciar sesión
            </Button>
          </Div>
        )}
      </LoginForm>
    )
  }
}

export default Login
