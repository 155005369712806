import { Types } from '../../duck/operations'

const UFUpdateLoading = (state = INITIAL_STATE, { status }) => {
  return ({
    ...state,
    UFLoading: status
  })
}
const UFSetAccountCosts = (state = INITIAL_STATE, { params }) => {
  return ({
    ...state,
    UFAcountCosts: params.data,
    UFMeta: params,
    UFLoading: false
  })
}

const UFSetAccountCostsGraph = (state = INITIAL_STATE, { params }) => {
  return ({
    ...state,
    UFAcountCostsGraph: params.data,
    UFMeta: params,
    UFLoading: false
  })
}

const UFSetAccountCostsSecond = (state = INITIAL_STATE, { params }) => {
  return ({
    ...state,
    UFAcountCostsSecond: params.data,
    UFMetaSecond: params,
    UFLoading: false
  })
}

const UFGetAccountCosts = (state = INITIAL_STATE, { params }) => {
  return ({
    ...state,
    childBudgets: { },
    ChargeTypeByACLevel1TC: { },
    AcountCostByCTTC: { },
    SuppliesByCTTC: { },
    AccountCostChilds: { },
    magicByAccountCost: { }
  })
}

const UFUpdateExcelDone = (state = INITIAL_STATE, { params }) => {
  return ({
    ...state,
    excelFactorsUpdate: true
  })
}

const UFGetAccountCostsGraph = (state = INITIAL_STATE, { params }) => {
  return ({
    ...state,
    childBudgetsGraph: [],
    ChargeTypeByACLevel1TCGraph: [],
    UFLoading: true,
    AcountCostByCTTCGraph: [],
    SuppliesByCTTCGraph: { },
    magicByAccountCostGraph: { }
  })
}

const UFGetAccountCostsSecond = (state = INITIAL_STATE, { params }) => {
  return ({
    ...state,
    childBudgetsSecond: { },
    ChargeTypeByACLevel1TCSecond: { },
    AcountCostByCTTCSecond: { },
    SuppliesByCTTCSecond: { },
    AccountCostChildsSecond: { },
    magicByAccountCostSecond: { }
  })
}

const UFSetAccountCostsById = (state = INITIAL_STATE, { params }) => {
  let auxObj = { ...state.UFAcountCostsById }

  params.data.forEach((e) => {
    if (e.id) {
      auxObj[e.id] = e
    }
  })
  // auxObj[params.data.id] = params.data
  return ({
    ...state,
    UFAcountCostsById: auxObj,
    UFLoading: false
  })
}
const UFSetAccountCostEditing = (state = INITIAL_STATE, { params }) => {
  return ({
    ...state,
    UFAcountCostEditing: params
  })
}
const UFUpdateAccountCostEditing = (state = INITIAL_STATE, { params }) => {
  const nState = {
    ...state,
    UFAcountCostEditing: params
  }
  return (nState)
}

const UFSaveAccountCostSuccess = (state = INITIAL_STATE, { params }) => {
  return {
    ...state,
    UFLoading: false,
    UFAcountCostsById: {},
    date: Date.now()
  }
}
const UFSetConceptsToEditing = (state = INITIAL_STATE, { params }) => {
  const nState = {
    ...state,
    UFAcountCostEditing: {
      ...state.UFAcountCostEditing,
      data: params
    }
  }
  return (nState)
}
const UFUpdateTextSearch = (state = INITIAL_STATE, { text }) => {
  return {
    ...state,
    UFTextSearch: text
  }
}
const UFUpdateSearchBy = (state = INITIAL_STATE, { value }) => {
  return {
    ...state,
    UFSearchBy: value
  }
}
// UFGetUnitesGenerals
export const unit_state_handles = {
  [Types.U_F_UPDATE_LOADING]: UFUpdateLoading,
  [Types.U_F_SET_ACCOUNT_COSTS]: UFSetAccountCosts,
  [Types.U_F_SET_ACCOUNT_COSTS_GRAPH]: UFSetAccountCostsGraph,
  [Types.U_F_SET_ACCOUNT_COSTS_SECOND]: UFSetAccountCostsSecond,
  [Types.U_F_GET_ACCOUNT_COSTS]: UFGetAccountCosts,
  [Types.U_F_UPDATE_EXCEL_DONE]: UFUpdateExcelDone,
  [Types.U_F_GET_ACCOUNT_COSTS_GRAPH]: UFGetAccountCostsGraph,
  [Types.U_F_GET_ACCOUNT_COSTS_SECOND]: UFGetAccountCostsSecond,
  [Types.U_F_SET_ACCOUNT_COSTS_BY_ID]: UFSetAccountCostsById,
  [Types.U_F_SET_ACCOUNT_COST_EDITING]: UFSetAccountCostEditing,
  [Types.U_F_UPDATE_ACCOUNT_COST_EDITING]: UFUpdateAccountCostEditing,
  [Types.U_F_SAVE_ACCOUNT_COST_SUCCESS]: UFSaveAccountCostSuccess,
  [Types.U_F_SET_CONCEPTS_TO_EDITING]: UFSetConceptsToEditing,
  [Types.U_F_UPDATE_TEXT_SEARCH]: UFUpdateTextSearch,
  [Types.U_F_UPDATE_SEARCH_BY]: UFUpdateSearchBy
}
