import { combineCycles } from 'redux-cycles'
import { AuthTypes } from 'app/auth/duck'
import { Actions, Types } from './operations'
import { push } from 'connected-react-router'
import combine from 'xstream/extra/sampleCombine'
import xs from 'xstream'

const alertOnLogout = ({ ACTION }) => {
  const handleLogout$ = ACTION
    .filter(({ type }) => type === AuthTypes.LOGOUT_SUCCESS)

  const handleTokenExpiration$ = ACTION
    .filter(({ type }) => type === AuthTypes.TOKEN_EXPIRED)

  const sendAlert$ = xs.merge(handleLogout$, handleTokenExpiration$)
    .mapTo(Actions.requestAlert('Sesión terminada', 'Su sesión se ha cerrado correctamente', '/login'))

  return {
    ACTION: sendAlert$
  }
}

const maybeRedirect = ({ ACTION, STATE }) => {
  const redirect$ = STATE.map(state => state.alerts.alert || {})

  const handleHide$ = ACTION
    .filter(({ type }) => type === Types.HIDE_ALERT)

  const redirectIfNecessary$ = handleHide$
    .compose(combine(redirect$))
    .filter(([, { redirect }]) => redirect)
    .map(([, { redirect }]) => push(redirect))

  return {
    ACTION: redirectIfNecessary$
  }
}

export default combineCycles(
  alertOnLogout,
  maybeRedirect
)
