import React from 'react'
import { Box, Heading, Text } from 'grommet'
import styled from 'styled-components'
import Logo from "../Logo"

const OrgContainer = styled(Box)`
  width: 100%;
  border: ${({ selected }) => selected ? '2px solid rgba(0, 0, 0, 0.2)' : '2px solid rgba(0, 0, 0, 0.1)'};
  cursor: pointer;
`

const OrgLogo = ({ name, logoUrl, color }) => !logoUrl
  ? <Heading style={{ margin: 0 }} color={color} size='medium' truncate>{name}</Heading>
  : <Logo src={logoUrl} size={5 / 12} />

export default React.memo(({
  color,
  name = 'Sin nombre',
  logoUrl,
  id,
  selected = false,
  onSelect,
  ...props
}) => {
  return (
    <OrgContainer
      {...props}
      flex
      onClick={() => onSelect(!selected, id)}
      direction='column'
      selectColor={'#ffffff'}
      align='center'
      justify='center'
      round='small'
      pad='small'
      selected={selected}>
      <Box
        margin={{ vertical: 'xsmall' }}
      >
        <OrgLogo name={name} logoUrl={logoUrl} color={color} />
        {/*<Logo size={1 / 2} />*/}
      </Box>
      <Box
        direction='row'
        align='center'
        margin={{ vertical: 'xsmall' }}
        justify='center'
      >
        <Text weight='bold' margin={{ right: 'xsmall' }}>Nombre:</Text> {name}
      </Box>
    </OrgContainer>
  )
})
