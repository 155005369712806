import { createReducer } from 'reduxsauce'
import { Types } from './operations'

const INITIAL_STATE = {
  firstPage: null
}

const updateRedirect = (state = INITIAL_STATE, { payload: { location } }) => ({
  ...state,
  firstPage: state.firstPage || location.pathname !== '/login' ? location.pathname : '/'
})

const HANDLERS = {
  //['@@router/LOCATION_CHANGE']: updateRedirect
}

export default createReducer(INITIAL_STATE, HANDLERS)
