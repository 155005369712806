import React, { Component } from 'react'
import posed from 'react-pose'
import styled from 'styled-components'

import hexToRGBA from 'hex-to-rgba'

const LoadingAnimation = posed.div({
  bigger: { scale: 1.4, transition: { ease: 'circOut' } },
  normal: { scale: 1, transition: { ease: 'circOut' } }
})

const Loading = styled(LoadingAnimation)`
  border-radius: 9999px;
  border: ${({ size }) => size ? size / 10 : 10}px solid ${({ color }) => hexToRGBA(color || '#C13B3B', '0.8')};
  width: ${({ size }) => size ? size / 100 : 100}px;
  height: ${({ size }) => size ? size / 100 : 100}px;
`

export default class LoadingComponent extends Component {
  state = { pose: 'normal' }

  componentDidMount = () => {
    const { interval = 500 } = this.props
    this._interval = setInterval(() =>
      this.setState(prevState =>
        ({ pose: prevState.pose === 'normal' ? 'bigger' : 'normal' })
      ), interval)
  }

  componentWillUnmount = () => {
    if (this._interval) clearInterval(this._interval)
  }

  render = () => {
    const { color, size } = this.props
    const { pose } = this.state
    return <Loading color={color} size={size} pose={pose} />
  }
}
