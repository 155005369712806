import { combineCycles } from 'redux-cycles'
import { Types, Actions } from './operations'
import { NotificationsActions } from 'app/notifications/duck'
import xs from 'xstream'
import dropUntil from 'xstream/extra/dropUntil'
import combine from 'xstream/extra/sampleCombine'
import { push } from 'connected-react-router'
import { RoutesActions } from 'app/routes/duck'
import { handleHTTPError, ifTypeIs, getToken } from 'helpers/cycles'
import { AuthActions } from 'app/auth/duck'

const { REACT_APP_API } = process.env

const fetchUsers = ({ ACTION, STATE, HTTP, STORAGE }) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})

  const handleAction$ = ACTION
    .filter(({ type }) => type === '@@router/LOCATION_CHANGE')
    .filter(({ payload: { location: { pathname } } }) => pathname.includes('users'))
    .mapTo(Actions.fetchUsers())

  const triggerFetch$ = ACTION
    .filter(({ type }) => type === Types.FETCH_USERS)
    .compose(combine(userToken$, org$))
    .debug()
    .map(([, token, org]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/users`,
      headers: { 'Authorization': `Bearer ${token}` },
      method: 'GET',
      responseType: 'json',
      category: 'USERS_FETCH'
    }))

  const updateUsers$ = HTTP
    .select('USERS_FETCH')
    .map(handleHTTPError)
    .flatten()
    .map(({ body }) => {
      return !body.error
        ? Actions.updateUsers(body.users)
        : RoutesActions.handleError(body.error)
    })

  return {
    HTTP: triggerFetch$,
    ACTION: xs.merge(updateUsers$, handleAction$)
  }
}

const createUser = ({ ACTION, STATE, HTTP }) => {
  const userToken$ = STATE.map(state => state.auth.token)
  const org$ = STATE.map(state => state.auth.orgSelected || {})

  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.REQUEST_CREATION))
    .compose(combine(userToken$, org$))
    .map(([{ payload: { email, rol }, projects, id}, token, org]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/users${id ? `/${id}` : ''}`,
      headers: { Authorization: `Bearer ${token}` },
      method: id ? 'PUT' : 'POST',
      category: 'CREATE_USER',
      send: id
        ? ({
          email,
          is_super: rol === 'Super',
          is_admin: rol === 'Administrador',
          ...(projects ? { organization_projects: Object.keys(projects).map(id => ({ id, role: projects[id] })) } : {})
        })
        : {
          users: [
            {
              email,
              is_super: rol === 'Super',
              is_admin: rol === 'Administrador',
              ...(projects ? { organization_projects: Object.keys(projects).map(id => ({ id, role: projects[id] })) } : {})
            }
          ]
        }
    }))

  const getResponse$ = HTTP
    .select('CREATE_USER')
    .map(handleHTTPError)
    .flatten()
    .map(({ body }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.creationSuccess())

  const onSuccess$ = ACTION
    .filter(ifTypeIs(Types.CREATION_SUCCESS))

  const sendNotification$ = onSuccess$
    .mapTo(NotificationsActions.requestNotification('Usuario creado', 'info'))

  const updateOrgs$ = onSuccess$
    .mapTo(Actions.fetchUsers())

  return {
    HTTP: handleRequest$,
    ACTION: xs.merge(getResponse$, sendNotification$, updateOrgs$)
  }
}

const deleteUser = ({ ACTION, STATE, HTTP }) => {
  const userToken$ = STATE.map(state => state.auth.token)
  const org$ = STATE.map(state => state.auth.orgSelected || {})

  const handleDelete$ = ACTION
    .filter(ifTypeIs(Types.DELETE_USER))
    .compose(combine(userToken$, org$))
    .map(([{ id }, token, org]) => ({
      url: `${REACT_APP_API}/organization/${org.id}/users/${id}`,
      method: 'DELETE',
      headers: { 'Authorization': `Bearer ${token}` },
      category: 'DELETE_USER'
    }))

  const getDeleteUser$ = HTTP
    .select('DELETE_USER')
    .map(handleHTTPError)
    .flatten()
    .map(({ body }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.fetchUsers())

  return {
    HTTP: handleDelete$,
    ACTION: getDeleteUser$
  }
}

const resendInvite = ({ ACTION, STATE, HTTP }) => {
  const userToken$ = STATE.map(state => state.auth.token)
  const org$ = STATE.map(state => state.auth.orgSelected || {})

  const handleDelete$ = ACTION
    .filter(ifTypeIs(Types.RESEND_INVITE))
    .compose(combine(userToken$, org$))
    .map(([{ id }, token, org]) => ({
      url: `${REACT_APP_API}/organization/${org.id}/resendInvite/${id}`,
      method: 'GET',
      headers: { 'Authorization': `Bearer ${token}` },
      category: 'RESEND_INVITE'
    }))

  const getDeleteUser$ = HTTP
    .select('RESEND_INVITE')
    .map(handleHTTPError)
    .flatten()
    .map(({ body }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.fetchUsers())

  return {
    HTTP: handleDelete$,
    ACTION: getDeleteUser$
  }
}

const editUser = ({ ACTION, HTTP, STATE }) => {
  const userToken$ = STATE.map(state => state.auth.token)
  const org$ = STATE.map(state => state.auth.orgSelected || {})
  const userId$ = STATE.map(state => state.auth.user || {})

  const sendEdit$ = ACTION
    .filter(ifTypeIs(Types.EDIT_USER))
    .compose(combine(userToken$, org$, userId$))
    .map(([ { payload }, token, org, user ]) => ({
      url: `${REACT_APP_API}/organization/${org.id}/users/${user.id}`,
      method: 'PUT',
      headers: { 'Authorization': `Bearer ${token}` },
      send: payload,
      category: 'EDIT_USER'
    }))

  const getResponse$ = HTTP
    .select('EDIT_USER')
    .map(handleHTTPError)
    .flatten()
    .map(({ body }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.editUserSuccess())

  const onUserEdit$ = ACTION
    .filter(ifTypeIs(Types.EDIT_USER_SUCCESS))

  const sendNotification$ = onUserEdit$
    .mapTo(NotificationsActions.requestNotification('Operacion exitorsa', 'info'))

  const fetchUserInfo$ = onUserEdit$
    .mapTo(AuthActions.fetchUser())

  return {
    HTTP: sendEdit$,
    ACTION: xs.merge(getResponse$, sendNotification$, fetchUserInfo$)
  }
}

export default combineCycles(
  fetchUsers,
  createUser,
  deleteUser,
  resendInvite,
  editUser
)
