import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'

import Login from './Login'
import First from './FirstLogin'
import Reset from './ResetPassword'
import { Box, Text } from 'grommet'
import Logo from 'components/Logo'
import LoginContent from 'components/Login/LoginContent'
import withTheme from 'components/HOC/withTheme'
import LoginFooter from 'components/Login/LoginFooter'
import Select from 'app/orgs/Select'
import Overlay from 'components/OverlayLoading'

const state = state => ({
  loading: state.auth.loading
})

@connect(state)
@withRouter
@withTheme
class Authentication extends Component {

  render = () => {
    const { theme, match, loading } = this.props

    return (
      <Box
        flex={false}
        fill
        basis='full'
        align='center'
        background='light-2'
      >
        <Box margin={{ vertical: 'medium' }}>
          <Logo size={0.8} />
        </Box>
        <LoginContent>
          <Box
            background='white'
            round='small'
            pad='large'
            width='500px'
          >
            <>
              <Overlay overlayColor={theme.primary} loaderColor={theme.font} pose={loading ? 'in' : 'out'} />
              <Switch>
                <Route exact path={`${match.url}/reset`} component={Reset} />
                <Route exact path={`${match.url}/first`} component={First} />
                <Route exact path={match.url} component={Login} />

                {/** SELECT ORGANIZATION **/}
                <Route exact path={`${match.url}/select`} component={Select} />
                <Redirect to='/login' />
              </Switch>
            </>
          </Box>
        </LoginContent>
        <LoginFooter
          direction='row'
          align='center'
          fill
          alignContent='center'
          justify='center'
        >
          <Box margin='small'>
            <Text color='light-6'>Powered by</Text>
          </Box>
          <Box margin='small'>
            <Logo size={1 / 2} />
          </Box>
        </LoginFooter>
      </Box>
    )
  }
}

export default Authentication
