import { combineCycles } from 'redux-cycles'
import {Actions, Types} from './operations'
import { push } from 'connected-react-router'
import sampleCombine from 'xstream/extra/sampleCombine'
import dropUntil from 'xstream/extra/dropUntil'
import xs from 'xstream'
import { AuthActions, AuthTypes } from 'app/auth/duck'
import { NotificationsActions } from 'app/notifications/duck'
import { ifTypeIs } from 'helpers/cycles'

const checkLogin = ({ ACTION, STATE }) => {
  const hasLoggedIn$ = STATE.map(state => state.auth.logged)
  const location$ = STATE.map(state => ({ payload: { location: state.router.location } }))

  const authChecked$ = ACTION
    .filter(({ type }) => type === AuthTypes.SET_AUTH_STATUS)
    .compose(sampleCombine(location$))
    .map(([{ status: isLogged }, payload]) => [payload, isLogged])

  const handleAction$ = ACTION
    .filter(({ type }) => type === '@@router/LOCATION_CHANGE')
    .map(payload => xs.combine(xs.of(payload), hasLoggedIn$))
    .flatten()
    .take(1)

  const authRouting$ = xs.merge(handleAction$, authChecked$)
    .filter(([{ payload: { location } }, isLogged]) => !isLogged || location.pathname !== '/login')
    .mapTo(push('/login'))

  return {
    ACTION: authRouting$
  }
}

const handleErrors = ({ ACTION }) => {
  const handleErrorAction$ = ACTION
    .filter(({ type }) => type === Types.HANDLE_ERROR)
    .map(({ error }) => error === 'TOKEN_EXPIRED'
      ? AuthActions.requestLogout()
      : NotificationsActions.requestNotification(error, 'error')
    )

  return {
    ACTION: handleErrorAction$
  }
}

export default combineCycles(
  // checkLogin,
  handleErrors
)
