import { createReducer } from 'reduxsauce'
import { Types } from './operations'
import {RoutesTypes} from 'app/routes/duck'

const INITIAL_STATE = {
  fetchingOrgs: false,
  orgs: []
}

const updateOrgs = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  orgs: payload,
  fetchingOrgs: false
})

const fetchingOrgs = (state = INITIAL_STATE) => ({
  ...state,
  fetchingOrgs: true
})

const handleError = (state = INITIAL_STATE) => ({
  ...state,
  fetchingOrgs: false
})

const HANDLERS = {
  [Types.UPDATE_ORGS]: updateOrgs,
  [Types.FETCH_ORGS]: fetchingOrgs,
  [Types.ERROR]: handleError,
  [Types.DELETE_ORG]: fetchingOrgs,
  [RoutesTypes.HANDLE_ERROR]: handleError
}

export default createReducer(INITIAL_STATE, HANDLERS)
