import React from "react";

const SvgOjoCerrado = props => (
  <svg viewBox="0 0 280.7 280.7" {...props}>
    <path d="M124 160.8c3.1.8 4.9 3.9 4.1 7l-8 30.3c-.8 3.1-3.9 4.9-7 4.1-3.1-.8-4.9-3.9-4.1-7l8-30.3c.8-3.1 3.9-4.9 7-4.1zM171.9 202.2c3.1-.8 4.9-3.9 4.1-7l-8-30.3c-.8-3.1-3.9-4.9-7-4.1-3.1.8-4.9 3.9-4.1 7l8 30.3c.8 3.1 3.9 4.9 7 4.1zM87.5 142.4c2.8 1.4 3.9 4.9 2.5 7.7l-14.3 27.8c-1.4 2.8-4.9 3.9-7.7 2.5s-3.9-4.9-2.5-7.7l14.3-27.8c1.4-2.9 4.9-4 7.7-2.5zM45.1 113.2c2.3 2.2 2.5 5.8.3 8.1l-21.3 23c-2.2 2.3-5.8 2.5-8.1.3s-2.5-5.8-.3-8.1l21.3-23c2.1-2.3 5.8-2.5 8.1-.3zM267.2 145.5c2.5-2 2.8-5.6.8-8.1l-19.8-24.3c-2-2.5-5.6-2.8-8.1-.8-2.5 2-2.8 5.6-.8 8.1l19.8 24.3c2 2.4 5.6 2.8 8.1.8zM223.4 180.4c2.8-1.4 3.9-4.9 2.5-7.7l-14.3-27.8c-1.4-2.8-4.9-3.9-7.7-2.5s-3.9 4.9-2.5 7.7l14.3 27.8c1.4 2.8 4.9 4 7.7 2.5z" />
    <path d="M253 96.3c1.2 0 2.4.4 3.5 1.2 2.5 1.9 3 5.5 1.1 8s-47.1 61.4-112.3 63c-40.1 1-78.6-20.1-114.3-62.8-2-2.4-1.7-6.1.7-8.1s6.1-1.7 8.1.7c33.3 39.8 68.7 59.4 105.2 58.7 59.7-1.5 103-57.9 103.4-58.5 1.1-1.4 2.8-2.2 4.6-2.2z" />
  </svg>
);

export default SvgOjoCerrado;
