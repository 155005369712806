import React from "react";

const SvgAbiertoojo = props => (
  <svg viewBox="0 0 280.7 280.7" {...props}>
    <path
      className="Abiertoojo_svg__st0"
      d="M252.1 156.6c-.5-.6-13.5-14.9-33.7-29.4-12-8.5-24-15.3-35.7-20.2-14.9-6.2-29.4-9.4-43.2-9.4s-28.3 3.2-43.2 9.4c-11.8 4.9-23.8 11.7-35.7 20.2-20.3 14.5-33.2 28.8-33.7 29.4-2 2.2-2 5.5 0 7.6.5.6 13.5 14.9 33.7 29.4 12 8.5 24 15.3 35.7 20.2 14.9 6.2 29.4 9.4 43.2 9.4s28.3-3.2 43.2-9.4c11.8-4.9 23.8-11.7 35.7-20.2 20.3-14.5 33.2-28.8 33.7-29.4 1.9-2.1 1.9-5.4 0-7.6zM168.7 114c10.4 8.7 16.3 21.4 16.3 35 0 25.2-20.5 45.7-45.7 45.7S93.7 174.2 93.7 149c0-13.6 5.9-26.2 16.3-35 9.4-3.1 19.3-5 29.4-5 10 0 20 2 29.3 5zm42.8 70.5c-17.5 12.5-44.2 27.3-72.1 27.3s-54.6-14.9-72.1-27.3c-13.2-9.4-23.3-18.9-28.4-24 5.1-5.1 15.2-14.6 28.4-24 6.1-4.3 13.2-8.9 21.1-13.1-4 7.9-6.1 16.6-6.1 25.7 0 31.5 25.6 57.1 57.1 57.1s57.1-25.6 57.1-57.1c0-9.1-2.1-17.8-6.1-25.7 7.9 4.2 15.1 8.8 21.1 13.1 13.2 9.4 23.3 18.9 28.4 24-5.1 5-15.2 14.5-28.4 24z"
    />
    <path
      className="Abiertoojo_svg__st0"
      d="M158.8 105.8c-3.1-.8-4.9-3.9-4.1-7l7.9-30.1c.8-3.1 3.9-4.9 7-4.1s4.9 3.9 4.1 7l-7.9 30.1c-.9 3-4 4.9-7 4.1zM111.1 64.6c-3.1.8-4.9 3.9-4.1 7l7.9 30.1c.8 3.1 3.9 4.9 7 4.1 3.1-.8 4.9-3.9 4.1-7l-8-30.1c-.8-3.1-3.9-4.9-6.9-4.1zM195.1 124.1c-2.8-1.4-3.9-4.9-2.5-7.7l14.2-27.7c1.4-2.8 4.9-3.9 7.7-2.5s3.9 4.9 2.5 7.7l-14.2 27.7c-1.5 2.9-4.9 4-7.7 2.5zM237.3 153.2c-2.3-2.1-2.5-5.8-.3-8.1l21.2-22.9c2.1-2.3 5.8-2.5 8.1-.3 2.3 2.1 2.5 5.8.3 8.1l-21.2 22.9c-2.2 2.3-5.8 2.4-8.1.3zM16.3 121c-2.4 2-2.8 5.6-.8 8l19.7 24.1c2 2.4 5.6 2.8 8 .8 2.4-2 2.8-5.6.8-8l-19.7-24.1c-2-2.4-5.6-2.7-8-.8zM59.8 86.3c-2.8 1.4-3.9 4.9-2.5 7.7l14.2 27.7c1.4 2.8 4.9 3.9 7.7 2.5s3.9-4.9 2.5-7.7L67.5 88.8c-1.4-2.9-4.9-4-7.7-2.5z"
    />
  </svg>
);

export default SvgAbiertoojo;
