import React, { Component } from 'react'
import { Layer, Box, Heading } from 'grommet'
import { Close } from 'grommet-icons'

import Prompt from 'components/Prompt'

class Modal extends Component {
  render = () => {
    const { show, children, onClose, title } = this.props
    return (
      <Prompt pose={show ? 'enter' : 'exit'}>
        {show && (
          <Layer
            onClickOutside={onClose || (() => {})}
            onEsc={onClose || (() => {})}
            modal
          >
            <Box
              fill='horizontal'
              direction='column'
              pad='small'
            >
              <Box
                fill='horizontal'
                direction='row'
                justify={title ? 'between' : 'end'}
                align='center'
                margin={{ bottom: 'small' }}
              >
                {title && (<Heading size='small' margin='none'>{title}</Heading>)}
                <Close style={{ cursor: 'pointer' }} onClick={onClose || (() => {})} />
              </Box>
              {children}
            </Box>
          </Layer>
        )}
      </Prompt>
    )
  }
}

export default Modal
