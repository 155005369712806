import { createActions } from 'reduxsauce'

const { Creators, Types } = createActions({
  fetchUsers: null,
  updateUsers: ['payload'],
  error: ['error'],
  requestCreation: ['payload', 'projects', 'id'],
  creationSuccess: null,
  deleteUser: ['id'],
  resendInvite: ['id'],
  deleteSuccess: null,
  editUser: ['payload'],
  editUserSuccess: null
}, {
  prefix: 'users/'
})

export { Creators as Actions, Types }
