import React from 'react'
import styled from 'styled-components'
import posed from 'react-pose'

import hexToRGBA from 'hex-to-rgba'

import Loading from './Loading'

const OverlayPose = posed.div({
  in: { opacity: 1 },
  out: { opacity: 0 }
})

const Overlay = styled(OverlayPose)`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  border-radius: inherit;
  width: 100%;
  height: 100%;
  background: ${({ overlayColor, opacity }) => overlayColor ? hexToRGBA(overlayColor, opacity || '0.4') : hexToRGBA('#324d7b', opacity || '0.4')};
  pointer-events: none;
  z-index: 10;
`

export default React.memo(({ loaderColor, loaderSize, ...props }) => (
  <Overlay {...props}>
    <Loading color={loaderColor} size={loaderSize} />
    <br />
    <p style={{ position: 'absolute' }}>
      Cargando
    </p>
  </Overlay>
))
