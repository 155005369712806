import React from "react";

const SvgReportes = props => (
  <svg viewBox="0 0 283.5 283.5" {...props}>
    <path
      className="reportes_svg__st0"
      d="M85.1 244.5H68.2c-4.3 0-7.9-3.5-7.9-7.9V50.4c0-4.3 3.5-7.9 7.9-7.9h151.9c4.3 0 7.9 3.5 7.9 7.9v.4h9.4v-.4c0-9.6-7.8-17.3-17.3-17.3H68.2c-9.6 0-17.3 7.8-17.3 17.3v186.2c0 9.6 7.8 17.3 17.3 17.3h16.9v-9.4z"
    />
    <path
      className="reportes_svg__st0"
      d="M54.4 225.6H37.5c-4.3 0-7.9-3.5-7.9-7.9V31.5c0-4.3 3.5-7.9 7.9-7.9h151.9c4.3 0 7.9 3.5 7.9 7.9v.4h9.4v-.4c0-9.6-7.8-17.3-17.3-17.3H37.5c-9.6 0-17.3 7.8-17.3 17.3v186.2c0 9.6 7.8 17.3 17.3 17.3h16.9v-9.4z"
    />
    <path
      className="reportes_svg__st0"
      d="M249.7 269.3h-152c-9.6 0-17.3-7.8-17.3-17.3V65.8c0-9.6 7.8-17.3 17.3-17.3h151.9c9.6 0 17.3 7.8 17.3 17.3V252c.1 9.5-7.7 17.3-17.2 17.3zM97.7 57.9c-4.3 0-7.9 3.5-7.9 7.9V252c0 4.3 3.5 7.9 7.9 7.9h151.9c4.3 0 7.9-3.5 7.9-7.9V65.8c0-4.3-3.5-7.9-7.9-7.9H97.7z"
    />
    <path
      className="reportes_svg__st0"
      d="M114.7 185.4h20.1v56.7h-20.1zM143 114.6h20.1v127.6H143zM171.4 178.3h20.1v63.8h-20.1zM199.7 68.5h20.1v173.6h-20.1z"
    />
  </svg>
);

export default SvgReportes;
