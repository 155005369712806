import React, { Component } from 'react'
import { Heading, Box, FormField, CheckBox, TextInput, RadioButton } from 'grommet'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import Overlay from 'components/OverlayLoading'
import OrganizationBox from 'components/Login/OrganizationBox'
import { AuthActions } from 'app/auth/duck'
import Button from 'components/Controlled/Button'
import withTheme from 'components/HOC/withTheme'
import withAuthentication from 'components/HOC/withAuthentication'

import { Typography } from 'antd'

const { Title,Text } = Typography
const state = state => ({
  orgs: state.auth.userOrganizations,
  selected: state.auth.orgSelected,
  user: state.auth.user,
  loading: state.auth.settingOrg
})

const dispatch = {
  selectOrg: AuthActions.selectOrganization,
  navigate: push,
  logout: AuthActions.requestLogout,
  fetchUsers: AuthActions.fetchUser
}

@withAuthentication
@withTheme
@connect(state, dispatch)
class Select extends Component {
  componentDidMount = () => {
    if (this.props.orgs) this.props.fetchUsers()
  }

  changeSelection = (checked, key) => {
    console.log(key)
    this.props.selectOrg(key)
  }

  render = () => {
    const { loading, orgs, theme } = this.props
    console.log(orgs)
    return (
      <>
        <Overlay pose={(loading || !orgs) ? 'in' : 'out'} />
        <Title
          level={4}
          style={{ color: theme.primary, marginBottom: '30px' }}
        >
          Organizaciones
        </Title>
        {orgs && (<Text margin={{ horizontal: 'small', bottom: 'medium' }}>Selecciona alguna de las siguientes organizaciones para poder continuar:</Text>)}
        <div style={{ maxHeight: 300, overflowY: 'auto', padding: '0 10px' }}>
          <Box
            margin='small'
            justify='center'
            align='center'
          >
            {!orgs
              ? (
                <Text weight='bold'>No tienes ninguna organizacion asignada.</Text>
              ) : orgs.map(({ name, primary_color: color, logo_url: logo, id }, i) => (
                <OrganizationBox
                  onSelect={this.changeSelection}
                  key={i}
                  id={id}
                  name={name}
                  logoUrl={logo}
                  selected={false}
                  color={color}
                  margin={{ vertical: 'small' }}
                />
              ))}
          </Box>
        </div>
        <Button color={theme.primary} style={{ marginTop: 20 }} onClick={this.props.logout}>Cerrar sesión</Button>
      </>
    )
  }
}

export default Select
