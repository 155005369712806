import { combineCycles } from 'redux-cycles'
import { Types, Actions } from './operations'
import { NotificationsActions } from 'app/notifications/duck'
import xs from 'xstream'
import combine from 'xstream/extra/sampleCombine'
import until from 'xstream/extra/dropUntil'
import {getToken, handleHTTPError, ifTypeIs} from 'helpers/cycles'
import { RoutesActions } from 'app/routes/duck'
import { AuthActions, AuthTypes } from 'app/auth/duck'
import { push } from 'connected-react-router'

const { REACT_APP_API } = process.env

const fetchOrganizations = ({ ACTION, STATE, HTTP, STORAGE }) => {
  const userToken$ = getToken(STORAGE)

  const handleAction$ = ACTION
    .filter(({ type }) => type === '@@router/LOCATION_CHANGE')
    .filter(({ payload: { location: { pathname } } }) => pathname.includes('orgs'))
    .mapTo(Actions.fetchOrgs())
    .compose(until(userToken$.filter(t => t)))

  const triggerFetch$ = ACTION
    .filter(ifTypeIs(Types.FETCH_ORGS))
    .compose(until(userToken$))
    .compose(combine(userToken$))
    .compose(until(userToken$.filter(token => token)))
    .map(([, token]) => ({
      url: `${REACT_APP_API}/organizations`,
      headers: { Authorization: `Bearer ${token}` },
      method: 'GET',
      responseType: 'json',
      category: 'orgFetch'
    }))
    .debug()

  const updateOrganizations$ = HTTP
    .select('orgFetch')
    .map(handleHTTPError)
    .flatten()
    .map(({ body }) => {
      return !body.error
        ? Actions.updateOrgs(body.organizations)
        : RoutesActions.handleError(body.error)
    })

  return {
    HTTP: triggerFetch$,
    ACTION: xs.merge(updateOrganizations$, handleAction$)
  }
}

const onError = ({ ACTION }) => {
  const handleAction$ = ACTION
    .filter(({ type }) => type === Types.ERROR)
    .map(({ error }) => NotificationsActions.requestNotification(error, 'error'))

  return {
    ACTION: handleAction$
  }
}

const requestCustomization = ({ ACTION, HTTP, STATE, STORAGE }) => {
  const organization$ = STATE.map(state => state.auth.orgSelected || {})
  const userToken$ = getToken(STORAGE)

  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.REQUEST_ORG_CUSTOMIZATION))
    .compose(combine(organization$, userToken$))
    .map(([{ payload: { font_color, primary_color, logo } }, org, token]) => ({
      url: `${REACT_APP_API}/organizations/${org.id || 1}`,
      method: 'PUT',
      headers: { Authorization: `Bearer ${token}` },
      category: 'CUSTOMIZE_ORG',
      responseType: 'json',
      send: {
        ...(font_color ? { font_color } : {}),
        ...(logo ? { logo } : {}),
        ...(primary_color ? { primary_color } : {}),
        name: org.name
      }
    }))

  const getResponse$ = HTTP
    .select('CUSTOMIZE_ORG')
    .map(handleHTTPError)
    .flatten()
    .map(({ body }) => body.error
      ? NotificationsActions.requestNotification('No se pudo actualizar', 'error')
      : body.organization)

  const toStorage$ = getResponse$
    .filter(({ name }) => name)
    .compose(combine(organization$))
    .map(([org, selected]) => ({
      key: 'organization_selected',
      value: JSON.stringify({
        ...selected,
        ...({
          ...org,
          logo_url: `${org.logo_url}?${Date.now()}`
        })
      })
    }))

  const update$ = getResponse$
    .filter(({ name }) => name)
    .compose(combine(organization$))
    .map(([org, selected]) => AuthActions.organizationConfigured({
      ...selected,
      ...({
        ...org,
        logo_url: `${org.logo_url}?${Date.now()}`
      })
    }))

  const sendNotification$ = getResponse$
    .filter(({ name }) => name)
    .mapTo(NotificationsActions.requestNotification('Operacion completada', 'info'))

  return {
    HTTP: handleRequest$,
    STORAGE: toStorage$,
    ACTION: xs.merge(getResponse$.filter(({ font_color }) => !font_color), update$, sendNotification$)
  }
}

const changeOrganization = ({ ACTION }) => {
  const handleAction$ = ACTION
    .filter(ifTypeIs(AuthTypes.CHANGE_ORGANIZATION))

  const redirectToSelect = handleAction$
    .mapTo(push('/login/select'))

  return {
    ACTION: redirectToSelect
  }
}

const createOrganization = ({ ACTION, STATE, HTTP, STORAGE }) => {
  const userToken$ = getToken(STORAGE)

  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.REQUEST_CREATION))
    .compose(combine(userToken$))
    .debug('PAY')
    .map(([{ payload, id }, token]) => ({
      url: `${REACT_APP_API}/organizations${id ? `/${id}` : ''}`,
      headers: { Authorization: `Bearer ${token}` },
      method: id ? 'PUT' : 'POST',
      category: 'CREATE_ORG',
      responseType: 'json',
      send: payload
    }))

  const getResponse$ = HTTP
    .select('CREATE_ORG')
    .map(handleHTTPError)
    .flatten()
    .debug()
    .map(({ body }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.creationSuccess())

  const onSuccess$ = ACTION
    .filter(ifTypeIs(Types.CREATION_SUCCESS))

  const sendNotification$ = onSuccess$
    .mapTo(NotificationsActions.requestNotification('Operacion completada', 'info'))

  const updateOrgs$ = onSuccess$
    .mapTo(Actions.fetchOrgs())

  return {
    HTTP: handleRequest$,
    ACTION: xs.merge(getResponse$, sendNotification$, updateOrgs$)
  }
}

const deleteOrg = ({ ACTION, STATE, HTTP, STORAGE }) => {
  const userToken$ = getToken(STORAGE)

  const handleDelete$ = ACTION
    .filter(ifTypeIs(Types.DELETE_ORG))
    .compose(combine(userToken$))
    .map(([{ id }, token]) => ({
      url: `${REACT_APP_API}/organizations/${id}`,
      method: 'DELETE',
      headers: { 'Authorization': `Bearer ${token}` },
      category: 'DELETE_ORG',
      responseType: 'json'
    }))

  const getDeleteUser$ = HTTP
    .select('DELETE_ORG')
    .map(handleHTTPError)
    .flatten()
    .map(({ body }) => !body || body.error
      ? RoutesActions.handleError((body || {}).error || 'ERROR')
      : Actions.fetchOrgs())

  return {
    HTTP: handleDelete$,
    ACTION: getDeleteUser$
  }
}

const changeTemplate = ({ ACTION, STATE, HTTP, STORAGE }) => {
  const userToken$ = getToken(STORAGE)

  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.REQUEST_ORG_TEMPLATE))
    .compose(combine(userToken$))
    .map(([ { payload }, token ]) => ({
      url: `${REACT_APP_API}/color-template`,
      headers: { 'Authorization': `Bearer ${token}` },
      method: 'PUT',
      send: payload,
      category: 'ORG_TEMPLATE',
      responseType: 'json'
    }))

  const getResponse$ = HTTP
    .select('ORG_TEMPLATE')
    .map(handleHTTPError)
    .flatten()
    .map(({ body }) => body.error
      ? RoutesActions.handleError(body.error)
      : NotificationsActions.requestNotification('Operacion completada', 'info'))

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

export default combineCycles(
  fetchOrganizations,
  onError,
  requestCustomization,
  changeOrganization,
  createOrganization,
  deleteOrg,
  changeTemplate
)
