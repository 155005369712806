import React, { Component } from 'react'
import { Box, Table, TableHeader, TableCell, Text, TableRow, Heading, TableBody, CheckBox } from 'grommet'
import hexToRgba from 'hex-to-rgba'
import Button from 'components/Button'
import SvgGuardar from '../../../../icons/Guardar'

import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { ProjectsActions } from '../../duck'

const INITIAL_STATE = {
  dataLocal: null
}

// recibe en el estado los reucers del store, se convierte en una propiedad del componente
const state = state => ({
  projectSelected: state.projects.projectSelected,
  chargeTypes: state.projects.chargeTypes,
  accountCosts: state.projects.accountCosts,
})

const dispatch = {
  updateChargeTypesArr: ProjectsActions.updateArrayChargeTypesOperation
}

// Funcion de redux que recibe (mapStateToProps, DispachState to props)
@connect(state, dispatch)
class TypeChargeComponent extends Component {
  state = INITIAL_STATE

  setChecked = (id, cost_type_id) => {
    const data = (this.state.dataLocal || []).map(e => {
      return {
        ...e,
        cost_type_id: e.id == id ? cost_type_id != e.cost_type_id ? cost_type_id : '' : e.cost_type_id
      }
    })
    this.setState(prevState => ({
      ...prevState,
      dataLocal: data
    }))
  }

  saveData = () => {
    const dataFormated = this.state.dataLocal.map(e => ({
      id: e.id,
      cost_type_id: e.cost_type_id ? e.cost_type_id : null
    }))
    this.props.updateChargeTypesArr(dataFormated, this.props.projectSelected)
    this.props.callBackSave()
  }

  render () {
    const { theme, } = this.props
    const cols = this.props.accountCosts ? this.props.accountCosts.length > 0 ? this.props.accountCosts.map(e => ({
      ...e,
      value: e.id
    })) : [] : []
    if (this.state.dataLocal == null) {
      this.setState(prevState => ({
        ...prevState,
        dataLocal: this.props.chargeTypes ? this.props.chargeTypes : []
      }))
    }
    const data = this.state.dataLocal ? this.state.dataLocal : []
    return (
      <Box direction='row' flex align='start' justify='baseline' fill margin={{ horizontal: 'small' }}>
        <Box
          pad='medium'
          background='white'
          round='xsmall'
          responsive
          fill='vertical'
          style={{ width: '100%', position: 'relative', overflowY: 'auto' }}
        >
          <Box direction='row' align='center'>
            <Heading margin={{ vertical: 'xsmall' }} level={3}>Tipo de cargo</Heading>
          </Box>
          <Box direction='row' align='center' margin={{ top: 'large' }}>
            <div style={{ overflowY: 'auto', height: '100%' }}>
              <Table style={{ width: '100%' }}>
                <TableHeader>
                  <TableRow>
                    <TableCell scope='col'>
                      <Text weight='normal' align='start'>Tipo</Text>
                    </TableCell>
                    <TableCell scope='col'>
                      <Text weight='normal' align='start'>Nombre</Text>
                    </TableCell>
                    {cols.map(({ description }, i) => (
                      <TableCell scope='col'>
                        <Text weight='normal' align='start'>{description}</Text>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {data.map(({ name, id, cost_type_id }, i) => (
                    <TableRow key={i} borderBottom='none' style={
                      {
                        borderBottom: `1px solid ${hexToRgba(theme.primary, 0.3)}`,
                        backgroundColor: (i % 2) ? 'rgba(0, 0, 0, 0.1)' : null,
                        borderBottom: `0px`
                      }
                    }>
                      <TableCell scope='row'>
                        <Text weight='normal'>{i}</Text>
                      </TableCell>
                      <TableCell scope='row'>
                        <ti weight='normal' style={
                          {
                            border: `1px solid black`,
                            backgroundColor: 'white',
                            padding: '3px',
                            borderRadius: '2px',
                            width: '100%',
                            fontSize: '14px',
                            lineHeight: '12px'
                          }
                        }>{name}</ti>
                      </TableCell>
                      {cols.map(({ value }) => (
                        <TableCell scope='row'>
                          <CheckBox
                            checked={(cost_type_id == value)}
                            onChange={this.setChecked.bind(this, id, value)}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </Box>
          <Box direction='row' justify='end' margin={{ vertical: 'small' }}>
            <Button
              icon
              type='submit'
              color={theme.primary}
              onClick={this.saveData.bind()}
            >
              <SvgGuardar fill={theme.font} width={80}/>
            </Button>
          </Box>
        </Box>
      </Box>
    )
  }
}

export default TypeChargeComponent