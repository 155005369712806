export const unit_factors_initial_state = {
  UFLoading: false,
  UFAcountCosts: [],
  UFAcountCostsGraph: [],
  UFAcountCostsSecond: [],
  UFAcountCostsMagic: [],
  UFAcountCostsMagicSecond: [],
  UFAccoutCostsChilds: [],
  UFAccoutCostsChildsSecond: [],
  UFAcountCostsById: {},
  UFAcountCostsByIdSecond: {},
  UFAcountCostEditing: null,
  UFTextSearch: '',
  UFSearchBy: 'description'
}
