import React, { Component } from 'react'

import withoutLogin from 'components/HOC/withoutLogin'
import withTheme from 'components/HOC/withTheme'
import Overlay from 'components/OverlayLoading'
import { connect } from 'react-redux'
import { Box, Heading } from 'grommet'
import ResetPasswordForm from 'forms/ResetPasswordForm'
import Input from 'components/Controlled/Input'
import ButtonCore from 'components/Controlled/Button'
import FirstUserForm from 'forms/FirstUserForm'
import { AuthActions } from './duck'
import {Box as Div} from 'reflexbox'
import {push} from 'connected-react-router'
const dispatch = {
  login: AuthActions.requestLogin,
  firstLogin: AuthActions.requestCreation,
  resetPassword: AuthActions.sendPasswordReset,
  navigate: push,
  resetStatus: AuthActions.resetStatus
}

const state = state => ({
  loading: state.auth.loading,
  token: state.auth.token,
  firstTime: state.auth.firstTime,
  sent: state.auth.emailSent,
  noName: state.auth.noName
})

@connect(state, dispatch)
@withTheme
@withoutLogin
class FirstLogin extends Component {
  componentDidMount = () => {
    if (!this.props.token) this.props.navigate('/login')
  }

  sendCreate = values => {
    this.props.firstLogin({ ...values })
  }

  render = () => {
    const {loading, firstTime, theme} = this.props

    return this.props.noName ? (
      <ResetPasswordForm onSubmit={this.sendCreate}>
        {({isValid, fields}) => (
          <Div w={1}>
            <Heading
              size='small'
              color={theme.primary}
              margin={{vertical: 'small'}}
            >
              Reestablecer contraseña
            </Heading>
            <Input
              {...fields.password.props}
              error={fields.password.error}
              disabled={loading}
              valid={!fields.password.error}
              type='password'
              color={theme.primary}
              password
              label='Contraseña'
            />
            <Input
              {...fields.password_confirmation.props}
              error={fields.password_confirmation.error}
              disabled={loading}
              valid={!fields.password_confirmation.error}
              type='password'
              color={theme.primary}
              password
              label='Confirmar contraseña'
            />
            <ButtonCore
              disabled={!isValid}
              color={theme.primary}
              type='submit'
            >
              CONTINUAR
            </ButtonCore>
          </Div>
        )}
      </ResetPasswordForm>
    ) : (
      <FirstUserForm onSubmit={this.sendCreate}>
        {({isValid, fields}) => (
          <Div w={1}>
            <Heading
              size='small'
              color={theme.primary}
              margin={{vertical: 'small'}}
            >
              Bienvenid@
            </Heading>
            <Input
              {...fields.name.props}
              error={fields.name.error}
              disabled={loading}
              valid={!fields.name.error}
              label='Nombre'
              color={theme.primary}
            />
            <Input
              {...fields.password.props}
              error={fields.password.error}
              disabled={loading}
              valid={!fields.password.error}
              label='Contraseña'
              type='password'
              color={theme.primary}
              password
            />
            <Input
              {...fields.password_confirmation.props}
              error={fields.password_confirmation.error}
              disabled={loading}
              valid={!fields.password_confirmation.error}
              label='Confirmar contraseña'
              color={theme.primary}
              type='password'
              password
            />
            <ButtonCore
              type='submit'
              disabled={!isValid}
              color={theme.primary}
            >
              CONTINUAR
            </ButtonCore>
          </Div>
        )}
      </FirstUserForm>
    )
  }
}

export default FirstLogin
