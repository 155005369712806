import React from "react";

const SvgNuevo = props => (
  <svg viewBox="0 0 141.7 141.7" {...props}>
    <path
      className="nuevo_svg__st0"
      d="M123.7 38.2L93.6 8c-.6-.6-1.5-1-2.4-1H27.6c-5.5 0-10 4.5-10 10v107.1c0 5.5 4.5 10 10 10h87c5.5 0 10-4.5 10-10V40.5c.1-.9-.3-1.7-.9-2.3zm-7.1 2.3h-22c-1.8 0-3.3-1.5-3.3-3.3v-22l25.3 25.3zm1.4 83.7c0 1.8-1.5 3.3-3.3 3.3h-87c-1.8 0-3.3-1.5-3.3-3.3V17.1c0-1.8 1.5-3.3 3.3-3.3h56.9v23.4c0 5.5 4.5 10 10 10H118v77z"
    />
    <path
      className="nuevo_svg__st0"
      d="M71.1 114.4c-1.3 0-2.4-1.1-2.4-2.4V36.2c0-1.3 1.1-2.4 2.4-2.4 1.3 0 2.4 1.1 2.4 2.4V112c0 1.3-1.1 2.4-2.4 2.4z"
    />
    <path
      className="nuevo_svg__st0"
      d="M109.1 76.4H33.2c-1.3 0-2.4-1.1-2.4-2.4 0-1.3 1.1-2.4 2.4-2.4h75.9c1.3 0 2.4 1.1 2.4 2.4-.1 1.4-1.1 2.4-2.4 2.4z"
    />
  </svg>
);

export default SvgNuevo;
