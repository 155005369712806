import { createActions } from 'reduxsauce'

const { Creators, Types } = createActions({
  redirectOnLogin: ['url'],
  updateRedirectUrl: ['url'],

  handleError: ['error']
}, {
  prefix: 'routes/'
})

export { Creators as Actions, Types }
