import React from "react";

const SvgEstructurascuentas = props => (
  <svg viewBox="0 0 141.7 141.7" {...props}>
    <path
      className="estructurascuentas_svg__st0"
      d="M80.8 53H60.7c-4.1 0-7.4-3.3-7.4-7.4V25.4c0-4.1 3.3-7.4 7.4-7.4h20.2c4.1 0 7.4 3.3 7.4 7.4v20.2c0 4-3.4 7.4-7.5 7.4zM60.7 22.7c-1.5 0-2.6 1.2-2.6 2.6v20.2c0 1.5 1.2 2.6 2.6 2.6h20.2c1.5 0 2.6-1.2 2.6-2.6V25.4c0-1.5-1.2-2.6-2.6-2.6H60.7zM80.8 122.7H60.7c-4.1 0-7.4-3.3-7.4-7.4V95.1c0-4.1 3.3-7.4 7.4-7.4h20.2c4.1 0 7.4 3.3 7.4 7.4v20.2c0 4.1-3.4 7.4-7.5 7.4zM60.7 92.5c-1.5 0-2.6 1.2-2.6 2.6v20.2c0 1.5 1.2 2.6 2.6 2.6h20.2c1.5 0 2.6-1.2 2.6-2.6V95.1c0-1.5-1.2-2.6-2.6-2.6H60.7zM127.2 122.7H107c-4.1 0-7.4-3.3-7.4-7.4V95.1c0-4.1 3.3-7.4 7.4-7.4h20.2c4.1 0 7.4 3.3 7.4 7.4v20.2c0 4.1-3.3 7.4-7.4 7.4zm-20.1-30.2c-1.5 0-2.6 1.2-2.6 2.6v20.2c0 1.5 1.2 2.6 2.6 2.6h20.2c1.5 0 2.6-1.2 2.6-2.6V95.1c0-1.5-1.2-2.6-2.6-2.6h-20.2zM34.5 122.7H14.3c-4.1 0-7.4-3.3-7.4-7.4V95.1c0-4.1 3.3-7.4 7.4-7.4h20.2c4.1 0 7.4 3.3 7.4 7.4v20.2c0 4.1-3.3 7.4-7.4 7.4zM14.3 92.5c-1.5 0-2.6 1.2-2.6 2.6v20.2c0 1.5 1.2 2.6 2.6 2.6h20.2c1.5 0 2.6-1.2 2.6-2.6V95.1c0-1.5-1.2-2.6-2.6-2.6H14.3z"
    />
    <path
      className="estructurascuentas_svg__st0"
      d="M68.7 49.5h4.8v41.2h-4.8z"
    />
    <path
      className="estructurascuentas_svg__st0"
      d="M21.6 90.9c0-.5-1.1-11.2 5.6-18.6 4-4.4 9.7-6.6 17.1-6.6h27.2v4.8H44.3c-6 0-10.5 1.7-13.5 5-5.2 5.8-4.4 14.8-4.4 14.9l-4.8.5z"
    />
    <path
      className="estructurascuentas_svg__st0"
      d="M121.3 90.9l-4.8-.5 2.4.3-2.4-.2c0-.1.8-9.1-4.4-14.9-3-3.3-7.6-5-13.5-5H71.4v-4.8h27.2c7.4 0 13.2 2.2 17.1 6.6 6.7 7.3 5.7 18.1 5.6 18.5z"
    />
  </svg>
);

export default SvgEstructurascuentas;
