import React, { PureComponent } from 'react'
import { Box, Heading, Text, Button } from 'grommet'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

class NotFound extends PureComponent {
  goHome = () => { this.props.navigate('/') }

  render = () => (
    <Box
      flex={false}
      fill
      basis='full'
      align='center'
      justify='center'
      direction='column'
      background='#fff5f0'
    >
      <Heading color='emsi' level={1}>Pagina no encontrada</Heading>
      <Text margin={false} size='large'>Al parecer, te haz equivocado de lugar...</Text>
      <Box margin='large'>
        <Button onClick={this.goHome} primary color='secondary' label={<Text size='large'>Ir a inicio</Text>} />
      </Box>
    </Box>
  )
}

const dispatch = {
  navigate: push
}

export default connect(null, dispatch)(NotFound)
