/* eslint-disable no-useless-escape */
import React, { Component } from 'react'
import { form, from } from 'react-inform'

const emailRE = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const Rules = {
  email: {
    'Usuario vacio': e => !!e,
    'Email no valido': e => emailRE.test(e)
  },
  password: {
    'Contraseña vacia': e => !!e,
    'La contraseña debe ser mayor a 6 caracteres': e => e.length >= 1
  },
  remember: {}
}

class LoginForm extends Component {
  onSubmit = e => {
    const { onSubmit, form } = this.props

    e.preventDefault()
    if (onSubmit) onSubmit(form.values())
  }

  render = () => {
    const { children, fields, form } = this.props

    const childrenParams = {
      fields,
      isValid: form.isValid()
    }

    return (
      <form autoComplete='new-password' onSubmit={this.onSubmit}>
        {children(childrenParams)}
      </form>
    )
  }
}

export default form(from(Rules))(LoginForm)
