import React from "react";

const SvgFactores = props => (
  <svg viewBox="0 0 283.5 283.5" {...props}>
    <path
      className="factores_svg__st0"
      d="M140 106.4v72.4c0 14.7-12 26.7-26.7 26.7H40.9c-14.7 0-26.7-12-26.7-26.7v-72.4c0-14.7 12-26.7 26.7-26.7h72.4c14.7 0 26.7 12 26.7 26.7zM31.4 178.8c0 5.2 4.2 9.4 9.4 9.4h72.4c5.2 0 9.4-4.2 9.4-9.4v-72.4c0-5.2-4.2-9.4-9.4-9.4H40.9c-5.2 0-9.4 4.2-9.4 9.4v72.4h-.1zM269.3 123.9v37.4c0 7.6-6.2 13.8-13.8 13.8h-37.4c-7.6 0-13.8-6.2-13.8-13.8v-37.4c0-7.6 6.2-13.8 13.8-13.8h37.4c7.6 0 13.8 6.2 13.8 13.8zm-56.1 37.4c0 2.7 2.2 4.9 4.9 4.9h37.4c2.7 0 4.9-2.2 4.9-4.9v-37.4c0-2.7-2.2-4.9-4.9-4.9h-37.4c-2.7 0-4.9 2.2-4.9 4.9v37.4zM269.3 37.9v37.4c0 7.6-6.2 13.8-13.8 13.8h-37.4c-7.6 0-13.8-6.2-13.8-13.8V37.9c0-7.6 6.2-13.8 13.8-13.8h37.4c7.6 0 13.8 6.2 13.8 13.8zm-56.1 37.4c0 2.7 2.2 4.9 4.9 4.9h37.4c2.7 0 4.9-2.2 4.9-4.9V37.9c0-2.7-2.2-4.9-4.9-4.9h-37.4c-2.7 0-4.9 2.2-4.9 4.9v37.4zM269.3 209.9v37.4c0 7.6-6.2 13.8-13.8 13.8h-37.4c-7.6 0-13.8-6.2-13.8-13.8v-37.4c0-7.6 6.2-13.8 13.8-13.8h37.4c7.6 0 13.8 6.2 13.8 13.8zm-56.1 37.4c0 2.7 2.2 4.9 4.9 4.9h37.4c2.7 0 4.9-2.2 4.9-4.9v-37.4c0-2.7-2.2-4.9-4.9-4.9h-37.4c-2.7 0-4.9 2.2-4.9 4.9v37.4z"
    />
    <path className="factores_svg__st0" d="M133.6 137.5H210v8.9h-76.4z" />
    <path
      className="factores_svg__st0"
      d="M210.3 233.7c-.8.1-20.8 2-34.5-10.3-8.2-7.4-12.3-18-12.3-31.7v-50.4h8.9v50.4c0 11 3.1 19.5 9.3 25.1 10.7 9.7 27.5 8.2 27.6 8.1l1 8.8z"
    />
    <path
      className="factores_svg__st0"
      d="M210.3 48.8l-.9 8.9.5-4.4-.5 4.4c-.2 0-16.9-1.6-27.6 8.1-6.2 5.6-9.3 14-9.3 25.1v50.4h-8.9V90.9c0-13.7 4.1-24.4 12.3-31.7 13.7-12.4 33.6-10.5 34.4-10.4zM235.8 43.3l-5.3 2.8-.8-3.1 6.6-3.5h3.5v30.1h-3.9V43.3h-.1zM227.9 152.8c1.2.7 3.8 1.9 6.7 1.9 5.2 0 6.9-3.3 6.8-5.8 0-4.2-3.8-6-7.8-6h-2.3v-3.1h2.3c3 0 6.7-1.5 6.7-5.1 0-2.4-1.5-4.5-5.3-4.5-2.4 0-4.7 1.1-6 2l-1.1-3c1.6-1.2 4.6-2.3 7.9-2.3 5.9 0 8.6 3.5 8.6 7.2 0 3.1-1.9 5.7-5.6 7.1v.1c3.7.7 6.7 3.5 6.7 7.7 0 4.8-3.7 9-11 9-3.4 0-6.3-1.1-7.8-2l1.2-3.2zM235.8 217.8l-5.3 2.8-.8-3.1 6.6-3.5h3.5v30.1h-3.9v-26.3h-.1z"
    />
  </svg>
);

export default SvgFactores;
