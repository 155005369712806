import { Types } from '../../duck/operations'

const getBudgetACSuccess = (state = INITIAL_STATE, { payload }) => {
  return ({
    ...state,
    rootBudgetAcc: payload,
    fetchingBudget: false
  })
}
const setSuppliesByCharge = (state = INITIAL_STATE, { payload }) => {
  let data = state.childBudgets
  data[payload.carge_type_id] = payload.data
  return ({
    ...state,
    // suppliesChargeType: data
    suppliesChargeType: {
      ...state.childBudgets,
      [payload.carge_type_id]: payload.data
    }
  })
}
const getConceptsByAccountCost = (state = INITIAL_STATE, { params }) => {
  return ({
    ...state,
    conceptsByAcountCost: {
      ...state.conceptsByAcountCost,
      [params.account_cost_id]: []
    }
  })
}
const getConceptsByAccountCostSecond = (state = INITIAL_STATE, { params }) => {
  return ({
    ...state,
    conceptsByAcountCostSecond: {
      ...state.conceptsByAcountCostSecond,
      [params.account_cost_id]: []
    }
  })
}
const setConceptsByAccountCosts = (state = INITIAL_STATE, { payload }) => {
  return ({
    ...state,
    conceptsByAcountCost: {
      ...state.conceptsByAcountCost,
      [payload.account_cost_id]: payload.data
    }
  })
}
const setConceptsByAccountCostsSecond = (state = INITIAL_STATE, { payload }) => {
  return ({
    ...state,
    conceptsByAcountCostSecond: {
      ...state.conceptsByAcountCostSecond,
      [payload.account_cost_id]: payload.data
    }
  })
}
const setProjectSelectedReducer = (state = INITIAL_STATE, { id }) => {
  return ({
    ...state,
    projectSelected: id
  })
}
const getBudgetAcountCostReducer = (state = INITIAL_STATE, { id, type }) => {
  return {
    ...state
  }
}
const updateTableBudgetDG = (state = INITIAL_STATE) => ({
  ...state,
  rootBudgetAcc: [],
  fetchingBudget: true
})

const getSuppliesByConcept = (state = INITIAL_STATE, { params }) => {
  return ({
    ...state,
    suppliesByConcepts: {
      ...state.suppliesByConcepts,
      [params.concept_id]: []
    }
  })
}

const getSuppliesByConceptSecond = (state = INITIAL_STATE, { params }) => {
  return ({
    ...state,
    suppliesByConceptsSecond: {
      ...state.suppliesByConceptsSecond,
      [params.concept_id]: []
    }
  })
}
const setSuppliesByConcept = (state = INITIAL_STATE, { payload }) => {
  return ({
    ...state,
    suppliesByConcepts: {
      ...state.suppliesByConcepts,
      [payload.concept_id]: payload.data
    }
  })
}

const setSuppliesByConceptSecond = (state = INITIAL_STATE, { payload }) => {
  return ({
    ...state,
    suppliesByConceptsSecond: {
      ...state.suppliesByConceptsSecond,
      [payload.concept_id]: payload.data
    }
  })
}
const getMagicByAccountCost = (state = INITIAL_STATE, { payload }) => {
  return ({
    ...state,
    magicByAccountCost: {
      ...state.magicByAccountCost,
      [payload.account_cost_id]: []
    }
  })
}

const getMagicByAccountCostSecond = (state = INITIAL_STATE, { payload }) => {
  return ({
    ...state,
    magicByAccountCostSecond: {
      ...state.magicByAccountCostSecond,
      [payload.account_cost_id]: []
    }
  })
}
const setMagicByAccountCosts = (state = INITIAL_STATE, { payload }) => {
  return ({
    ...state,
    magicByAccountCost: {
      ...state.magicByAccountCost,
      [payload.accoun_cost_id]: payload.data
    }
  })
}
const setMagicByAccountCostsSecond = (state = INITIAL_STATE, { payload }) => {
  return ({
    ...state,
    magicByAccountCostSecond: {
      ...state.magicByAccountCostSecond,
      [payload.accoun_cost_id]: payload.data
    }
  })
}
/// /////////////////////////////////////////////////////////////////////////////////
// View ACOUNT COST
/// /////////////////////////////////////////////////////////////////////////////////

const getAccountCostChildAC = (state = INITIAL_STATE, { params }) => {
  return ({
    ...state,
    AccountCostChilds: {
      ...state.AccountCostChilds,
      [params.account_cost_id]: false
    }
  })
}

const getAccountCostChildACGraph = (state = INITIAL_STATE, { params }) => {
  let auxObj = [ ...state.AccountCostChildsGraph ]
  auxObj[params.index] = {
    ...auxObj[params.index],
    [params.account_cost_id]: false
  }

  return ({
    ...state,
    UFLoading: true,
    AccountCostChildsGraph: auxObj
  })
}

const getMagicByAccountCostGraph = (state = INITIAL_STATE, { payload }) => {
  let auxObj = [ ...state.AccountCostChildsGraph ]
  auxObj[payload.index] = {
    ...auxObj[payload.index],
    [payload.account_cost_id]: false
  }

  return ({
    ...state,
    UFLoading: true,
    magicByAccountCostGraph: auxObj
  })
}

const getAccountCostChildACSecond = (state = INITIAL_STATE, { params }) => {
  return ({
    ...state,
    AccountCostChildsSecond: {
      ...state.AccountCostChildsSecond,
      [params.account_cost_id]: false
    }
  })
}
const setAccountCostChildAC = (state = INITIAL_STATE, { data }) => {
  return ({
    ...state,
    AccountCostChilds: {
      ...state.AccountCostChilds,
      [data.ident_parent]: data.data
    }
  })
}

const setMagicByAccountCostsGraph = (state = INITIAL_STATE, { payload }) => {
  let auxObj = [ ...state.AccountCostChildsGraph ]
  auxObj[payload.auxIndex] = {
    ...auxObj[payload.auxIndex],
    [payload.accoun_cost_id]: payload.data
  }
  console.log(auxObj)
  return ({
    UFLoading: false,
    magicByAccountCostGraph: auxObj
  })
}

const setAccountCostChildACGraph = (state = INITIAL_STATE, { data }) => {
  let auxObj = [ ...state.AccountCostChildsGraph ]
  auxObj[data.auxIndex] = {
    ...auxObj[data.auxIndex],
    [data.ident_parent]: data.data
  }
  console.log(auxObj)
  return ({
    ...state,
    UFLoading: false,
    AccountCostChildsGraph: auxObj
  })
}

const setAccountCostChildACSecond = (state = INITIAL_STATE, { data }) => {
  return ({
    ...state,
    AccountCostChildsSecond: {
      ...state.AccountCostChildsSecond,
      [data.ident_parent]: data.data
    }
  })
}

/// /////////////////////////////////////////////////////////////////////////////////
// View budget charge type
/// /////////////////////////////////////////////////////////////////////////////////
const getChargeTypeByAccountCostCT = (state = INITIAL_STATE, { params }) => {
  return ({
    ...state,
    ChargeTypeByACLevel1TC: {
      ...state.ChargeTypeByACLevel1TC,
      [params.account_cost_id]: []
    }
  })
}

const getChargeTypeByAccountCostGraphCT = (state = INITIAL_STATE, { params }) => {
  return ({
    ...state,
    ChargeTypeGraph: null,
    UFLoading: true
  })
}

const getChargeTypeByAccountCostCTSecond = (state = INITIAL_STATE, { params }) => {
  return ({
    ...state,
    ChargeTypeByACLevel1TCSecond: {
      ...state.ChargeTypeByACLevel1TCSecond,
      [params.account_cost_id]: []
    }
  })
}
const setChargeTypeByAccountCostCT = (state = INITIAL_STATE, { data }) => {
  return ({
    ...state,
    ChargeTypeByACLevel1TC: {
      ...state.ChargeTypeByACLevel1TC,
      [data.accoun_cost_id]: data.data
    }
  })
}

const setChargeTypeByAccountCostGraphCT = (state = INITIAL_STATE, { data }) => {
  console.log('setChargeTypeByAccountCostGraphCT')
  console.log(data)
  return ({
    ...state,
    ChargeTypeGraph: data.data,
    UFLoading: false
  })
}

const setChargeTypeByAccountCostCTSecond = (state = INITIAL_STATE, { data }) => {
  return ({
    ...state,
    ChargeTypeByACLevel1TCSecond: {
      ...state.ChargeTypeByACLevel1TCSecond,
      [data.accoun_cost_id]: data.data
    }
  })
}
const getAccountCostByChargeTypeCT = (state = INITIAL_STATE, { params }) => {
  return ({
    ...state,
    AcountCostByCTTC: {
      ...state.AcountCostByCTTC,
      [`${params.account_cost_id}-${params.charge_type_id}`]: false
    }
  })
}

const getAccountCostByChargeTypeCTSecond = (state = INITIAL_STATE, { params }) => {
  return ({
    ...state,
    AcountCostByCTTCSecond: {
      ...state.AcountCostByCTTCSecond,
      [`${params.account_cost_id}-${params.charge_type_id}`]: false
    }
  })
}
const setAccountCostByChargeTypeCT = (state = INITIAL_STATE, { data }) => {
  return ({
    ...state,
    AcountCostByCTTC: {
      ...state.AcountCostByCTTC,
      [`${data.accoun_cost_id}-${data.charge_type_id}`]: data.data,
      [`${data.accoun_cost_id}-${data.charge_type_id}T`]: data.dataByCharge
    }
  })
}

const setAccountCostByChargeTypeCTSecond = (state = INITIAL_STATE, { data }) => {
  return ({
    ...state,
    AcountCostByCTTCSecond: {
      ...state.AcountCostByCTTCSecond,
      [`${data.accoun_cost_id}-${data.charge_type_id}`]: data.data,
      [`${data.accoun_cost_id}-${data.charge_type_id}T`]: data.dataByCharge
    }
  })
}
const getSuppliesByChargeTypeCT = (state = INITIAL_STATE, { params }) => {
  return ({
    ...state,
    loadingSupplies: true,
    SuppliesByCTTC: {
      ...state.SuppliesByCTTC,
      [`${params.account_cost_id}-${params.charge_type_id}`]: false
    }
  })
}
const setSuppliesByChargeTypeCT = (state = INITIAL_STATE, { data }) => {
  return ({
    ...state,
    loadingSupplies: false,
    SuppliesByCTTC: {
      ...state.SuppliesByCTTC,
      [`${data.accoun_cost_id}-${data.charge_type_id}`]: data.data
    }
  })
}
// UFGetUnitesGenerals
export const burget_view_handles = {
  [Types.SET_PROJECT_SELECTED_OPERATION]: setProjectSelectedReducer,
  [Types.GET_BUDGET_ACOUNT_COST]: getBudgetAcountCostReducer,
  [Types.GET_BUDGET_SUCCESS_ACOUNT]: getBudgetACSuccess,
  [Types.SET_SUPPLIES_BY_CHARGE]: setSuppliesByCharge,
  [Types.GET_BUDGET_ACOUNT_COST]: updateTableBudgetDG,
  [Types.SET_CONCEPTS_BY_ACCOUNT_COSTS]: setConceptsByAccountCosts,
  [Types.SET_CONCEPTS_BY_ACCOUNT_COSTS_SECOND]: setConceptsByAccountCostsSecond,
  [Types.GET_CONCEPTS_BY_ACCOUNT_COST]: getConceptsByAccountCost,
  [Types.GET_CONCEPTS_BY_ACCOUNT_COST_SECOND]: getConceptsByAccountCostSecond,
  [Types.GET_SUPPLIES_BY_CONCEPT]: getSuppliesByConcept,
  [Types.GET_SUPPLIES_BY_CONCEPT_SECOND]: getSuppliesByConceptSecond,
  [Types.SET_SUPPLIES_BY_CONCEPT]: setSuppliesByConcept,
  [Types.SET_SUPPLIES_BY_CONCEPT_SECOND]: setSuppliesByConceptSecond,
  [Types.SET_MAGIC_BY_ACCOUNT_COSTS]: setMagicByAccountCosts,
  [Types.SET_MAGIC_BY_ACCOUNT_COSTS_GRAPH]: setMagicByAccountCostsGraph,
  [Types.SET_MAGIC_BY_ACCOUNT_COSTS_SECOND]: setMagicByAccountCostsSecond,
  [Types.GET_MAGIC_BY_ACCOUNT_COST]: getMagicByAccountCost,
  [Types.GET_MAGIC_BY_ACCOUNT_COST_GRAPH]: getMagicByAccountCostGraph,
  [Types.GET_MAGIC_BY_ACCOUNT_COST_SECOND]: getMagicByAccountCostSecond,
  // View account cost
  [Types.GET_ACCOUNT_COST_CHILD_AC]: getAccountCostChildAC,
  [Types.GET_ACCOUNT_COST_CHILD_AC_SECOND]: getAccountCostChildACSecond,
  [Types.SET_ACCOUNT_COST_CHILD_AC]: setAccountCostChildAC,
  [Types.SET_ACCOUNT_COST_CHILD_AC_SECOND]: setAccountCostChildACSecond,
  [Types.GET_ACCOUNT_COST_CHILD_AC_GRAPH]: getAccountCostChildACGraph,
  [Types.SET_ACCOUNT_COST_CHILD_AC_GRAPH]: setAccountCostChildACGraph,

  // View budget charge type
  [Types.GET_CHARGE_TYPE_BY_ACCOUNT_COST_CT]: getChargeTypeByAccountCostCT,
  [Types.GET_CHARGE_TYPE_BY_ACCOUNT_COST_GRAPH_CT]: getChargeTypeByAccountCostGraphCT,
  [Types.GET_CHARGE_TYPE_BY_ACCOUNT_COST_CT_SECOND]: getChargeTypeByAccountCostCTSecond,
  [Types.SET_CHARGE_TYPE_BY_ACCOUNT_COST_CT]: setChargeTypeByAccountCostCT,
  [Types.SET_CHARGE_TYPE_BY_ACCOUNT_COST_GRAPH_CT]: setChargeTypeByAccountCostGraphCT,
  [Types.SET_CHARGE_TYPE_BY_ACCOUNT_COST_CT_SECOND]: setChargeTypeByAccountCostCTSecond,
  [Types.GET_ACCOUNT_COST_BY_CHARGE_TYPE_CT]: getAccountCostByChargeTypeCT,
  [Types.GET_ACCOUNT_COST_BY_CHARGE_TYPE_CT_SECOND]: getAccountCostByChargeTypeCTSecond,
  [Types.SET_ACCOUNT_COST_BY_CHARGE_TYPE_CT]: setAccountCostByChargeTypeCT,
  [Types.SET_ACCOUNT_COST_BY_CHARGE_TYPE_CT_SECOND]: setAccountCostByChargeTypeCTSecond,
  [Types.GET_SUPPLIES_BY_CHARGE_TYPE_CT]: getSuppliesByChargeTypeCT,
  [Types.SET_SUPPLIES_BY_CHARGE_TYPE_CT]: setSuppliesByChargeTypeCT
}
