import React from "react";

const SvgLapiz = props => (
  <svg  viewBox="0 0 280.7 280.7" {...props}>
    <path
      className="Lapiz_svg__st0"
      d="M258.2 25.3c-7.2-7.2-16.7-11.1-26.9-11.1s-19.7 3.9-26.9 11.1L41.6 188.1c-.7.7-1.2 1.5-1.5 2.3l-25.5 70.2c-.8 2.3-.3 4.9 1.5 6.7 1.2 1.2 2.8 1.9 4.5 1.9.7 0 1.5-.1 2.2-.4L93 243.4c.9-.3 1.7-.8 2.3-1.5L258.2 79c7.2-7.2 11.1-16.7 11.1-26.9s-4-19.6-11.1-26.8zM249.1 70l-11.4 11.4L87.3 231.8l-1.5.5-34.7-34.7.5-1.5L202 45.8l11.4-11.4c4.8-4.8 11.1-7.4 17.8-7.4 6.7 0 13.1 2.6 17.8 7.4 4.8 4.8 7.4 11.1 7.4 17.8.1 6.7-2.5 13-7.3 17.8z"
    />
  </svg>
);

export default SvgLapiz;
