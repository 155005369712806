import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { withRouter } from 'react-router-dom'

export default (WrappedComponent) => {
  const state = state => ({
    appReady: state.auth.ready,
    isLogged: state.auth.logged,
    org: state.auth.orgSelected
  })

  const dispatchers = {
    navigate: push
  }

  @withRouter
  @connect(state, dispatchers)
  class withoutLogin extends Component {
    checkLogged = () => {
      const { isLogged, appReady, navigate, org } = this.props

      if (isLogged && appReady) navigate(!org ? '/login/select' : '/')
    }

    componentDidUpdate = () => {
      this.checkLogged()
    }

    componentDidMount = () => {
      this.checkLogged()
    }

    render = () => {
      const { ...props } = this.props

      return <WrappedComponent {...props} />
    }
  }

  return withoutLogin
}
