import { createReducer } from 'reduxsauce'
import { Types } from './operations'
import { RoutesTypes } from '../../routes/duck'
// initial states
import { unit_factors_initial_state } from '../UnitFactors/Redux/initialStateUnitFactors'
import { budget_view_initial_state } from '../BudgetView/Redux/initialStateBudgetView'
// Reducers
import { unit_state_handles } from '../UnitFactors/Redux/reducersUnitFactors'
import { burget_view_handles } from '../BudgetView/Redux/reducersBudgetView'

const INITIAL_STATE = {
  ...unit_factors_initial_state,
  ...budget_view_initial_state,
  projects: null,
  fetchingProjects: false,
  project: null,
  fetchingProject: false,
  fetchingBudget: false,
  rootBudget: null,
  rootBudgetSecond: null,
  accountCosts: null,
  fetchingAgain: false,
  projectSelected: null,
  fetchingChild: false,
  childBudgets: {},
  childBudgetsSecond: {},
  budgetFetching: null,
  budgetFetchingSecond: null,
  loading: false,
  searchText: null,
  searchInternalType: null,
  periodsFromBudget: [],
  budgetSelected: null,
  fetchingPeriods: false,
  period: null,
  history: null,
  updatedProgress: [],
  budgetFetchingProgress: null
}

const updateProjects = (state = INITIAL_STATE, { payload }) => {
  return ({
    ...state,
    projects: payload,
    fetchingProjects: false,
    fetchingAgain: false
  })
}

const fetchingUsers = (state = INITIAL_STATE) => ({
  ...state,
  fetchingProjects: true,
  fetchingAgain: true
})

const handleError = (state = INITIAL_STATE) => ({
  ...state,
  fetchingProjects: false,
  fetchingProject: false,
  fetchingBudget: false,
  budgetFetching: null,
  loading: false
})

const fetchingProject = (state = INITIAL_STATE) => ({
  ...state,
  fetchingProject: true,
  childBudgetsGraph: [],
  ChargeTypeByACLevel1TCGraph: [],
  AcountCostByCTTCGraph: [],
  ChargeTypeGraph: null,
  UFAcountCostsGraph: [],
  SuppliesByCTTCGraph: { },
  magicByAccountCostGraph: { }
})

const projectUpdated = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  fetchingProject: false,
  project: payload
})

const clean = (state = INITIAL_STATE) => ({
  ...state,
  fetchingProject: false,
  project: null
})

const gettingBudget = (state = INITIAL_STATE) => ({
  ...state,
  fetchingBudget: true,
  childBudgets: {},
  childBudgetsSecond: {},
  rootBudget: null,
  rootBudgetSecond: null
})

const gettingBudgetPeriod = (state = INITIAL_STATE) => ({
  ...state,
  fetchingBudget: true,
  childBudgets: {},
  childBudgetsSecond: {},
  rootBudget: null,
  rootBudgetSecond: null
})

const updateProgressExcel = (state = INITIAL_STATE) => ({
  ...state,
  fetchingBudget: true,
  modalError: null
})
const updateProgressExcelSuccess = (state = INITIAL_STATE, { params }) => {
  return ({
    ...state,
    fetchingBudget: false,
    reload: !params.errorExcel,
    modalError: params.errorExcel ? params.message : null
  })
}
const updateProgressRealExcel = (state = INITIAL_STATE) => ({
  ...state,
  UFLoading: true,
  modalError: null
})
const getExcel = (state = INITIAL_STATE) => ({
  ...state,
  UFLoading: true,
  fetchingBudget: true
})

const getHistory = (state = INITIAL_STATE) => ({
  ...state,
  loadingHistory: true
})

const getExcelSuccess = (state = INITIAL_STATE) => ({
  ...state,
  UFLoading: false,
  fetchingBudget: false
})

const updateProgressRealExcelSuccess = (state = INITIAL_STATE, { params }) => {
  return ({
    ...state,
    reload: !params.errorExcel,
    UFLoading: false,
    modalError: params.errorExcel ? params.message : null
  })
}

const successBudget = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  rootBudget: payload.budget_items,
  equals: payload['equals'],
  budget: payload['budget'],
  fetchingBudget: false,
  isMapSuccess: false
})

const successBudgetSecond = (state = INITIAL_STATE, { payload }) => {
  console.log(payload)
  return {
    ...state,
    rootBudgetSecond: payload.budget_items,
    equals: payload['equals'],
    budgetSecond: payload['budget'],
    fetchingBudget: false,
    isMapSuccess: false
  }
}

const successHistory = (state = INITIAL_STATE, { payload }) => {
  const auxArray = payload.periods.slice()

  return {
    ...state,
    history: auxArray,
    loadingHistory: false
  }
}

const getCosts = (state = INITIAL_STATE, { id }) => {
  const project = (state.projects || []).filter(({ id: pId }) => pId * 1 === id * 1)[0]
  return ({
    ...state,
    projectSelected: id,
    accountCosts: project ? project.account_cost_structure : []
  })
}

const loadingAccount = (state = INITIAL_STATE) => ({
  ...state,
  fetchingAgain: true
})

const accountUpdated = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  fetchingAgain: false,
  accountCosts: payload
})

const gettingChilds = (state = INITIAL_STATE, { id }) => ({
  ...state,
  budgetFetching: id
})

const gettingChildsSecond = (state = INITIAL_STATE, { id }) => ({
  ...state,
  budgetFetchingSecond: id
})

const gettingChildsProgress = (state = INITIAL_STATE, { id }) => ({
  ...state,
  budgetFetchingProgress: id
})

const gettingChildsSecondProgress = (state = INITIAL_STATE, { id }) => ({
  ...state,
  budgetFetchingSecond: id
})

const gettingChildSuccess = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  budgetFetching: null,
  fetchingBudget: false,
  childBudgets: {
    ...state.childBudgets,
    [state.budgetFetching]: payload
  }
})

const gettingChildSecondSuccess = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  budgetFetchingSecond: null,
  fetchingBudget: false,
  childBudgetsSecond: {
    ...state.childBudgetsSecond,
    [state.budgetFetchingSecond]: payload
  }
})

const gettingChildProgressSuccess = (state = INITIAL_STATE, { payload }) => {
  const newChilds = {
    ...state.childBudgets,
    [state.budgetFetchingProgress]: payload
  }

  const auxkeys = {}
  payload.forEach((e) => {
    auxkeys[e.id] = e.original_progress
  })
  const totalCost = state.rootBudget.reduce((total, { price_amount }) => total + (+price_amount), 0)

  const auxConcepts = [...new Set(Object.values(newChilds).reduce((arr, item) => [...arr, ...item], []).filter(({ type }) => type === 'CONCEPT'))]

  const aux = auxConcepts.filter(elem => {
    if (auxkeys[elem.id] !== undefined) {
      return (auxkeys[elem.id] === elem.original_progress)
    } else {
      return true
    }
  })

  const allConcepts = aux === undefined ? [] : aux

  const transformedBudgetItems = Object.keys(newChilds)
    .reduce((data, id) => {
      const childs = newChilds[id]
      const budgetItems = childs.filter(({ type }) => type === 'BUDGET_ITEM')

      return {
        ...data,
        [id]: [
          ...childs.filter(({ type }) => type === 'CONCEPT'),
          ...(budgetItems.map((budgetItem) => {
            const filteredConcepts = allConcepts
              .filter(({ parent_ids }) => parent_ids.split('|').some((id) => +id === budgetItem.id))
            const totalAmountConcepts = budgetItem.original_progress - filteredConcepts.reduce((total, { original_progress, progress }) => total + (original_progress - progress), 0)

            filteredConcepts.forEach((elem) => elem.progress_percentage = (elem.progress / elem.amount) * 100)

            if (filteredConcepts.length > 0) {
              return {
                ...budgetItem,
                progress: totalAmountConcepts,
                progress_percentage: (totalAmountConcepts / totalCost) * 100,
                progress_amount: null
              }
            } else {
              return {
                ...budgetItem
              }
            }
          }))
        ]
      }
    }, {})

  const newRootBudget = state.rootBudget
    .map((budgetItem) => {
      const filteredConcepts = allConcepts
        .filter(({ parent_ids }) => parent_ids.split('|').some((id) => +id === budgetItem.id))
      if (transformedBudgetItems[budgetItem.id]) {
        const totalAmountConcepts = transformedBudgetItems[budgetItem.id].reduce((total, { progress }) => total + progress, 0)
        return {
          ...budgetItem,
          progress: totalAmountConcepts,
          progress_percentage: (totalAmountConcepts / totalCost) * 100,
          progress_amount: null
        }
      } else {
        return {
          ...budgetItem
        }
      }
    })

  return {
    ...state,
    budgetFetchingProgress: null,
    fetchingBudget: false,
    rootBudget: newRootBudget,
    childBudgets: {
      ...newChilds,
      ...transformedBudgetItems
    }
  }
}

const searching = (state = INITIAL_STATE, { search, searchType }) => ({
  ...state,
  rootBudget: null,
  childBudgets: {},
  searchText: search,
  searchInternalType: searchType
})

const clearSearch = (state = INITIAL_STATE, { search, searchType }) => ({
  ...state,
  rootBudget: null,
  childBudgets: {},
  searchText: null,
  searchInternalType: null
})

const startMapping = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
  isMapSuccess: false
})

const mappingDone = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  isMapSuccess: true
})

// PROJECTS
const getChargeTypeReducer = (state = INITIAL_STATE, { id }) => {
  const project = (state.projects || []).filter(({ id: pId }) => pId * 1 === id * 1)[0]
  // console.log('Set chargeTypeData: ', project ? project.ChargeTypes : []);
  return ({
    ...state,
    projectSelected: id,
    chargeTypes: project ? project.ChargeTypes : []
  })
}
// const setChargeTypeReducer = (state = INITIAL_STATE, { payload }) => {
//   return ({
//     ...state,
//     chargeTypesPrueba: payload
//   })
// }

const clearAndGetPeriods = (state = INITIAL_STATE, { id }) => ({
  ...state,
  budgetSelected: id,
  periodsFromBudget: [],
  fetchingPeriods: true
})

const togglingPeriod = (state = INITIAL_STATE) => ({
  ...state,
  fetchingPeriods: true
})

const createPeriod = (state = INITIAL_STATE) => ({
  ...state,
  fetchingPeriods: true,
  periodsFromBudget: []
})

const getPeriods = (state = INITIAL_STATE, { periods }) => ({
  ...state,
  periodsFromBudget: periods,
  fetchingPeriods: false
})

const updateProgress = (state = INITIAL_STATE, { periods }) => ({
  ...state,
  fetchingBudget: true
})

const updateProgressDone = (state = INITIAL_STATE, { periods }) => ({
  ...state,
  fetchingBudget: false
})

const updateProgressReal = (state = INITIAL_STATE, { periods }) => ({
  ...state,
  fetchingPeriods: true
})

const updateProgressRealSuccess = (state = INITIAL_STATE, { }) => ({
  ...state,
  fetchingPeriods: false
})

const setProgress = (state = INITIAL_STATE, { conceptId, parentIds, data }) => ({
  ...state,
  updatedProgress: [
    ...state.updatedProgress,
    {
      id: conceptId,
      parents: parentIds,
      ...data
    }
  ]
})

const getPeriodUnique = (state = INITIAL_STATE) => ({
  ...state,
  fetchingPeriods: true,
  period: null
})

const closeProgress = (state = INITIAL_STATE) => ({
  ...state,
  fetchingPeriods: true
})

const openProgress = (state = INITIAL_STATE) => ({
  ...state,
  fetchingPeriods: true
})

const getPeriodSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  period: data.period,
  budgetData: data.budget,
  fetchingPeriods: false
})

const HANDLERS = {
  ...unit_state_handles,
  ...burget_view_handles,
  [Types.FETCH_PROJECTS]: fetchingUsers,
  [Types.UPDATE_PROJECTS]: updateProjects,
  [Types.ERROR]: handleError,
  [RoutesTypes.HANDLE_ERROR]: handleError,
  [Types.FETCH_PROJECT]: fetchingProject,
  [Types.PROJECT_FETCHED]: projectUpdated,
  [Types.CLEAN_VIEW]: clean,
  [Types.GET_BUDGET_BY_ID]: gettingBudget,
  [Types.GET_BUDGET_PROGRESS_BY_ID]: gettingBudget,
  [Types.GET_BUDGET_PROGRESS_BY_PERIOD]: gettingBudgetPeriod,
  [Types.GET_BUDGET_SUCCESS]: successBudget,
  [Types.GET_BUDGET_SECOND_SUCCESS]: successBudgetSecond,
  [Types.GET_HISTORY_SUCCESS]: successHistory,
  [Types.GET_ACCOUNT_COSTS]: getCosts,
  [Types.ACCOUNT_SUCCESS]: loadingAccount,
  [Types.UPDATE_ACCOUNT]: accountUpdated,
  [Types.GET_BUDGET_CHILD]: gettingChilds,
  [Types.GET_BUDGET_CHILD_SECOND]: gettingChildsSecond,
  [Types.GET_BUDGET_PROGRESS_CHILD]: gettingChildsProgress,
  [Types.GET_BUDGET_SECOND_PROGRESS_CHILD]: gettingChildsSecondProgress,
  [Types.GET_BUDGET_CHILD_SUCCESS]: gettingChildSuccess,
  [Types.GET_BUDGET_CHILD_SECOND_SUCCESS]: gettingChildSecondSuccess,
  [Types.GET_BUDGET_CHILD_PROGRESS_SUCCESS]: gettingChildProgressSuccess,
  // [Types.DELETE_BUDGET]: deleteBudget,
  [Types.SEARCH_SOMETHING]: searching,
  [Types.CLEAR_SEARCH]: clearSearch,
  [Types.MAP_ACCOUNT_COST]: startMapping,
  [Types.MAP_ACCOUNT_COST_SUCCESS]: mappingDone,

  [Types.GET_PERIODS_OF_BUDGET]: clearAndGetPeriods,
  [Types.GET_PERIODS_OF_BUDGET_SUCCESS]: getPeriods,
  // Projects
  [Types.GET_CHARGE_TYPES_OPERATION]: getChargeTypeReducer,
  [Types.TOGGLE_PERIOD]: togglingPeriod,
  [Types.DELETE_PERIOD]: togglingPeriod,
  [Types.CREATE_PERIOD]: createPeriod,
  [Types.CREATE_PERIOD_SUCCESS]: getPeriods,

  [Types.UPDATE_PROGRESS]: updateProgress,
  [Types.UPDATE_PROGRESS_DONE]: updateProgressDone,
  [Types.UPDATE_PROGRESS_REAL]: updateProgressReal,
  [Types.UPDATE_PROGRESS_REAL_SUCCESS]: updateProgressRealSuccess,
  [Types.SET_PROGRESS]: setProgress,
  [Types.OPEN_PROGRESS]: openProgress,
  [Types.GET_PERIOD_SUCCESS]: getPeriodSuccess,
  [Types.GET_PERIOD]: getPeriodUnique,
  [Types.CLOSE_PROGRESS]: closeProgress,
  [Types.UPDATE_PROGRESS_EXCEL]: updateProgressExcel,
  [Types.GET_GENERAL_HISTORY]: getHistory,
  [Types.GET_EXCEL_REAL]: getExcel,
  [Types.GET_BUDGET_BASE_EXCEL]: getExcel,
  [Types.GET_BUDGET_BASE_EXCEL_COMPARE]: getExcel,
  [Types.GET_COST_EXCEL]: getExcel,
  [Types.GET_CHARGE_TYPE_EXCEL]: getExcel,
  [Types.GET_CHARGE_TYPE_EXCEL_COMPARE]: getExcel,
  [Types.GET_COST_EXCEL_COMPARE]: getExcel,
  [Types.GET_COST_EXCEL_COMPARE_UNIT_COST]: getExcel,
  [Types.GET_COST_EXCEL_COMPARE_TYPE]: getExcel,
  [Types.GET_EXCEL_BUDGET]: getExcel,
  [Types.GET_EXCEL_SUCCESS]: getExcelSuccess,
  [Types.UPDATE_PROGRESS_EXCEL_SUCCESS]: updateProgressExcelSuccess,
  [Types.UPDATE_PROGRESS_REAL_EXCEL]: updateProgressRealExcel,
  [Types.UPDATE_PROGRESS_REAL_EXCEL_SUCCESS]: updateProgressRealExcelSuccess

}
export default createReducer(INITIAL_STATE, HANDLERS)
