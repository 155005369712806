import React from 'react'
import styled from 'styled-components'
import { Button } from 'reactstrap'

const buttonPreflight = ({ children, ...props }) => <Button tag='button' {...props}>{children}</Button>

export default styled(buttonPreflight)`
  background-color: ${({ color }) => color || '#EA1D23'} !important;
  color: ${({ fontColor }) => fontColor || '#fff'};
  border: none !important;
  width: ${({ fill }) => fill ? '100%' : 'auto'};
  box-shadow: none !important;
`
