export default {
  global: {
    colors: {
      secondary: '#324d7b',
      emsi: '#EA1D23',
      brand: '#EA1D23'
    }
  },
  table: {
    header: {
      border: undefined
    }
  }
}
