import { createStore, applyMiddleware } from 'redux'
import { createCycleMiddleware } from 'redux-cycles'
import { composeWithDevTools } from 'redux-devtools-extension'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'

import { run } from '@cycle/run'
import { makeHTTPDriver } from '@cycle/http'
import storageDriver from '@cycle/storage'

import Reducers from './reducers'
import Cycles from './cycles'

// Creating our history path
export const history = createBrowserHistory()

// Initiating our cycle middleware
const cycleMiddleware = createCycleMiddleware()

// Getting our drivers
const { makeActionDriver, makeStateDriver } = cycleMiddleware

// All our redux middlewares goes here
const enhancer = composeWithDevTools(
  applyMiddleware(cycleMiddleware),
  applyMiddleware(routerMiddleware(history))
)

export default createStore(connectRouter(history)(Reducers), enhancer)

// Facing the truth
run(Cycles, {
  STATE: makeStateDriver(),
  ACTION: makeActionDriver(),
  HTTP: makeHTTPDriver(),
  STORAGE: storageDriver
})
