import React from "react";

const SvgMapeoexterno = props => (
  <svg viewBox="0 0 141.7 141.7" {...props}>
    <path
      className="mapeoexterno_svg__st0"
      d="M107.3 48.9c-1.3 1.3-3.5 1.3-4.8 0L74.4 20.8v76.5c0 1.9-1.5 3.4-3.4 3.4s-3.4-1.5-3.4-3.4V20.8l-28 28.1c-1.3 1.3-3.5 1.3-4.8 0-1.3-1.3-1.3-3.5 0-4.8l33.9-33.9c.7-.7 1.5-1 2.4-1s1.7.3 2.4 1l33.9 33.9c1.3 1.4 1.3 3.5-.1 4.8zM134.4 110.2v18.2c0 2.5-2.2 4.5-4.9 4.5H11.6c-2.7 0-4.9-2-4.9-4.5v-18.2c0-2.5 2.2-4.5 4.9-4.5h117.9c2.7-.1 4.9 2 4.9 4.5zm-9.8 11.8v-5.5c0-1-.8-1.8-1.8-1.8H18.3c-1 0-1.8.8-1.8 1.8v5.5c0 1 .8 1.8 1.8 1.8h104.5c1 0 1.8-.8 1.8-1.8z"
    />
  </svg>
);

export default SvgMapeoexterno;
