import { combineCycles } from 'redux-cycles'

// Cycles
import { NotificationsCycles } from 'app/notifications/duck'
import { AlertsCycles } from 'app/alerts/duck'
import { AuthCycles } from 'app/auth/duck'
import { RoutesCycles } from 'app/routes/duck'
import { OrgsCycles } from 'app/orgs/duck'
import { UsersCycles } from 'app/users/duck'
import { ProjectsCycles } from 'app/projects/duck'

export default combineCycles(
  AuthCycles,
  NotificationsCycles,
  RoutesCycles,
  OrgsCycles,
  AlertsCycles,
  UsersCycles,
  ProjectsCycles
)
