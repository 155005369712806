import { createActions } from 'reduxsauce'

const { Creators, Types } = createActions({
  requestAlert: ['title', 'message', 'redirect'],
  hideAlert: null
}, {
  prefix: 'alerts/'
})

export { Creators as Actions, Types }
