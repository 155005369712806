import React, { Component } from 'react'
import { Box, Text } from 'grommet'
import { ColorInput, materialColors, DropInput } from 'grommet-controls'
import { connect } from 'react-redux'
import { SwatchesPicker } from 'react-color'

import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap'
import classnames from 'classnames'

import Button from 'components/Button'
import Modal from 'components/Modal'
import withTheme from 'components/HOC/withTheme'
import { OrgsActions } from 'app/orgs/duck'
import Permission from '../Permission'
import SvgGuardar from '../../icons/Guardar'

const state = state => ({
  org: state.auth.orgSelected || {},
  customizing: state.orgs.customizingOrg,
  template: state.auth.templates || {}
})

const ColorRound = ({ color }) => (
  <div style={{ borderRadius: 9999, backgroundColor: color, width: 18, height: 18 }} />
)

const dispatch = {
  requestCustomization: OrgsActions.requestOrgCustomization,
  requestTemplate: OrgsActions.requestOrgTemplate
}

@connect(state, dispatch)
@withTheme
class Customization extends Component {
  state = {
    editForm: {},
    templateForm: {},
    type: null,
    activeTab: '1'
  }

  toggle = (tab) => {
    this.setState(prevState => ({
      type: tab === '1' ? 'edit' : 'template',
      activeTab: prevState.activeTab !== tab ? tab : this.state.activeTab
    }))
  }

  updateFormField = (field, form) => e => {
    const value = e.target.value
    this.setState(prevState => ({
      [`${form}Form`]: {
        ...prevState[`${form}Form`],
        [field]: value
      }
    }))
  }

  updateColor = (name, form) => (color) => {
    this.updateFormField(name, form)({ target: { value: color.hex } })
  }

  componentDidMount = () => {
    this._reader = new FileReader()

    this.toggle(this.state.activeTab)

    this._reader.addEventListener('load', () => {
      this.setState(prevState => ({
        [`${prevState.type}Form`]: {
          ...prevState[`${prevState.type}Form`],
          logo: this._reader.result
        }
      }))
    }, false)
  }

  renderFormCustom = type => {
    const { org, theme, template } = this.props
    const { editForm, templateForm } = this.state

    return (
      <TabPane tabId='2'>
        <Row>
          <Col sm='12'>
            <Box
              direction='column'
              margin={{ vertical: 'small' }}
            >
              <Text size='medium' weight='bold'>Color primario:</Text>
              <DropInput
                dropIcon={
                  <ColorRound color={type === 'edit' ? (editForm.primary_color || editForm.primary_color === '' ? editForm.primary_color : org.primary_color) : (templateForm.primary_color || templateForm.primary_color === '' ? templateForm.primary_color : template.primary_color)} />
                }
                value={type === 'edit' ? (editForm.primary_color || editForm.primary_color === '' ? editForm.primary_color : org.primary_color) : (templateForm.primary_color || templateForm.primary_color === '' ? templateForm.primary_color : template.primary_color)}
                onChange={this.updateFormField('primary_color', type)}
                dropContent={
                  <Box>
                    <SwatchesPicker
                      colors={Object.values(materialColors).reduce((accum, item) => [ ...accum, [...Object.values(item).map(val => val === '#212121' ? '#000000' : val)] ], [])}
                      color={type === 'edit' ? (editForm.primary_color || editForm.primary_color === '' ? editForm.primary_color : org.primary_color) : (templateForm.primary_color || templateForm.primary_color === '' ? templateForm.primary_color : template.primary_color)}
                      onChange={this.updateColor('primary_color', type)}
                    />
                  </Box>
                }
              />
            </Box>
            <Box
              direction='column'
              margin={{ vertical: 'small' }}
            >
              <Text size='medium' weight='bold'>Color de botones e íconos:</Text>
              <DropInput
                dropIcon={
                  <ColorRound color={type === 'edit' ? (editForm.font_color || editForm.font_color === '' ? editForm.font_color : org.font_color) : (templateForm.font_color || templateForm.font_color === '' ? templateForm.font_color : template.font_color)} />
                }
                value={type === 'edit' ? (editForm.font_color || editForm.font_color === '' ? editForm.font_color : org.font_color) : (templateForm.font_color || templateForm.font_color === '' ? templateForm.font_color : template.font_color)}
                onChange={this.updateFormField('font_color', type)}
                dropContent={
                  <Box>
                    <SwatchesPicker
                      colors={Object.values(materialColors).reduce((accum, item) => [ ...accum, [...Object.values(item).map(val => val === '#212121' ? '#000000' : val)] ], [])}
                      color={type === 'edit' ? (editForm.font_color || editForm.font_color === '' ? editForm.font_color : org.font_color) : (templateForm.font_color || templateForm.font_color === '' ? templateForm.font_color : template.font_color)}
                      onChange={this.updateColor('font_color', type)}
                    />
                  </Box>
                }
              />
            </Box>
            <Box
              direction='column'
              margin={{ vertical: 'small' }}
            >
              <Text size='medium' weight='bold'>Logo:</Text>
              <Box
                fill='horizontal'
                margin={{ vertical: 'small' }}
              >
                <input accept='image/*' type='file' width='100%' onChange={this.handleLogo} />
              </Box>
            </Box>
            <Box
              direction='row'
              justify='end'
              margin={{ vertical: 'small' }}
            >
              <Button
                icon
                color={theme.primary}
                onClick={this.send}
                type='submit'
              >
                <SvgGuardar fill={theme.font} width={80} />
              </Button>
            </Box>
          </Col>
        </Row>
      </TabPane>
    )
  }

  componentWillUnmount = () => {
    this.setState({
      primary_color: null,
      font_color: null,
      logo: null
    }, () => {
      this._reader = null
    })
  }

  handleLogo = ({ target: { files } }) => {
    this._reader.readAsDataURL(files[0])
  }

  send = () => {
    const { editForm, templateForm, type } = this.state

    this.props[type === 'edit' ? 'requestCustomization' : 'requestTemplate'](type === 'edit'
      ? editForm
      : templateForm
    )

    this.props.onClose()
  }

  render = () => {
    const { show, onClose } = this.props
    const { primary_color, font_color, logo } = this.state

    return (
      <Modal show={show} onClose={onClose} title='Personalización'>
        <Box
          width='medium'
          direction='column'
          style={{ padding: '10px' }}
        >
          <Nav tabs>
            <NavItem style={{ cursor: 'pointer' }}>
              <Permission>
                {({ role }) => (
                  (role === 'super' || role === 'admin') && (
                    <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1') }}>
                      <Text>Tema</Text>
                    </NavLink>
                  )
                )}
              </Permission>
            </NavItem>
            <NavItem style={{ cursor: 'pointer' }}>
              <Permission role='super'>
                <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2') }}>
                  <Text>Plantilla</Text>
                </NavLink>
              </Permission>
            </NavItem>
          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            <Permission>
              {({ role }) => (
                (role === 'super' || role === 'admin') && (
                  <TabPane tabId='1'>
                    <Row>
                      <Col sm='12'>
                        {this.renderFormCustom('edit')}
                      </Col>
                    </Row>
                  </TabPane>
                )
              )}
            </Permission>
            <Permission role='super'>
              <TabPane tabId='2'>
                <Row>
                  <Col sm='12'>
                    {this.renderFormCustom('template')}
                  </Col>
                </Row>
              </TabPane>
            </Permission>
          </TabContent>
        </Box>
      </Modal>
    )
  }
}

export default Customization
