import React, { Component } from 'react'
import { CircleQuestion, CircleInformation } from 'grommet-icons'
import { connect } from 'react-redux'

// Notification View
import Notification from 'components/Notification'
import NotificationLayer from 'components/NotificationLayer'
import { NotificationsActions } from './duck'

const state = state => ({
  stack: state.notifications.stack,
  showing: state.notifications.showing
})

const dispatch = {
  hideNotification: NotificationsActions.hideNotification
}

@connect(state, dispatch)
class NotificationsHandler extends Component {
  shouldComponentUpdate = nextProps => (
    nextProps.stack.length > this.props.stack.length ||
    nextProps.showing !== this.props.showing
  )

  componentWillReceiveProps = nextProps => {
    const { showing } = nextProps

    if (Object.keys(showing).length > 0) Object.keys(showing).forEach(id => { this.clearNotification(id, nextProps) })
  }

  componentWillUnmount = () => {
    const { showing } = this.props

    if (showing) Object.keys(showing).forEach(id => { clearTimeout(this[`n_${id}`]) })
  }

  clearNotification = (id, props) => {
    const { hideNotification, delay = 3000 } = props

    if (!this[`n_${id}`]) this[`n_${id}`] = setTimeout(hideNotification, delay, id)
  }

  hideNotification = id => () => this.props.hideNotification(id)

  render = () => {
    const { stack, showing } = this.props

    return (
      <NotificationLayer>
        {stack.map(({ message, id, type }) => (
          <Notification
            key={id}
            onClick={this.hideNotification(id)}
            pose={showing[id] ? 'enter' : 'exit'}
            icon={type === 'error' ? CircleInformation : type === 'info' ? CircleQuestion : CircleInformation}
            error={type === 'error'}
            info={type === 'info'}
            message={message || 'no message'}
          />
        ))}
      </NotificationLayer>
    )
  }
}

export default NotificationsHandler
