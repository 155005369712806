import React from 'react'
import loadable from 'react-loadable'
import { Text } from 'grommet'

const load = () => (
  <Text>Cargando...</Text>
)

export const BudgetViewer = loadable({
  loader: () => import('./BudgetView/BudgetViewer'),
  loading: load
})
export const ReportViewer = loadable({
  loader: () => import('./Reports/ReportCompareBudget'),
  loading: load
})

export const ReportViewerCA = loadable({
  loader: () => import('./Reports/ReportCompareAdvanceBudget'),
  loading: load
})

export const ReportSpreadAccounts = loadable({
  loader: () => import('./Reports/ReportSpreadAccounts'),
  loading: load
})

export const ReportSpreadTypes = loadable({
  loader: () => import('./Reports/ReportSpreadTypes'),
  loading: load
})

export const ReportSpreadUnitCosts = loadable({
  loader: () => import('./Reports/ReportSpreadUnitCosts'),
  loading: load
})
export const ReportGraphsHistory = loadable({
  loader: () => import('./Reports/ReportGraphHistory'),
  loading: load
})
export const ReportGraphsActual = loadable({
  loader: () => import('./Reports/ReportGraphActual'),
  loading: load
})
export const ReportGraphsKPI = loadable({
  loader: () => import('./Reports/ReportGraphKPI'),
  loading: load
})
export const ReportGraphsUnitsCosts = loadable({
  loader: () => import('./Reports/ReportGraphUnitCosts'),
  loading: load
})

export const AccountCostsViewer = loadable({
  loader: () => import('./AccountCosts'),
  loading: load
})

export const AccountMapViewer = loadable({
  loader: () => import('./ExternalMapping'),
  loading: load
})

export const BudgetMapperViewer = loadable({
  loader: () => import('./BudgetMapper'),
  loading: load
})

export const ProgressViewer = loadable({
  loader: () => import('./ProgressViewer'),
  loading: load
})

export const RealViewer = loadable({
  loader: () => import('./RealViewer'),
  loading: load
})

export const UnitFactorsViewer = loadable({
  loader: () => import('./UnitFactors/UnitFactors'),
  loading: load
})
