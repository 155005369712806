import React from "react";

const SvgPresupuestos = props => (
  <svg viewBox="0 0 141.7 141.7" {...props}>
    <path
      className="presupuestos_svg__st0"
      d="M123 38.2L92.8 8c-.6-.6-1.5-1-2.4-1H26.9c-5.5 0-10 4.5-10 10v107.1c0 5.5 4.5 10 10 10h87c5.5 0 10-4.5 10-10V40.5c0-.9-.3-1.7-.9-2.3zm-7.1 2.3h-22c-1.8 0-3.3-1.5-3.3-3.3v-22l25.3 25.3zm1.4 83.7c0 1.8-1.5 3.3-3.3 3.3H27c-1.8 0-3.3-1.5-3.3-3.3V17.1c0-1.8 1.5-3.3 3.3-3.3h56.9v23.4c0 5.5 4.5 10 10 10h23.4v77z"
    />
    <path
      className="presupuestos_svg__st0"
      d="M89.6 88.7c0 2.9-.6 5.5-1.7 7.9-1.1 2.4-2.7 4.4-4.7 6.1-2 1.7-4.5 3.1-7.4 4.1-2.9 1-6.2 1.6-9.8 1.8l-1.2 11.8c0 .2-.1.4-.2.6-.1.2-.3.4-.5.5-.3.1-.6.2-1.1.3-.5.1-1 .1-1.8.1s-1.5 0-2-.1-.9-.2-1.2-.3c-.3-.1-.5-.3-.6-.5-.1-.2-.1-.5 0-.8l1.2-11.7c-1.7-.2-3.3-.5-4.8-.8-1.5-.3-2.8-.7-4-1.1-1.2-.4-2.1-.8-3-1.2-.8-.4-1.4-.8-1.8-1.2-.4-.4-.6-.9-.8-1.5-.1-.6-.2-1.5-.2-2.5 0-.8 0-1.5.1-2s.2-1 .3-1.3c.1-.3.3-.6.6-.7.2-.1.5-.2.8-.2.5 0 1.1.3 2 .8.9.5 2 1.1 3.4 1.7 1.4.6 3.2 1.2 5.2 1.8 2.1.5 4.5.8 7.4.8 5.1 0 9-1 11.7-3 2.7-2 4-4.8 4-8.2 0-2.1-.5-3.9-1.5-5.3s-2.3-2.7-3.9-3.7-3.4-1.9-5.5-2.7c-2-.7-4.1-1.5-6.2-2.3-2.1-.8-4.2-1.6-6.2-2.6s-3.9-2.2-5.4-3.6c-1.6-1.4-2.9-3.2-3.8-5.3-1-2.1-1.5-4.6-1.5-7.7 0-2.4.4-4.7 1.2-6.8.8-2.1 2.1-3.9 3.7-5.5 1.7-1.6 3.8-2.9 6.3-3.8 2.5-1 5.5-1.6 8.8-1.8l1.1-11c0-.2.1-.4.2-.6.1-.2.3-.4.5-.5.3-.1.6-.2 1-.3.4-.1 1-.1 1.8-.1s1.5 0 2 .1.9.2 1.2.4c.3.2.4.4.5.6.1.2.1.5.1.8L72.7 39c1.1.1 2.2.3 3.4.6 1.2.3 2.3.6 3.3.9 1 .3 2 .7 2.8 1.1.8.4 1.4.8 1.7 1.1.3.3.5.6.7.8.1.3.2.5.3.9.1.3.1.7.2 1.1.1.4.1.9.1 1.5 0 .7 0 1.3-.1 1.8s-.1.9-.2 1.2c-.1.3-.3.5-.4.7-.2.1-.4.2-.7.2-.4 0-1-.2-1.9-.7-.9-.5-2-1-3.3-1.5-1.3-.5-2.8-1-4.6-1.5-1.7-.5-3.7-.7-5.8-.7-2.4 0-4.5.2-6.2.7-1.7.5-3.1 1.1-4.2 2-1.1.8-1.8 1.9-2.3 3.1-.5 1.2-.7 2.5-.7 3.9 0 2.2.5 4 1.5 5.4 1 1.4 2.3 2.7 3.9 3.7s3.4 1.9 5.5 2.6c2.1.7 4.2 1.5 6.3 2.2 2.1.8 4.3 1.6 6.3 2.6 2.1.9 3.9 2.1 5.5 3.6 1.6 1.4 2.9 3.2 3.9 5.3 1.4 1.5 1.9 4 1.9 7.1z"
    />
  </svg>
);

export default SvgPresupuestos;
