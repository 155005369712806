import React, { Component } from 'react'
import { Box, Table, TableHeader, TableCell, Text, TableRow, Heading, TableBody, CheckBox } from 'grommet'
import hexToRgba from 'hex-to-rgba'
import Button from 'components/Button'
import SvgGuardar from '../../../../icons/Guardar'

import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { ProjectsActions } from '../../duck'
import { Close } from 'grommet-icons/es6'
import { Alert, Input } from 'antd'

const INITIAL_STATE = {
  dataLocal: null,
  dataLocalNames: {}
}

// recibe en el estado los reucers del store, se convierte en una propiedad del componente
const state = state => ({
  projectSelected: state.projects.projectSelected,
  chargeTypes: state.projects.chargeTypes,
  accountCosts: state.projects.UFAcountCosts,
  accessControl: state.auth.orgSelected.accessControl,
  period: state.projects.periodsFromBudget || {}

})

const dispatch = {
  updateChargeTypesArr: ProjectsActions.updateArrayChargeTypesOperation,
  getAccountCosts: ProjectsActions.UFGetAccountCosts,
  getPeriod: ProjectsActions.getPeriodsOfBudget,
}

// Funcion de redux que recibe (mapStateToProps, DispachState to props)
@connect(state, dispatch)
class TypeChargeComponentRead extends Component {
  state = INITIAL_STATE
  saveData = () => {
    const dataFormated = this.state.dataLocal.map(e => ({
      id: parseInt(e.split('-')[0]),
      cost_type_id: parseInt(e.split('-')[1]),
      name: this.state.dataLocalNames[e.split('-')[0]] ? this.state.dataLocalNames[e.split('-')[0]] : undefined
    }))
    this.props.updateChargeTypesArr(dataFormated, this.props.dataTypes.id)
    this.props.callBackSave()
  }
  setChecked = (id, cost_type_id) => {
    const data = (this.state.dataLocal || [])
    let aux = data.indexOf(id + '-' + cost_type_id)
    let aux2 = -1
    data.forEach((e, i) => {
      if (e.includes(id + '-')) {
        aux2 = i
      }
    })
    if (aux >= 0) {
      data.splice(aux, 1)
      this.setState(prevState => ({
        ...prevState,
        dataLocal: data
      }))
    } else if (aux2 >= 0) {
      data.splice(aux2, 1)
      this.setState(prevState => ({
        ...prevState,
        dataLocal: data
      }))
      data.push(id + '-' + cost_type_id)
      this.setState(prevState => ({
        ...prevState,
        dataLocal: data
      }))
    } else {
      data.push(id + '-' + cost_type_id)
      this.setState(prevState => ({
        ...prevState,
        dataLocal: data
      }))
    }
  }

  setChecked2 = (id, newName) => {
    const data = ({ ...this.state.dataLocalNames } || {})
    data[id] = newName
    this.setState(prevState => ({
      ...prevState,
      dataLocalNames: data
    }))
  }

  componentDidUpdate = () => {
    if (this.props.accountCosts.length === 0) {
      this.props.getAccountCosts({
        project: this.props.dataTypes.id,
        budget: this.props.projectSelected
      })
      setTimeout(() => {
        this.props.getPeriod(this.props.projectSelected, this.props.dataTypes.id)
      }, 500)
    }
  }

  render () {
    const { theme, dataTypes, projectSelected, match, accessControl } = this.props
    const data = dataTypes.ChargeTypes && dataTypes.ChargeTypes.filter(item => {
      return item.budget_id.toString() === projectSelected.toString()
    })

    // this.props.getChargeTypeAc()
    let auxPeriodOpen = false
    let loaded = false
    this.props.period && this.props.period.forEach((e, i) => {
      if (e.active_advance.toString() === '1' || e.active_real.toString() === '1') {
        auxPeriodOpen = true
      }
      if (i === this.props.period.length - 1) {
        loaded = true
      }
    })
    if(accessControl && (this.state.canInteract === null || this.state.canInteract === undefined)){
      this.setState({
        canInteract: accessControl[this.props.dataTypes.id]
      })
    }
    const testAux = []
    data && data.forEach((e) => {
      if (e.cost_type_id) {
        testAux.push(e.id + '-' + e.cost_type_id)
      }
    })
    const cols = this.props.accountCosts ? this.props.accountCosts.length > 0 ? this.props.accountCosts.map(e => ({
      ...e,
      value: e.id
    })) : [] : []
    if (this.state.dataLocal == null) {
      this.setState(prevState => ({
        ...prevState,
        dataLocal: this.props.chargeTypesBudget ? this.props.chargeTypesBudget : testAux
      }))
    }


    return (
      <Box direction='row' flex align='start' justify='baseline' fill margin={{ horizontal: 'small' }}>
        <Box
          pad='medium'
          background='white'
          round='xsmall'
          responsive
          fill='vertical'
          style={{ width: '100%', position: 'relative', overflowY: 'auto' }}
        >
          <Box direction='row'
            align='center'
            justify='between'>
            <Heading margin={{ vertical: 'xsmall' }} level={3}>Tipo de cargo</Heading>
            <Button color='white' onClick={this.props.callBackSave} icon>
              <Close color='black' />
            </Button>
          </Box>
          {
            auxPeriodOpen && loaded &&
            <Alert
              style={{ margin: '10px' }}
              message='Periodo abierto, no se puede modificar el mapeo de tipos' type='error' />
          }
          <Box direction='row' align='center' margin={{ top: 'large' }}>
            <div style={{ overflowY: 'auto', height: '100%' }}>
              <Table style={{ width: '100%' }}>
                <TableHeader>
                  <TableRow>
                    <TableCell scope='col'>
                      <Text weight='normal' align='start'>ID</Text>
                    </TableCell>
                    <TableCell scope='col'>
                      <Text weight='normal' align='start'>Nombre</Text>
                    </TableCell>
                    {cols.map(({ description }, i) => (
                      <TableCell scope='col'>
                        <Text weight='normal' align='start'>{description}</Text>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {data && data.map(({ name, id, cost_type_id, budget_charge_type_id }, i) => (
                    <TableRow key={i} borderBottom='none' style={
                      {
                        backgroundColor: (i % 2) ? 'rgba(0, 0, 0, 0.1)' : null,
                        borderBottom: `0px`
                      }
                    }>
                      <TableCell scope='row'>
                        <Text weight='normal'>{budget_charge_type_id}</Text>
                      </TableCell>
                      <TableCell scope='row'>
                        <Input weight='normal'
                          placeholder={name}
                          onKeyUp={(e) =>
                            this.setChecked2(id, e.target.value)
                          }
                          style={
                            {
                              border: `1px solid black`,
                              backgroundColor: 'white',
                              padding: '3px',
                              borderRadius: '2px',
                              width: '100%',
                              fontSize: '14px',
                              lineHeight: '12px'
                            }
                          } />
                      </TableCell>
                      {cols.map(({ value }) => {
                        return (
                          <TableCell scope='row'>
                            <CheckBox
                              disabled={(auxPeriodOpen && loaded) || !this.state.canInteract}
                              checked={(this.state.dataLocal && this.state.dataLocal.indexOf(id + '-' + value) >= 0)}
                              onChange={this.setChecked.bind(this, id, value)}
                            />
                          </TableCell>
                        )
                      }
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>

          </Box>
          {!auxPeriodOpen && this.state.canInteract && <Button className='button_save'
            color={theme.primary}
            onClick={this.saveData.bind()}
            style={{
              width: '25%',
              padding: '10px',
              paddingRight: 15,
              paddingLeft: 15,
              'background-color': '#2196f3!important',
              color: 'white',
              borderRadius: '10px',
              marginTop: '25px',
              marginLeft: 'auto'
            }}
          >Guardar</Button>}
        </Box>
      </Box>
    )
  }
}

export default TypeChargeComponentRead
