import { createReducer } from 'reduxsauce'
import { Types } from './operations'
import uuid from 'uuid/v4'

const INITIAL_STATE = {
  stack: [],
  showing: {}
}

const stackNotification = (state = INITIAL_STATE, { message, messageType }) => {
  const id = uuid()

  return {
    ...state,
    stack: [
      { message, type: messageType, id },
      ...state.stack
    ],
    showing: {
      ...state.showing,
      [id]: true
    }
  }
}

const hideNotification = (state = INITIAL_STATE, { id }) => ({
  ...state,
  showing: {
    ...state.showing,
    [id]: false
  }
})

const HANDLERS = {
  [Types.REQUEST_NOTIFICATION]: stackNotification,
  [Types.HIDE_NOTIFICATION]: hideNotification
}

export default createReducer(INITIAL_STATE, HANDLERS)
