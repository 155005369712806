import { Component } from 'react'
import { connect } from 'react-redux'
import { isAFunction } from 'helpers'

class UserData extends Component {
  render = () => {
    const { children, user = {} } = this.props

    return isAFunction(children) ? children(user || {}) : children
  }
}

const state = state => ({
  user: state.auth.user
})

export default connect(state)(UserData)
