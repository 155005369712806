import React, { Component } from 'react'
import { connect } from 'react-redux'

import withoutLogin from 'components/HOC/withoutLogin'
import withTheme from 'components/HOC/withTheme'
import { Box, Heading, Text } from 'grommet'

import Input from 'components/Controlled/Input'
import Button from 'components/Controlled/Button'
import { Box as Div } from 'reflexbox'
import { AuthActions } from './duck'
import { push } from 'connected-react-router'
import { formToArray } from 'helpers'

import { Typography } from 'antd'

const { Title } = Typography

const dispatch = {
  login: AuthActions.requestLogin,
  resetPassword: AuthActions.sendPasswordReset,
  navigate: push,
  clear: AuthActions.clearLogin
}

const state = state => ({
  loading: state.auth.loading,
  sent: state.auth.emailSent
})

@connect(state, dispatch)
@withTheme
@withoutLogin
class ResetPassword extends Component {
  goBack = () => {
    this.props.navigate('/login')
    this.props.clear()
  }

  sendReset = e => {
    e.preventDefault()

    const values = formToArray(e.target)
    this.props.resetPassword(values.resetEmail)
  }

  render = () => {
    const { theme } = this.props

    return (
      <Div w={1}>
        <Title
          level={4}
          style={{ color: theme.primary, marginBottom: '20px' }}
        >
          Cambiar contraseña
        </Title>
        {!this.props.sent
          ? (
            <form onSubmit={this.sendReset}>
              <div
                style={{ margin: '20px 0' }}
              >
                <Input
                  label='Email'
                  required
                  type='email'
                  placeholder='ejemplo@mail.com'
                  name='resetEmail'
                  color={theme.primary}
                />
              </div>
              <div
                style={{ marginTop: '25px' }}
              >
                <Button
                  type='submit'
                  color={theme.primary}
                >
                Enviar
                </Button>
                <Button
                  style={{ marginLeft: 10 }}
                  color='rgba(0, 0, 0, 0.3)'
                  onClick={this.goBack}
                >
                Cancelar
                </Button>
              </div>
            </form>
          ) : (
            <Box fill>
              <Text>Se te ha enviado un email sobre el cambio de tu contraseña(revisa tu bandeja de spam)</Text>
              <Button
                style={{ marginTop: 10 }}
                onClick={this.goBack}
                color={theme.primary}
              >REGRESAR</Button>
            </Box>
          )}
      </Div>
    )
  }
}

export default ResetPassword
