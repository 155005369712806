import React from "react";

const SvgCostoavance = props => (
  <svg viewBox="0 0 283.5 283.5" {...props}>
    <path
      className="costoavance_svg__st0"
      d="M196.1 145c0 6-1.1 11.5-3.4 16.4-2.3 4.9-5.6 9.2-9.8 12.7s-9.4 6.4-15.5 8.5-12.9 3.4-20.5 3.7l-2.5 24.5c-.1.5-.2.9-.4 1.3s-.5.8-1.1 1c-.5.3-1.3.5-2.2.6-.9.2-2.2.2-3.7.2-1.7 0-3.1-.1-4.1-.3s-1.8-.4-2.4-.7c-.6-.3-1-.6-1.2-1.1s-.2-1-.1-1.6l2.6-24.4c-3.6-.5-7-1-10.1-1.8-3.1-.7-5.8-1.5-8.3-2.3-2.4-.8-4.5-1.7-6.2-2.6-1.7-.9-2.9-1.8-3.7-2.5-.8-.8-1.3-1.8-1.6-3.1-.3-1.3-.5-3.1-.5-5.3 0-1.7.1-3 .2-4.1s.4-2 .7-2.7c.3-.7.7-1.2 1.2-1.5s1.1-.4 1.8-.4c1 0 2.4.5 4.2 1.6 1.8 1.1 4.2 2.3 7.2 3.6s6.6 2.5 10.9 3.7c4.3 1.1 9.5 1.7 15.5 1.7 10.7 0 18.8-2.1 24.4-6.3 5.5-4.2 8.3-9.9 8.3-17.2 0-4.4-1-8.2-3.1-11.1-2-3-4.7-5.5-8.1-7.7-3.4-2.1-7.2-4-11.4-5.5-4.3-1.5-8.6-3.1-13-4.7s-8.7-3.4-13-5.4-8-4.6-11.4-7.6c-3.3-3-6-6.7-8-11s-3.1-9.7-3.1-16c0-5 .9-9.8 2.6-14.1 1.7-4.4 4.3-8.2 7.8-11.5s7.8-6 13.1-8c5.3-2.1 11.4-3.3 18.4-3.8l2.3-22.8c.1-.5.2-.9.4-1.3s.5-.8 1.1-1.1c.5-.3 1.2-.5 2.1-.6.9-.1 2.1-.2 3.7-.2 1.7 0 3 .1 4.1.2 1.1.2 1.9.4 2.5.7s.9.7 1.1 1.2c.2.5.2 1 .2 1.6l-2.5 22.6c2.3.2 4.6.6 7.1 1.2 2.4.6 4.7 1.2 6.9 1.9 2.1.7 4.1 1.5 5.8 2.4s2.9 1.6 3.6 2.2c.6.6 1.1 1.2 1.4 1.7.3.5.5 1.1.6 1.8s.3 1.5.4 2.4c.1.9.2 1.9.2 3.1 0 1.4-.1 2.7-.2 3.7-.1 1.1-.3 1.9-.5 2.5s-.5 1.1-.9 1.4c-.4.3-.8.5-1.4.5-.8 0-2.1-.5-4-1.5-1.8-1-4.1-2-6.8-3.2-2.8-1.1-5.9-2.2-9.6-3.2-3.6-1-7.7-1.5-12.1-1.5-5.1 0-9.4.5-12.9 1.5-3.6 1-6.5 2.4-8.7 4.1-2.2 1.8-3.8 3.9-4.9 6.4-1 2.5-1.5 5.2-1.5 8.1 0 4.5 1 8.3 3.1 11.3s4.8 5.6 8.1 7.7c3.4 2.1 7.2 3.9 11.5 5.4s8.7 3.1 13.2 4.7 8.9 3.4 13.2 5.4 8.1 4.4 11.5 7.5c3.4 3 6.1 6.7 8.1 11 2.2 4.3 3.3 9.6 3.3 16zM208.7 269.4H75.2c-12 0-21.8-9.8-21.8-21.8v-1.2c0-12 9.8-21.8 21.8-21.8h133.5c12 0 21.8 9.8 21.8 21.8v1.2c0 12.1-9.8 21.8-21.8 21.8zM75.2 235.8c-5.8 0-10.6 4.8-10.6 10.6v1.2c0 5.8 4.8 10.6 10.6 10.6h133.5c5.8 0 10.6-4.8 10.6-10.6v-1.2c0-5.8-4.8-10.6-10.6-10.6H75.2z"
    />
    <path
      className="costoavance_svg__st0"
      d="M168.6 265.2H59.7c-.9 0-1.7-.8-1.7-1.7v-30.2c0-.9.8-1.7 1.7-1.7h108.8c.9 0 1.7.8 1.7 1.7v30.2c.1 1-.7 1.7-1.6 1.7z"
    />
  </svg>
);

export default SvgCostoavance;
