/* eslint-disable no-useless-escape */
import React, { Component } from 'react'
import { form, from } from 'react-inform'

export const Rules = {
  name: {
    'Requerido': e => !!e
  },
  password: {
    'Contraseña vacia': e => !!e,
    'La contraseña debe ser mayor a 1 caracter': e => e.length >= 1
  },
  password_confirmation: {
    'Contraseña vacia': e => !!e,
    'Las contraseñas no coinciden': (e, val) => val.password === e
  }
}

class FirstUserForm extends Component {
  onSubmit = e => {
    const { onSubmit, form } = this.props

    e.preventDefault()
    if (onSubmit) onSubmit(form.values())
  }

  render = () => {
    const { children, fields, form } = this.props

    const childrenParams = {
      fields,
      isValid: form.isValid()
    }

    return (
      <form onSubmit={this.onSubmit}>
        {children(childrenParams)}
      </form>
    )
  }
}

export default form(from(Rules))(FirstUserForm)
