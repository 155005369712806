import React from "react";

const SvgInsumo = props => (
  <svg viewBox="0 0 141.7 141.7" {...props}>
    <path
      className="insumo_svg__st0"
      d="M127.2 38.1L96.9 7.8c-.6-.6-1.5-1-2.4-1H30.6c-5.6 0-10.1 4.5-10.1 10.1v107.6c0 5.6 4.5 10.1 10.1 10.1H118c5.6 0 10.1-4.5 10.1-10.1v-84c.1-.9-.3-1.8-.9-2.4zm-7.2 2.4H97.9c-1.9 0-3.4-1.5-3.4-3.4V15L120 40.5zm1.4 84.1c0 1.9-1.5 3.4-3.4 3.4H30.6c-1.9 0-3.4-1.5-3.4-3.4V16.9c0-1.9 1.5-3.4 3.4-3.4h57.2V37c0 5.6 4.5 10.1 10.1 10.1h23.5v77.5z"
    />
  </svg>
);

export default SvgInsumo;
