import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { AlertsActions } from './duck'

import Prompt from 'components/Prompt'
import Alert from 'app/alerts/Alert'
import withTheme from 'components/HOC/withTheme'

const state = state => ({
  showing: state.alerts.showing,
  alert: state.alerts.alert
})

const dispatch = {
  hideAlert: AlertsActions.hideAlert,
  navigate: push
}

@withTheme
@connect(state, dispatch)
class AlertOverlay extends Component {
  onHide = () => { this.props.hideAlert() }

  render = () => {
    const { showing, alert, theme } = this.props

    const { title = '', text = '' } = alert || {}

    return (
      <Prompt pose={showing ? 'enter' : 'exit'}>
        <Alert
          theme={theme}
          show={showing}
          title={title}
          text={text}
          onHide={this.onHide}
        />
      </Prompt>
    )
  }
}

export default AlertOverlay
