export const budget_view_initial_state = {
  isMapSuccess: false,
  chargeTypes: null,
  chargeTypesPrueba: null,
  rootBudgetAcc: null,
  suppliesChargeType: {},
  conceptsByAcountCost: [],
  suppliesByConcepts: [],
  suppliesByConceptsSecond: [],
  magicByAccountCost: [],
  // Estados para cuenta de costo
  AccountCostChilds: [],
  AccountCostChildsSecond: [],
  loadingSupplies: false,
  AccountCostChildsGraph: [],
  // Estados para vista tipo de cargo
  ChargeTypeByACLevel1TC: [],
  AcountCostByCTTC: [],
  SuppliesByCTTC: []
}
