import React, { Component } from 'react'
import { AiOutlineDownload, AiOutlineCloudDownload } from 'react-icons/ai'
import { Button } from 'antd'
import withTheme from 'components/HOC/withTheme'

@withTheme
class NeoData extends Component {
  render = () => {
    const { theme } = this.props

    return (
      <div style={{ height: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
        <AiOutlineDownload style={{ width: 300, height: 300, margin: '0 auto', display: 'block' }} />
        <br />
        <h3 style={{ textAlign: 'center' }}>Descarga aquí el extractor de datos para Neodata y Opus</h3>
        <Button type='primary' shape='round' style={{ backgroundColor: theme.primary, marginTop: '25px' }} href={'/la/EMSI_Extractor.rar'}>
          Extractor.rar
        </Button>
      </div>
    )
  }
}

export default NeoData
