import {
  combineCycles
} from 'redux-cycles'
import {
  Types,
  Actions
} from '../../duck/operations'
import {
  NotificationsActions
} from 'app/notifications/duck'
import xs from 'xstream'
import combine from 'xstream/extra/sampleCombine'
import {
  RoutesActions
} from 'app/routes/duck'
import {
  getToken,
  handleHTTPError,
  ifTypeIs
} from 'helpers/cycles'

const {
  REACT_APP_API
} = process.env
const getBudgetById2 = ({
  ACTION,
  STATE,
  HTTP,
  STORAGE
}) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})
  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.GET_BUDGET_ACOUNT_COST))
    .compose(combine(userToken$, org$))
    .map(([{
      params
    }, token, org]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/show-chunked/${params.budget_id}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      method: 'POST',
      category: 'GET_BUDGET_BY_ID_DG',
      send: {
        type: params.typeTable
      },
      responseType: 'json'
    }))

  const getResponse$ = HTTP
    .select('GET_BUDGET_BY_ID_DG')
    .map(handleHTTPError)
    .flatten()
    .map(({
      body
    }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.getBudgetSuccessAcount(body.data))

  const onSuccess$ = ACTION
    .filter(ifTypeIs(Types.CREATION_SUCCESS))

  const sendNotification$ = onSuccess$
    .mapTo(NotificationsActions.requestNotification('Tabla de presupuesto actualizada', 'info'))

  return {
    HTTP: handleRequest$,
    ACTION: xs.merge(getResponse$, sendNotification$)
  }
}
const getSuppliesByCharge = ({
  ACTION,
  STATE,
  HTTP,
  STORAGE
}) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})
  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.GET_SUPPLIES_BY_CHARGE))
    .compose(combine(userToken$, org$))
    .map(([{
      params
    }, token, org]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/budget/${params.budget_id}/charge_type/${params.charge_type_id}/supplies`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      method: 'GET',
      category: 'GETSUPLIESBYCHARGE',
      send: {
        params: params
      },
      responseType: 'json'
    }))

  const getResponse$ = HTTP
    .select('GETSUPLIESBYCHARGE')
    .map(handleHTTPError)
    .flatten()
    .map(({
      body
    }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.setSuppliesByCharge(body))

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}
const getConceptsByAccountCost = ({
  ACTION,
  STATE,
  HTTP,
  STORAGE
}) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})
  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.GET_CONCEPTS_BY_ACCOUNT_COST))
    .compose(combine(userToken$, org$))
    .map(([{
      params
    }, token, org]) => ({
      // url: `${REACT_APP_API}/organization/${org.id || 1}/concepts_relation/project_id/${params.project_id}/budget_id/${params.budget_id}/account_cost_id/${params.account_cost_id}`,
      url: `${REACT_APP_API}/organization/${org.id || 1}/budget/${params.budget_id}/account_cost/${params.account_cost_id}/concepts`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      method: 'POST',
      send: {
        period: params.period
      },
      category: 'GETCONCEPTSBYACCOUNTCOST',
      responseType: 'json'
    }))

  const getResponse$ = HTTP
    .select('GETCONCEPTSBYACCOUNTCOST')
    .map(handleHTTPError)
    .flatten()
    .map(({
      body
    }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.setConceptsByAccountCosts(body))

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

const getConceptsByAccountCostSecond = ({
  ACTION,
  STATE,
  HTTP,
  STORAGE
}) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})
  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.GET_CONCEPTS_BY_ACCOUNT_COST_SECOND))
    .compose(combine(userToken$, org$))
    .map(([{
      params
    }, token, org]) => ({
      // url: `${REACT_APP_API}/organization/${org.id || 1}/concepts_relation/project_id/${params.project_id}/budget_id/${params.budget_id}/account_cost_id/${params.account_cost_id}`,
      url: `${REACT_APP_API}/organization/${org.id || 1}/budget/${params.budget_id}/account_cost/${params.account_cost_id}/concepts`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      method: 'POST',
      send: { period: params.period },
      category: 'GETCONCEPTSBYACCOUNTCOSTSECOND',
      responseType: 'json'
    }))

  const getResponse$ = HTTP
    .select('GETCONCEPTSBYACCOUNTCOSTSECOND')
    .map(handleHTTPError)
    .flatten()
    .map(({
      body
    }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.setConceptsByAccountCostsSecond(body))

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}
const getSuppliesByConcept = ({
  ACTION,
  STATE,
  HTTP,
  STORAGE
}) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})
  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.GET_SUPPLIES_BY_CONCEPT))
    .compose(combine(userToken$, org$))
    .map(([{
      params
    }, token, org]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/concept/${params.concept_id}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      method: 'POST',
      category: 'CAT_GET_SUPPLIES_BY_CONCEPT',
      send: { period: params.period },

      responseType: 'json'
    }))

  const getResponse$ = HTTP
    .select('CAT_GET_SUPPLIES_BY_CONCEPT')
    .map(handleHTTPError)
    .flatten()
    .map(({
      body
    }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.setSuppliesByConcept(body))

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

const getSuppliesByConceptSecond = ({
  ACTION,
  STATE,
  HTTP,
  STORAGE
}) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})
  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.GET_SUPPLIES_BY_CONCEPT_SECOND))
    .compose(combine(userToken$, org$))
    .map(([{
      params
    }, token, org]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/concept/${params.concept_id}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      method: 'POST',
      send: { period: params.period },
      category: 'CAT_GET_SUPPLIES_BY_CONCEPT_SECOND',
      responseType: 'json'
    }))

  const getResponse$ = HTTP
    .select('CAT_GET_SUPPLIES_BY_CONCEPT_SECOND')
    .map(handleHTTPError)
    .flatten()
    .map(({
      body
    }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.setSuppliesByConceptSecond(body))

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}
const getMagicByAccountCost = ({
  ACTION,
  STATE,
  HTTP,
  STORAGE
}) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})
  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.GET_MAGIC_BY_ACCOUNT_COST))
    .compose(combine(userToken$, org$))
    .map(([{
      payload
    }, token, org]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/show-magic/project_id/${payload.project_id}/budget_id/${payload.budget_id}/account_cost_id/${payload.account_cost_id}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      method: 'POST',
      send: payload,
      category: 'CAT_GET_MAGIC_BY_ACCOUNT_COST',
      responseType: 'json'
    }))

  const getResponse$ = HTTP
    .select('CAT_GET_MAGIC_BY_ACCOUNT_COST')
    .map(handleHTTPError)
    .flatten()
    .map(({
      body
    }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.setMagicByAccountCosts(body))

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}
const getMagicByAccountCostGraph = ({
  ACTION,
  STATE,
  HTTP,
  STORAGE
}) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})
  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.GET_MAGIC_BY_ACCOUNT_COST_GRAPH))
    .compose(combine(userToken$, org$))
    .map(([{
      payload
    }, token, org]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/show-magic/project_id/${payload.project_id}/budget_id/${payload.budget_id}/account_cost_id/${payload.account_cost_id}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      method: 'POST',
      send: payload,
      category: 'CAT_GET_MAGIC_BY_ACCOUNT_COST',
      responseType: 'json'
    }))

  const getResponse$ = HTTP
    .select('CAT_GET_MAGIC_BY_ACCOUNT_COST_GRAPH')
    .map(handleHTTPError)
    .flatten()
    .map(({
      body
    }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.setMagicByAccountCostsGraph(body))

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

const getMagicByAccountCostSecond = ({
  ACTION,
  STATE,
  HTTP,
  STORAGE
}) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})
  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.GET_MAGIC_BY_ACCOUNT_COST_SECOND))
    .compose(combine(userToken$, org$))
    .map(([{
      payload
    }, token, org]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/show-magic/project_id/${payload.project_id}/budget_id/${payload.budget_id}/account_cost_id/${payload.account_cost_id}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      method: 'POST',
      send: payload,
      category: 'CAT_GET_MAGIC_BY_ACCOUNT_COST_SECOND',
      responseType: 'json'
    }))

  const getResponse$ = HTTP
    .select('CAT_GET_MAGIC_BY_ACCOUNT_COST_SECOND')
    .map(handleHTTPError)
    .flatten()
    .map(({
      body
    }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.setMagicByAccountCostsSecond(body))

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

// Cicles by budget view Charge Type
const getChargeTypeByAccountCostCT = ({
  ACTION,
  STATE,
  HTTP,
  STORAGE
}) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})
  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.GET_CHARGE_TYPE_BY_ACCOUNT_COST_CT))
    .compose(combine(userToken$, org$))
    .map(([{ params }, token, org]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/charge_type_account_cost/project_id/${params.project_id}/budget_id/${params.budget_id}/account_cost_id/${params.account_cost_id}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      method: 'POST',
      send: params,
      category: 'CAT_GET_CHARGE_TYPE_BY_ACCOUNT_COST_CT)',
      responseType: 'json'
    }))

  const getResponse$ = HTTP
    .select('CAT_GET_CHARGE_TYPE_BY_ACCOUNT_COST_CT)')
    .map(handleHTTPError)
    .flatten()
    .map(({
      body
    }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.setChargeTypeByAccountCostCT(body))

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

const getChargeTypeByAccountCostCTGraph = ({
  ACTION,
  STATE,
  HTTP,
  STORAGE
}) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})
  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.GET_CHARGE_TYPE_BY_ACCOUNT_COST_GRAPH_CT))
    .compose(combine(userToken$, org$))
    .map(([{ params }, token, org]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/charge_type_account_cost_all/project_id/${params.project_id}/budget_id/${params.budget_id}/`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      method: 'POST',
      send: params,
      category: 'CAT_GET_CHARGE_TYPE_BY_ACCOUNT_COST_CT_GRAPH',
      responseType: 'json'
    }))

  const getResponse$ = HTTP
    .select('CAT_GET_CHARGE_TYPE_BY_ACCOUNT_COST_CT_GRAPH')
    .map(handleHTTPError)
    .flatten()
    .map(({
      body
    }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.setChargeTypeByAccountCostGraphCT(body))

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}
// Cicles by budget view Charge Type
const getChargeTypeByAccountCostCTSecond = ({
  ACTION,
  STATE,
  HTTP,
  STORAGE
}) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})
  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.GET_CHARGE_TYPE_BY_ACCOUNT_COST_CT_SECOND))
    .compose(combine(userToken$, org$))
    .map(([{ params }, token, org]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/charge_type_account_cost/project_id/${params.project_id}/budget_id/${params.budget_id}/account_cost_id/${params.account_cost_id}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      method: 'POST',
      send: params,
      category: 'CAT_GET_CHARGE_TYPE_BY_ACCOUNT_COST_CT_SECOND',
      responseType: 'json'
    }))

  const getResponse$ = HTTP
    .select('CAT_GET_CHARGE_TYPE_BY_ACCOUNT_COST_CT_SECOND')
    .map(handleHTTPError)
    .flatten()
    .map(({
      body
    }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.setChargeTypeByAccountCostCTSecond(body))

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}
const getAccountCostByChargeTypeCT = ({
  ACTION,
  STATE,
  HTTP,
  STORAGE
}) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})
  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.GET_ACCOUNT_COST_BY_CHARGE_TYPE_CT))
    .compose(combine(userToken$, org$))
    .map(([{
      params
    }, token, org]) => ({
      // url: `${REACT_APP_API}/organization/${org.id || 1}/group_supplies_ac_ct/project_id/${params.project_id}/budget_id/${params.budget_id}/account_cost_id/${params.account_cost_id}/charge_type_id/${params.charge_type_id}`,
      url: `${REACT_APP_API}/organization/${org.id || 1}/group_charge_type/project_id/${params.project_id}/budget_id/${params.budget_id}/account_cost_id/${params.account_cost_id}/charge_type_id/${params.charge_type_id}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      method: 'POST',
      send: params,
      category: 'CAT_GET_ACCOUNT_COST_BY_CHARGE_TYPE_CT)',
      responseType: 'json'
    }))

  const getResponse$ = HTTP
    .select('CAT_GET_ACCOUNT_COST_BY_CHARGE_TYPE_CT)')
    .map(handleHTTPError)
    .flatten()
    .map(({
      body
    }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.setAccountCostByChargeTypeCT(body))

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

const getAccountCostByChargeTypeCTSecond = ({
  ACTION,
  STATE,
  HTTP,
  STORAGE
}) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})
  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.GET_ACCOUNT_COST_BY_CHARGE_TYPE_CT_SECOND))
    .compose(combine(userToken$, org$))
    .map(([{
      params
    }, token, org]) => ({
      // url: `${REACT_APP_API}/organization/${org.id || 1}/group_supplies_ac_ct/project_id/${params.project_id}/budget_id/${params.budget_id}/account_cost_id/${params.account_cost_id}/charge_type_id/${params.charge_type_id}`,
      url: `${REACT_APP_API}/organization/${org.id || 1}/group_charge_type/project_id/${params.project_id}/budget_id/${params.budget_id}/account_cost_id/${params.account_cost_id}/charge_type_id/${params.charge_type_id}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      method: 'POST',
      send: params,
      category: 'CAT_GET_ACCOUNT_COST_BY_CHARGE_TYPE_CT_SECOND',
      responseType: 'json'
    }))

  const getResponse$ = HTTP
    .select('CAT_GET_ACCOUNT_COST_BY_CHARGE_TYPE_CT_SECOND')
    .map(handleHTTPError)
    .flatten()
    .map(({
      body
    }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.setAccountCostByChargeTypeCTSecond(body))

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}
const getSuppliesByChargeTypeCT = ({
  ACTION,
  STATE,
  HTTP,
  STORAGE
}) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})
  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.GET_SUPPLIES_BY_CHARGE_TYPE_CT))
    .compose(combine(userToken$, org$))
    .map(([{
      params
    }, token, org]) => ({ // group_supplies_ac_ct/project_id/43/budget_id/96/account_cost_id/160/charge_type_id/330
      url: `${REACT_APP_API}/organization/${org.id || 1}/group_supplies_ac_ct/project_id/${params.project_id}/budget_id/${params.budget_id}/account_cost_id/${params.account_cost_id}/charge_type_id/${params.charge_type_id}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      method: 'GET',
      category: 'CAT_GET_SUPPLIES_BY_CHARGE_TYPE_CT)',
      responseType: 'json'
    }))

  const getResponse$ = HTTP
    .select('CAT_GET_SUPPLIES_BY_CHARGE_TYPE_CT)')
    .map(handleHTTPError)
    .flatten()
    .map(({
      body
    }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.setSuppliesByChargeTypeCT(body))

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

const getAccountCostChildAC = ({
  ACTION,
  STATE,
  HTTP,
  STORAGE
}) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})

  const handleRequest$ = ACTION // getAccountCostChildAC
    .filter(ifTypeIs(Types.GET_ACCOUNT_COST_CHILD_AC))
    .compose(combine(userToken$, org$))
    .map(([{
      params
    }, token, org]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/account-costs-index-alt/${params.project_id}/${params.budget_id}/${params.account_cost_id}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      responseType: 'json',
      method: 'POST',
      send: params,
      category: 'CAT_GET_ACCOUNT_COST_CHILD_AC'
    }))

  const getResponse$ = HTTP
    .select('CAT_GET_ACCOUNT_COST_CHILD_AC')
    .map(handleHTTPError)
    .flatten()
    .map(({
      body
    }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.setAccountCostChildAC(body))

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

const getAccountCostChildACGraph = ({
  ACTION,
  STATE,
  HTTP,
  STORAGE
}) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})

  const handleRequest$ = ACTION // getAccountCostChildAC
    .filter(ifTypeIs(Types.GET_ACCOUNT_COST_CHILD_AC_GRAPH))
    .compose(combine(userToken$, org$))
    .map(([{
      params
    }, token, org]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/account-costs-index-alt/${params.project_id}/${params.budget_id}/${params.account_cost_id}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      responseType: 'json',
      method: 'POST',
      send: params,
      category: 'CAT_GET_ACCOUNT_COST_CHILD_AC_GRAPH'
    }))

  const getResponse$ = HTTP
    .select('CAT_GET_ACCOUNT_COST_CHILD_AC_GRAPH')
    .map(handleHTTPError)
    .flatten()
    .map(({
      body, request
    }) => {
      if (body.error) {
        return RoutesActions.handleError(body.error)
      } else {
        body.auxIndex = request.send.index
        return Actions.setAccountCostChildACGraph(body)
      }
    })

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}
const getAccountCostChildACSecond = ({
  ACTION,
  STATE,
  HTTP,
  STORAGE
}) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})

  const handleRequest$ = ACTION // getAccountCostChildAC
    .filter(ifTypeIs(Types.GET_ACCOUNT_COST_CHILD_AC_SECOND))
    .compose(combine(userToken$, org$))
    .map(([{
      params
    }, token, org]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/account-costs-index-alt/${params.project_id}/${params.budget_id}/${params.account_cost_id}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      responseType: 'json',
      method: 'POST',
      send: params,
      category: 'CAT_GET_ACCOUNT_COST_CHILD_AC_SECOND'
    }))

  const getResponse$ = HTTP
    .select('CAT_GET_ACCOUNT_COST_CHILD_AC_SECOND')
    .map(handleHTTPError)
    .flatten()
    .map(({
      body
    }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.setAccountCostChildACSecond(body))

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}
export default combineCycles(
  getBudgetById2,
  getSuppliesByCharge,
  getConceptsByAccountCost,
  getConceptsByAccountCostSecond,
  getSuppliesByConcept,
  getSuppliesByConceptSecond,
  getMagicByAccountCost,
  getMagicByAccountCostGraph,
  getMagicByAccountCostSecond,
  // Cicles account cost
  getAccountCostChildAC,
  getAccountCostChildACGraph,
  getAccountCostChildACSecond,

  // Cicles budget view charge type
  getChargeTypeByAccountCostCT,
  getChargeTypeByAccountCostCTGraph,
  getChargeTypeByAccountCostCTSecond,
  getAccountCostByChargeTypeCT,
  getAccountCostByChargeTypeCTSecond,
  getSuppliesByChargeTypeCT
)
