import React, { Component } from 'react'
import { Box, Text } from 'grommet'
import posed from 'react-pose'

const Layer = posed.div({
  enter: {
    y: 0,
    opacity: 1,
    delay: 300,
    transition: {
      y: { type: 'spring', stiffness: 1000, damping: 15 },
      default: { duration: 300 }
    }
  },
  exit: {
    y: 50,
    opacity: 0,
    height: 0,
    margin: 0,
    transition: { duration: 150 }
  }
})

class Notification extends Component {
  render = () => {
    const {
      error = false,
      info = false,
      message,
      icon: Icon,
      pose = 'exit',
      onClick = () => {}
    } = this.props

    if (error && info) console.error(`You're using both, error and info in notification component.`)

    return message &&
      (
        <Layer pose={pose}>
          <Box
            onClick={onClick}
            elevation='medium'
            direction='row'
            round
            align='center'
            alignContent='center'
            width='medium'
            fill={false}
            margin={{ vertical: pose === 'exit' ? 'none' : 'small' }}
            pad={{ vertical: 'small', horizontal: 'small' }}
            background={info ? '#fff' : error ? 'status-critical' : '#fff'}
            style={{ cursor: 'pointer' }}
          >
            {Icon && (
              <Box margin={{ horizontal: 'xsmall' }}>
                <Icon
                  size='medium'
                  color={!error ? '#000' : '#fff'}
                />
              </Box>
            )}
            <Box margin={{ horizontal: 'small' }}>
              <Text
                margin={Icon && { left: 'small' }}
                color={!error ? '#000' : '#fff'}
              >
                {message}
              </Text>
            </Box>
          </Box>
        </Layer>
      )
  }
}

export default Notification
