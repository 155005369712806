import React from 'react'
import { Layer, Box, Paragraph } from 'grommet'
import { Typography } from 'antd'
import ButtonCore from 'components/Controlled/Button'

const { Title } = Typography

export default React.memo(({
  show,
  title,
  text,
  onHide,
  theme
}) => show && (
  <Layer
    onClickOutside={onHide}
    onEsc={onHide}
    modal
    full
  >
    <Box
      align='center'
      justify='center'
      fill
    >
      <Box
        width='medium'
        pad='medium'
        background='white'
        border={{ side: 'all', color: theme.primary, size: 'xsmall' }}
        round='small'
      >
        <Title
          level={3}
          style={{ color: theme.primary, margin: '10px' }}
        >
          {title}
        </Title>
        <Paragraph margin='none'>{text}</Paragraph>
        <Box
          align='end'
          fill='horizontal'
          margin={{ bottom: 'none', top: 'small' }}
        >
          <ButtonCore
            type='submit'
            color={theme.primary}
            onClick={onHide}
          >Aceptar
          </ButtonCore>
        </Box>
      </Box>
    </Box>
  </Layer>
))
