import { combineCycles } from 'redux-cycles'
import { Types, Actions } from './operations'
import { NotificationsActions } from 'app/notifications/duck'
import xs from 'xstream'
import dropUntil from 'xstream/extra/dropUntil'
import combine from 'xstream/extra/sampleCombine'
import delay from 'xstream/extra/delay'
import { push } from 'connected-react-router'
import { handleHTTPError, ifTypeIs, getToken } from 'helpers/cycles'
import { RoutesActions } from 'app/routes/duck'

const { REACT_APP_API } = process.env

const handleLogin = ({ ACTION, STORAGE, HTTP, STATE }) => {
  const isLogged$ = STATE.map(state => state.auth.logged)

  const handleLoginRequest$ = ACTION
    .filter(ifTypeIs(Types.REQUEST_LOGIN))
    .compose(combine(isLogged$))
    .filter(([, logged]) => !logged)
    .map(([{ email, password, remember }]) => ({
      url: `${REACT_APP_API}/login`,
      method: 'POST',
      category: 'LOGIN_REQUEST',
      send: {
        email,
        password,
        remember_me: true
      }
    }))

  const requestLoginResult$ = HTTP
    .select('LOGIN_REQUEST')
    .map(handleHTTPError)
    .flatten()
    .map((x) => {
      console.log(x)
      const { access_token: accessToken = '', error, first, reset } = x.body

      return !error
        ? (reset || first) ? Actions.firstTimeLogin(accessToken, reset, first) : Actions.requestSuccess(accessToken)
        : Actions.loginError(error)
    })

  return {
    HTTP: handleLoginRequest$,
    ACTION: requestLoginResult$
  }
}

const onError = ({ ACTION }) => {
  const handleLoginError$ = ACTION
    .filter(ifTypeIs(Types.LOGIN_ERROR))
    .map(({ error }) => NotificationsActions.requestNotification(error, 'error'))

  return {
    ACTION: handleLoginError$
  }
}

const setupAuthentication = ({ ACTION, STORAGE }) => {
  const handleSuccess$ = ACTION
    .filter(ifTypeIs(Types.REQUEST_SUCCESS))

  const setTokenKey$ = handleSuccess$
    .map(({ token }) => ({ key: 'token', value: token }))

  const setLoginKey$ = handleSuccess$
    .map(({ token }) => ({ key: 'is_logged', value: !!token }))

  const setSessionKeys$ = xs.merge(setLoginKey$, setTokenKey$)

  const continueAuth$ = STORAGE.local
    .getItem('token')
    .filter(x => !!x)
    .map(Actions.getLocalToken)

  const getToken$ = ACTION
    .filter(ifTypeIs(Types.GET_LOCAL_TOKEN))
    .filter(({ token }) => token)
    .map(({ token }) => Actions.loginSuccess(token))

  return {
    STORAGE: setSessionKeys$,
    ACTION: xs.merge(continueAuth$, getToken$)
  }
}

const checkAuth = ({ STORAGE }) => {
  const getStatus$ = STORAGE.local
    .getItem('is_logged')
    .map(isLogged => Actions[!!isLogged ? 'userLogged' : 'userNotLogged']())

  return {
    ACTION: getStatus$
  }
}

const getUserToken = ({ ACTION, STORAGE }) => {
  const getTokenOnLogged$ = ACTION
    .filter(ifTypeIs(Types.SET_AUTH_STATUS))
    .mapTo(STORAGE.local.getItem('token'))
    .flatten()
    .filter(result => !!result)
    .map(Actions.getLocalToken)

  return {
    ACTION: getTokenOnLogged$
  }
}

const redirectOnLogin = ({ ACTION, STATE }) => {
  const user$ = STATE
    .map(({ auth }) => auth.user || {})

  const redirectOnLogin$ = ACTION
    .filter(ifTypeIs(Types.LOGIN_SUCCESS))
    .compose(combine(user$))
    .map(([, user]) => push('/select'))

  return {
    ACTION: redirectOnLogin$
  }
}

const fetchUserData = ({ ACTION, HTTP, STATE, STORAGE }) => {
  const token$ = getToken(STORAGE)

  const handleLogin$ = ACTION
    .filter(ifTypeIs(Types.REQUEST_SUCCESS))

  const localToken$ = ACTION
    .filter(ifTypeIs(Types.GET_LOCAL_TOKEN))

  const fetchUser$ = ACTION
    .filter(ifTypeIs(Types.FETCH_USER))
    .compose(combine(token$))
    .map(([, token]) => ({ token }))

  const requestUser$ = xs.merge(handleLogin$, localToken$, fetchUser$)
    .map(({ token }) => ({
      url: `${REACT_APP_API}/user`,
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'json',
      category: 'GET_USER_DATA'
    }))

  const fetchData$ = HTTP
    .select('GET_USER_DATA')
    .map(handleHTTPError)
    .flatten()

  const setUser$ = fetchData$
    .map(({ body }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.updateUserData(body.user)
    )

  const setUserOrgs$ = fetchData$
    .map(({ body }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.setUserOrganizations(body.organizations)
    )

  const setTemplates$ = fetchData$
    .map(({ body }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.setTemplate(body.template)
    )

  return {
    HTTP: requestUser$,
    ACTION: xs.merge(setUserOrgs$, setUser$, setTemplates$)
  }
}

const persistUserData = ({ ACTION, STORAGE }) => {
  const handleUpdate$ = ACTION
    .filter(ifTypeIs(Types.UPDATE_USER_DATA))
    .map(({ payload }) => ({
      key: 'user_data',
      value: JSON.stringify(payload)
    }))

  const getPersistedUserData$ = STORAGE.local
    .getItem('user_data')
    .filter(data => !!data)
    .map(JSON.parse)
    .map(Actions.updateUserData)
    .endWhen(ACTION.filter(ifTypeIs(Types.UPDATE_USER_DATA)))

  return {
    STORAGE: handleUpdate$,
    ACTION: getPersistedUserData$
  }
}

const logoutUser = ({ ACTION }) => {
  const handleAction$ = ACTION
    .filter(ifTypeIs(Types.REQUEST_LOGOUT))

  const removeToken$ = handleAction$
    .mapTo({
      target: 'local',
      key: 'token',
      action: 'removeItem'
    })

  const removeUserData$ = handleAction$
    .mapTo({
      target: 'local',
      key: 'user_data',
      action: 'removeItem'
    })

  const removeLoggedStatus$ = handleAction$
    .mapTo({
      target: 'local',
      key: 'is_logged',
      action: 'removeItem'
    })

  const deleteSession$ = ACTION
    .filter(ifTypeIs(Types.REQUEST_LOGOUT))
    .compose(delay(1000))
    .compose(dropUntil(handleAction$))
    .mapTo(Actions.logoutSuccess())

  return {
    ACTION: xs.merge(deleteSession$),
    STORAGE: xs.merge(removeLoggedStatus$, removeToken$, removeUserData$)
  }
}

const logoutOnTokenExpired = ({ ACTION }) => {
  const handleAction$ = ACTION
    .filter(ifTypeIs(Types.TOKEN_EXPIRED))
    .mapTo(Actions.requestLogout())

  return {
    ACTION: handleAction$
  }
}

const selectOrganization = ({ ACTION, STATE, STORAGE }) => {
  const orgs$ = STATE.map(({ auth }) => auth.userOrganizations || [])
  const logged$ = STATE.map(({ auth }) => auth.token)
  const selected$ = STATE.map(({ auth }) => auth.orgSelected)

  const handleSelection$ = ACTION
    .filter(ifTypeIs(Types.SELECT_ORGANIZATION))
    .compose(combine(orgs$))
    .map(([{ id }, orgs]) => (orgs.filter(({ id: orgId }) => orgId === id))[0])

  const setOrganization$ = handleSelection$
    .compose(combine(selected$))
    .map(([ result, selected ]) => !selected || (result.id !== selected.id)
      ? Actions.organizationConfigured(result)
      : push('/'))

  const getOrganization$ = STORAGE.local
    .getItem('organization_selected')
    .filter(item => item)
    .map(result => Actions.organizationConfigured(JSON.parse(result)))

  const setLocalOrganization$ = handleSelection$
    .map(org => ({
      key: 'organization_selected',
      value: JSON.stringify(org)
    }))

  const redirectOnSet$ = ACTION
    .filter(ifTypeIs(Types.ORGANIZATION_CONFIGURED))
    .compose(combine(logged$))
    .filter(([ , logged ]) => !!logged)
    .mapTo(push('/'))

  return {
    STORAGE: setLocalOrganization$,
    ACTION: xs.merge(getOrganization$, setOrganization$, redirectOnSet$)
  }
}

const firstLogin = ({ ACTION, STATE, HTTP, STORAGE }) => {
  const token$ = getToken(STORAGE)
  const token2$ = STATE.map(state => state.auth.token)

  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.REQUEST_CREATION))
    .compose(combine(token$, token2$))
    .map(([{ payload: { name, ...payload } }, token, secondOptionToken]) => ({
      url: `${REACT_APP_API}/first-login`,
      method: 'POST',
      headers: { Authorization: `Bearer ${token || secondOptionToken}` },
      send: {
        ...(name ? { name: name.toUpperCase() } : {}),
        ...payload
      },
      responseType: 'json',
      category: 'FIRST_LOGIN'
    }))

  const getResponse$ = HTTP
    .select('FIRST_LOGIN')
    .map(handleHTTPError)
    .flatten()
    .compose(combine(token$, token2$))
    .map(([{ body }, token, secondOptionToken]) => body.error
      ? Actions.loginError(body.error)
      : Actions.requestSuccess(token || secondOptionToken))

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

const resetPass = ({ ACTION, STATE, HTTP, STORAGE }) => {
  const token$ = getToken(STORAGE)

  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.SEND_PASSWORD_RESET))
    .compose(combine(token$))
    .map(([{ email }, token]) => ({
      url: `${REACT_APP_API}/reset-password`,
      method: 'POST',
      headers: { Authorization: `Bearer ${token}` },
      send: { email },
      responseType: 'json',
      category: 'RESET_PASSWORD'
    }))

  const handleResponse$ = HTTP
    .select('RESET_PASSWORD')
    .map(handleHTTPError)
    .flatten()
    .map(({ body }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.sendPasswordResetSuccess())

  return {
    HTTP: handleRequest$,
    ACTION: handleResponse$
  }
}

const deleteOrgOnLogin = ({ ACTION }) => {
  const handleSuccess$ = ACTION
    .filter(ifTypeIs(Types.REQUEST_SUCCESS))

  const deleteOrg$ = handleSuccess$
    .mapTo({
      key: 'organization_selected',
      action: 'removeItem',
      target: 'local'
    })

  const noOrg$ = handleSuccess$
    .mapTo(Actions.unsetOrg())

  return {
    STORAGE: deleteOrg$,
    ACTION: noOrg$
  }
}

const handleFirstLogin = ({ ACTION }) => {
  const handleSuccess$ = ACTION
    .filter(ifTypeIs(Types.FIRST_TIME_LOGIN))

  const handleFirst$ = handleSuccess$
    .mapTo(push('/login/first'))

  return {
    ACTION: handleFirst$
  }
}

export default combineCycles(
  getUserToken,
  checkAuth,
  handleLogin,
  onError,
  fetchUserData,
  setupAuthentication,
  //redirectOnLogin,
  logoutUser,
  logoutOnTokenExpired,
  persistUserData,
  selectOrganization,
  firstLogin,
  resetPass,
  deleteOrgOnLogin,
  handleFirstLogin
)
