import { createActions } from 'reduxsauce'
import { unit_factors_action } from '../UnitFactors/Redux/actionsUnitFactors'
import { budget_view_action } from '../BudgetView/Redux/actionsBudwetView'

const { Creators, Types } = createActions({
  ...unit_factors_action,
  ...budget_view_action,

  fetchProjects: null,

  updateProjects: ['payload'],
  error: ['error'],
  requestCreation: ['payload', 'users', 'id'],
  creationSuccess: null,
  fetchProject: ['id'],
  projectFetched: ['payload'],
  cleanView: null,
  deleteProject: ['id'],
  deleteBudget: ['id'],
  getBudgetById: ['id', 'search', 'searchType', 'compareBudgets', 'compareBudgetsAdvance', 'type'],
  getBudgetSecondById: ['id', 'search', 'searchType', 'compareBudgets', 'compareBudgetsAdvance', 'type'],
  getChargeTypeExcel: ['params'],
  getChargeTypeExcelCompare: ['params'],
  getChargeTypeExcelAdvance: ['params'],
  getBudgetBaseExcel: ['projectId', 'budgetId', 'periodId', 'colors'],
  getBudgetBaseExcelCompare: ['projectId', 'budgetId', 'periodId', 'colors', 'secondBudgetId'],
  getBudgetBaseExcelCompareAdvance: ['projectId', 'budgetId', 'periodId', 'colors', 'data'],
  getCostExcel: ['projectId', 'budgetId', 'periodId', 'colors'],
  getCostExcelCompare: ['projectId', 'budgetId', 'periodIdF', 'periodIdS', 'colors'],
  getCostExcelCompareAdvance: ['projectId', 'budgetId', 'periodIdF', 'periodIdS', 'colors'],
  getCostExcelCompareType: ['projectId', 'budgetId', 'periodIdF', 'periodIdS', 'colors'],
  getCostExcelCompareUnitCost: ['projectId', 'budgetId', 'periodIdF', 'periodIdS', 'colors'],
  getBudgetProgressById: ['id', 'search', 'searchType', 'type'],
  getBudgetProgressByPeriod: ['id', 'period', 'search', 'searchType', 'type'],
  getBudgetSecondProgressByPeriod: ['id', 'period', 'search', 'searchType', 'type'],
  getExcelBudget: ['id', 'period', 'colors'],
  getExcelSuccess: null,
  getBudgetAcountCost: ['params'],
  getBudgetSuccessAcount: ['payload'],
  getBudgetSuccess: ['payload'],
  getBudgetSecondSuccess: ['payload'],
  getHistorySuccess: ['payload'],
  getAccountCosts: ['id'],
  getGeneralHistory: ['id'],
  getAccountCostsSuccess: ['payload'],

  addAccount: ['parent', 'name', 'pId'],
  editAccount: ['id', 'name'],
  editAccountExt: ['payload'],
  accountSuccess: null,
  updateAccount: ['payload'],
  deleteAccount: ['deleteId'],

  getBudgetChild: ['id', 'requestType', 'search', 'searchType'],
  getBudgetChildSecond: ['id', 'requestType', 'search', 'searchType', 'budgetId'],
  getBudgetProgressChild: ['id', 'requestType', 'period', 'search', 'searchType'],
  getBudgetSecondProgressChild: ['id', 'requestType', 'period', 'search', 'searchType', 'budgetId'],
  getBudgetChildSuccess: ['payload'],
  getBudgetChildSecondSuccess: ['payload'],
  getBudgetChildProgressSuccess: ['payload'],

  searchSomething: ['id', 'search', 'searchType'],
  clearSearch: null,

  mapAccountCost: ['payload', 'budget'],
  mapAccountCostSuccess: null,
  getPeriodsOfBudget: ['budgetId'],
  updateProgressExcel: ['params'],
  updateProgressExcelSuccess: ['params'],
  updateProgressRealExcel: ['params'],
  updateProgressRealExcelSuccess: ['params'],
  getPeriodsOfBudgetSuccess: ['periods'],
  togglePeriod: ['periodId', 'budgetId'],
  updatePeriod: ['endDate', 'budgetId', 'periodId'],
  getExcelReal: ['projectId', 'budgetId', 'periodId', 'colors'],
  getExcelExt: ['projectId', 'colors'],
  createPeriod: ['projectId', 'budgetId'],
  createPeriodSuccess: ['periods'],
  deletePeriod: ['periodId', 'budgetId'],
  getPeriod: ['budgetId', 'periodId'],
  getPeriodSuccess: ['data'],

  updateProgress: ['periodId', 'conceptId', 'data'],
  updateProgressDone: null,
  updateProgressReal: ['periodId', 'supplyCode', 'data'],
  setProgress: ['conceptId', 'parentIds', 'data'],
  closeProgress: ['budgetId', 'periodId'],
  openProgress: ['budgetId', 'periodId'],
  closeProgressReal: ['budgetId', 'periodId'],
  openProgressReal: ['budgetId', 'periodId'],
  closeProgressSuccess: ['budgetId', 'periodId'],
  closeProgressRealSuccess: ['budgetId', 'periodId'],
  updateProgressSuccess: null,
  updateProgressRealSuccess: null
}, {
  prefix: 'projects/'
})
export { Creators as Actions, Types }
