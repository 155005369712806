import React, { Component } from 'react'
import { Box, Heading, Text, Table, TableBody, TableHeader, TableRow, TableCell, DropButton, Button as GButton } from 'grommet'
import { Add, Close, Edit, Trash, Drag } from 'grommet-icons'
import { connect } from 'react-redux'
import { Table as DataTable, UncontrolledTooltip } from 'reactstrap'
import moment from 'moment'
import { push } from 'connected-react-router'
import DayPickerInput from 'components/Controlled/InputDate'

import OverlayLoading from 'components/OverlayLoading'
import { ProjectsActions } from '../duck'

import withTheme from 'components/HOC/withTheme'

import Button from 'components/Button'
import Divider from 'components/Divider'
import SvgEstructurascuentas from '../../../icons/Estructurascuentas'
import SvgMapeoexterno from '../../../icons/Mapeoexterno'
import SvgPresupuestos from '../../../icons/Presupuestos'
import SvgGuardar from '../../../icons/Guardar'
import InputField from 'components/Controlled/Input'
import hexToRgba from 'hex-to-rgba'
import { formToArray } from '../../../helpers'
import Permission from '../../../components/Permission'
import TypeChargeComponent from './Components/type_charge'
import { AiOutlineFileAdd } from 'react-icons/ai/index'
import { Button as ButtonG } from 'grommet/components/Button'
import { Typography } from 'antd'
import Modal from '../../../components/Modal'
const { Title } = Typography

const INITIAL_STATE = {
  creating: false,
  editing: false,
  project: null,
  selections: {},
  form: { limit_progress: true, limit_progress_visual: true },
  width50: false,
  editingTypeChange: false,
  deleteId: null
}

const days = Array.from({ length: 31 }, (v, k) => k + 1)

const wDays = Array.from({ length: 7 }, (v, k) => k + 1)

// recibe en el estado los reucers del store, se convierte en una propiedad del componente
const state = state => ({
  fetchingProjects: state.projects.fetchingProjects,
  projects: state.projects.projects,
  chargeTypes: state.projects.projects,
  auth: state.auth.orgSelected
})

const dispatch = {
  getProjects: ProjectsActions.fetchProjects,
  getProject: ProjectsActions.fetchProject,
  create: ProjectsActions.requestCreation,
  navigate: push,
  deleteProject: ProjectsActions.deleteProject,

  getChargeTypesLocal: ProjectsActions.getChargeTypesOperation,
  getAccountCosts: ProjectsActions.getAccountCosts,
  setProyectSelected: ProjectsActions.setProjectSelectedOperation
}

@withTheme
@connect(state, dispatch)
class Projects extends Component {
  state = INITIAL_STATE

  delete = id => () => {
    console.log('delete')
    this.props.deleteProject(id)
  }

  deleteAux = id => {
    console.log('deleteAux')
    this.props.deleteProject(id)
  }

  clear = () => {
    this.setState(INITIAL_STATE)
  }

  goTo = path => () => {
    this.props.navigate(path)
  }

  componentWillUnmount = () => { this.clear() }

  handleClose = () => {
    this.setState(prevState => ({
      creating: !prevState.showCreate
    }))
  }

  updateField = name => e => {
    const value = e.target.value
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [name]: value
      }
    }))
  }

  updateBoolean = name => e => {
    this.updateField(name)({ target: { value: e.target.checked } })
  }

  updateDate = name => day => {
    const date = moment(day).format('DD/MMM/YYYY')
    if (name === 'initial_date') {
      console.log(day)
      console.log(new Date(day))
      console.log(new Date(date))
      this.setState({
        initial_date: day
      })
    }

    this.updateField(name)({ target: { value: date } })
  }

  close = () => {
    this.clear()
  }

  edit = pId => () => {
    const { projects } = this.props
    this.setState(prevState => {
      const project = (projects || []).filter(({ id }) => id === pId)[0] || {}

      const periodicity = project.periodicity.type === 'monthly'
        ? 'Mensual'
        : project.periodicity.type === 'biweekly'
          ? 'Quincenal'
          : project.periodicity.type === 'weekly'
            ? 'Semanal'
            : 'Mensual'

      return {
        editing: true,
        form: {
          ...project,
          periodicity,
          day1: project.periodicity.days[0],
          initial_date: moment(project.initial_date, 'YYYY-MM-DD').format('DD/MMM/YYYY'),
          close_date: moment(project.close_date, 'YYYY-MM-DD').format('DD/MMM/YYYY'),
          ...(project.periodicity.days[0] ? { day2: project.periodicity.days[0] } : {})
        },
        project,
        periodicity,
        selections: {},
        creating: false
      }
    })
  }

  setTypeChange = (id) => {
    this.props.getChargeTypesLocal(id)
    this.props.getAccountCosts(id)
    this.props.setProyectSelected(id)
    this.setState(prevState => ({
      ...prevState.selections,
      width50: true,
      editingTypeChange: true
    }))
  }
  closeTypeChange = () => {
    this.setState(prevState => ({
      ...prevState.selections,
      width50: false,
      editingTypeChange: false
    }))
  }

  select = (type, id) => e => {
    const checked = e.target.checked

    this.setState(prevState => ({
      selections: {
        ...prevState.selections,
        [id]: checked ? type : null
      }
    }))
  }

  renderProjects = (values) => {
    return values && (
      <Permission>
        {({ role }) => (
          <>
            <Box
              direction='row'
              align='center'
              justify='between'
              margin={{ bottom: 'small', top: 'medium' }}
            >
              <Heading
                margin={{ vertical: 'none' }}
                level={3}
              >
                Usuarios asignados
              </Heading>
            </Box>
            <div style={{ maxHeight: 400, overflowY: 'auto' }}>
              <DataTable style={{ width: '100%' }} caption='Organizaciones'>
                <thead>
                  <tr>
                    <th color='secondary' weight='bold'>Nombre</th>
                    <th color='secondary' weight='bold'>Visor</th>
                    <th color='secondary' weight='bold'>Usuario</th>
                  </tr>
                </thead>
                <tbody>
                  {values.map(({ name, role, id }, i) => (
                    <tr key={i}>
                      <th><Text>{name || 'SIN NOMBRE'}</Text></th>
                      {role !== 'Admin' ? <>
                        <th><InputField style={{ marginLeft: '20px' }} type='checkbox' onChange={this.select('Viewer', id)} checked={this.state.selections[id] ? (this.state.selections[id] === 'Viewer') : (role === 'Viewer')} /></th>
                        <th><InputField style={{ marginLeft: '20px' }} type='checkbox' onChange={this.select('User', id)} checked={this.state.selections[id] ? (this.state.selections[id] === 'User') : (role === 'User')} /></th>
                      </> : <th color='secondary' weight='bold'>Administrador</th>}
                    </tr>
                  ))}
                </tbody>
              </DataTable>
            </div>
          </>
        )}
      </Permission>
    )
  }

  sendCreation = e => {
    e.preventDefault()

    const values = formToArray(e.target)

    const { day1, day2 } = values
    const { form, selections, project } = this.state

    this.props.create({
      ...values,
      periodicity: {
        type: form.periodicity === 'Mensual' ? 'monthly' : form.periodicity === 'Quincenal' ? 'biweekly' : form.periodicity === 'Semanal' ? 'weekly' : 'weekly',
        days: form.periodicity === 'Quincenal' ? [day1 * 1, day2 * 1] : [day1 * 1]
      },
      initial_date: moment(form.initial_date, 'DD/MMMM/YYYY').format('DD-MM-YYYY'),
      close_date: moment(form.close_date, 'DD/MMMM/YYYY').format('DD-MM-YYYY'),
      limit_progress: !!form.limit_progress,
      limit_progress_visual: !!form.limit_progress_visual
    }, selections, (project || {}).id)

    this.clear()
  }

  componentDidMount = () => {
    this.props.getProjects()
    this.props.getChargeTypesLocal(19)
  }

  render = () => {
    const { fetchingProjects, projects, theme } = this.props
    const { creating, editing, project, form, width50, editingTypeChange } = this.state

    return (
      <Box
        direction='row'
        flex
        align='start'
        justify='baseline'
        fill
      >
        <Box
          pad='medium'
          background='white'
          round='xsmall'
          responsive
          fill='vertical'
          style={{ width: editing || creating || width50 ? '50%' : '100%', position: 'relative', overflowY: 'auto' }}
        >
          <OverlayLoading overlayColor={theme.primary} loaderColor={theme.font} pose={fetchingProjects ? 'in' : 'out'} />
          <Box
            direction='row'
            align='center'
            justify='between'
          >
            <Title
              level={3}
            >
              {/* <Heading */}
              {/*  margin={{ vertical: 'xsmall' }} */}
              {/*  level={3} */}
              {/* > */}
              Proyectos creados
              {/* </Heading> */}
            </Title>
          </Box>
          <Divider color='black' />
          {this.props.auth && this.props.auth.is_admin ? <Box
            margin='small'
            flex='false'
          >
            <ButtonG
              disabled={creating || editing}
              // color={theme.primary}
              style={{ width: '30px' }}
              onClick={this.handleClose}
            >
              <AiOutlineFileAdd fill={theme.font} style={{ fontSize: '30px' }} />
              {/* <SvgNuevo fill={theme.font} width={80} /> */}
            </ButtonG>
          </Box> : null}
          <div style={{ overflowY: 'auto', height: '100%' }} >
            {(!projects || projects.length === 0)
              ? (
                <Text>No hay proyectos</Text>
              ) : (
                <Table style={{ width: '100%' }} caption='Organizaciones'>
                  <TableHeader style={{ backgroundColor: theme.primary }}>
                    <TableRow>
                      <TableCell scope='col'>
                        <Text color={theme.font} weight='bold'>Nombre</Text>
                      </TableCell>
                      <TableCell scope='col'>
                        <Text color={theme.font} weight='bold'>Acciones</Text>
                      </TableCell>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {projects.map(({ name, id, ChargeTypes }, i) => (
                      <TableRow key={i} style={{ borderBottom: `1px solid ${hexToRgba(theme.primary, 0.3)}`, backgroundColor: (project && project.id === id) ? 'rgba(0, 0, 0, 0.1)' : null }}>
                        <TableCell scope='row'>
                          <Text weight='normal'>{name}</Text>
                        </TableCell>
                        <TableCell scope='col'>
                          <Box
                            direction='row'
                            justify='end'
                            align='center'
                          >
                            <Box margin={{ horizontal: 'xsmall' }}>
                              <Button
                                icon
                                onClick={() => { this.props.navigate(`/projects/costs/${id}`) }}
                                color={theme.primary}
                                id={`accountStructures-${i}`}
                              >
                                <SvgEstructurascuentas fill={theme.font} width={80} />
                                <UncontrolledTooltip delay={0} placement='bottom' target={`accountStructures-${i}`}>Estructura de cuentas</UncontrolledTooltip>
                              </Button>
                            </Box>
                            <Box margin={{ horizontal: 'xsmall' }}>
                              <Button
                                icon
                                onClick={() => { this.props.navigate(`/projects/map/${id}`) }}
                                color={theme.primary}
                                id={`externalMapping-${i}`}
                              >
                                <SvgMapeoexterno fill={theme.font} width={80} />
                                <UncontrolledTooltip delay={0} placement='bottom' target={`externalMapping-${i}`}>Mapeo externo</UncontrolledTooltip>
                              </Button>
                            </Box>
                            {/* <Box margin={{ horizontal: 'xsmall' }}> */}
                            {/*  <Button */}
                            {/*    icon */}
                            {/*    color={theme.primary} */}
                            {/*    id={`chargeType-${i}`} */}
                            {/*    disabled={editing || creating || width50 || (ChargeTypes.length == 0)} */}

                            {/*    onClick={this.setTypeChange.bind(this, id)} */}
                            {/*  > */}
                            {/*    <SvgTiposdecargo fill={theme.font} width={80} /> */}
                            {/*    <UncontrolledTooltip delay={0} placement='bottom' target={`chargeType-${i}`}>Tipos de cargo</UncontrolledTooltip> */}
                            {/*  </Button> */}
                            {/* </Box> */}
                            <Box margin={{ horizontal: 'xsmall' }}>
                              <Button
                                onClick={() => { this.props.navigate(`/projects/${id}`) }}
                                icon
                                color={theme.primary}
                                id={`budgets-${i}`}
                              >
                                <SvgPresupuestos fill={theme.font} width={80} />
                                <UncontrolledTooltip delay={0} placement='bottom' target={`budgets-${i}`}>Presupuestos</UncontrolledTooltip>
                              </Button>
                            </Box>
                            {this.props.auth.is_admin ? <Box margin={{ horizontal: 'xsmall' }}>
                              <DropButton
                                plain
                                label={<Drag size='medium' />}
                                id={`options-${i}`}
                                dropContent={
                                  <Box
                                    round='small'
                                  >
                                    <Box margin={{ top: 'small', right: 'small', left: 'small' }}>
                                      <GButton
                                        plain
                                        onClick={this.edit(id)}
                                      >
                                        <Text color={theme.primary}>Editar</Text>
                                      </GButton>
                                    </Box>
                                    <Box margin='small'>
                                      <GButton
                                        plain
                                        onClick={(ev) => {
                                          ev.preventDefault()
                                          ev.stopPropagation()
                                          this.setState({ errorModal: true, deleteId: id })
                                          return true
                                        }}
                                      >
                                        <Text color={theme.primary}>Eliminar</Text>
                                      </GButton>
                                    </Box>
                                  </Box>
                                }
                                dropAlign={{ bottom: 'top', top: 'bottom', left: 'left' }}
                              />
                              <UncontrolledTooltip delay={0} placement='bottom'
                                target={`options-${i}`}>Opciones</UncontrolledTooltip>
                            </Box> : null}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
          </div>
        </Box>
        {(editing || creating) && (
          <Box
            pad='medium'
            background='white'
            round='xsmall'
            responsive
            margin={{ horizontal: 'small' }}
            style={{ position: 'relative', width: '50%' }}
          >
            <Close onClick={this.close} color={theme.primary} style={{ position: 'absolute', top: 20, right: 20, cursor: 'pointer' }} />
            <form
              onSubmit={this.sendCreation}
            >
              <Heading
                margin={{ vertical: 'small', bottom: 'none' }}
                color='black'
                level={3}
              >
                {editing ? 'Editar' : 'Crear'}
              </Heading>
              <Divider color='black' />
              <Box
                margin={{ vertical: 'small' }}
              >
                <InputField
                  label='Nombre'
                  value={form.name}
                  onChange={this.updateField('name')}
                  name='name'
                  required
                  color={theme.primary}
                />
              </Box>
              <Box
                margin={{ vertical: 'small' }}
              >
                <DayPickerInput
                  value={form.initial_date}
                  onDayChange={this.updateDate('initial_date')}
                  placeholder='Selecciona una fecha'
                  color={theme.primary}
                  label='Fecha inicial'
                  name='initial_date'
                  required
                  readOnly='read-only'
                />
              </Box>
              <Box
                margin={{ vertical: 'small' }}
              >
                <DayPickerInput
                  label='Fecha de término'
                  value={form.close_date}
                  modifiers={{
                    disabled: {
                      before: new Date(this.state.initial_date),
                      after: null
                    }
                  }}
                  onDayChange={this.updateDate('close_date')}
                  placeholder='Selecciona una fecha'
                  name='close_date'
                  color={theme.primary}
                  required
                />
              </Box>
              <Box
                margin={{ vertical: 'small' }}
              >
                <InputField
                  type='select'
                  label='Periodicidad'
                  value={form.periodicity}
                  onChange={this.updateField('periodicity')}
                  name='periodicity'
                  color={theme.primary}
                  required
                >
                  <option value=''>Selecciona</option>
                  <option>Mensual</option>
                  <option>Quincenal</option>
                  <option>Semanal</option>
                </InputField>
              </Box>
              <Box
                margin={{ vertical: 'small' }}
              >
                <InputField
                  type='select'
                  label='Copiar estructura de cuentas de'
                  value={form.referenceProject}
                  onChange={this.updateField('referenceProject')}
                  name='referenceProject'
                  color={theme.primary}
                >
                  <option value=''>Selecciona</option>
                  {
                    projects.map(({ name, id }, i) => (
                      <option value={id}>{name}</option>
                    ))
                  }
                </InputField>
              </Box>

              <Box
                fill='horizontal'
                direction='row'
                justify='between'
              >
                <Box
                  width='40%'
                  margin={{ right: '1%' }}
                >
                  <InputField
                    type='select'
                    label='Día 1'
                    name='day1'
                    value={form.day1}
                    onChange={this.updateField('day1')}
                    color={theme.primary}
                    required
                  >
                    <option value=''>Selecciona</option>
                    {(form.periodicity === 'Semanal' ? wDays : days).map(day => (
                      <option value={day}>{form.periodicity === 'Semanal' ? moment.weekdays(day).split('').map((v, i) => i === 0 ? v.toUpperCase() : v).join('') : day}</option>
                    ))}
                  </InputField>
                </Box>
                {form.periodicity === 'Quincenal' && (
                  <Box
                    width='40%'
                  >
                    <InputField
                      type='select'
                      label='Día 2'
                      name='day2'
                      value={form.day2}
                      onChange={this.updateField('day2')}
                      color={theme.primary}
                      required
                    >
                      <option value=''>Selecciona</option>
                      {days.map(day => (
                        <option>{day}</option>
                      ))}
                    </InputField>
                  </Box>
                )}
              </Box>
              <Box
                margin={{ vertical: 'small', left: '20px' }}
              >
                <InputField
                  type='checkbox'
                  value={form.limit_progress}
                  checked={form.limit_progress}
                  onChange={this.updateBoolean('limit_progress')}
                  name='limit_progress'
                  color={theme.primary}
                  add={
                    <span style={{ margin: 0, padding: 0 }}>Limitar avance físico mayor al 100%</span>
                  }
                />

              </Box>
              {!form.limit_progress &&
                <Box
                  margin={{ vertical: 'small', left: '20px', top: '10px' }}
                >
                  <InputField
                    type='checkbox'
                    value={form.limit_progress_visual}
                    checked={form.limit_progress_visual}
                    onChange={this.updateBoolean('limit_progress_visual')}
                    name='limit_progress_visual'
                    color={theme.primary}
                    add={
                      <span style={{ margin: 0, padding: 0 }}>Mostrar señalización visual sobre avance mayor al límite </span>
                    }
                  />
                </Box>}
              {editing && this.renderProjects(project.project_users)}
              <Box
                direction='row'
                justify='end'
                margin={{ vertical: 'small' }}
              >
                <Button
                  icon
                  type='submit'
                  disabled={!form.name || !form.initial_date || !form.close_date || !form.periodicity || (!form.day1 && !form.day2)}
                  color={theme.primary}
                >
                  <SvgGuardar fill={theme.font} width={80} />
                </Button>
              </Box>
            </form>
          </Box>
        )}
        {(editingTypeChange && !(editing || creating) && (
          <TypeChargeComponent theme={theme} callBackSave={this.closeTypeChange.bind()} />
        ))}
        <Modal onClose={() => this.setState({ errorModal: false, inputConfirm: '' })} show={this.state.errorModal} title='¡Advertencia!'>
          <Box onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }} width='medium'>
            <p>
              Esta acción no se puede deshacer, se borraran todos los datos referentes a este usuario y no se podrán recuperar, para confirmar escribe BORRAR
            </p>
            <input onChange={(e) => {
              this.setState({ inputConfirm: e.target.value })
            }} placeholder={'Introduce "BORRAR"'} />
            <Box
              direction='row'
              justify='end'
              align='center'
            >
              <Button
                type='submit'
                required
                style={{
                  margin: '10px'
                }}
                onClick={() => {
                  this.setState({ errorModal: false, inputConfirm: '' }, () => {
                    this.deleteAux(this.state.deleteId)
                  })
                }}
                disabled={this.state.inputConfirm !== 'BORRAR'}
                color={theme.primary}
              >
                Confirmar
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    )
  }
}

export default Projects
