import React, { Component } from 'react'
import store, { history } from '../store'
import { Provider } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { Grommet } from 'grommet'

// Routes
import NotFound from 'components/NotFound'
import Login from 'app/auth'
import Panel from 'app/panel/PanelContainer'
import SelectOrganization from 'app/orgs/Select'

// Notifications
import NotificationsHandler from 'app/notifications'
import AlertOverlay from 'app/alerts/index'

// Theme
import theme from 'theme'

class App extends Component {
  render = () => {
    const { user } = this.props
    const style = document.createElement('style')
    style.innerHTML = `@media print {
  @page {
    margin: 0;
    size: landscape;
  }
  body * {
    visibility: hidden;
  }
  .section-to-print, .section-to-print * {
    visibility: visible;
  }
  .ignore-to-print {
    visibility: hidden!important;
  }
  .section-to-print {
    position: absolute;
    left: 0;
    top: 0;
  }
}`
    style.id = 'page-orientation'
    document.head.appendChild(style)

    return (
      <Provider store={store}>
        <Grommet
          theme={theme}
          full
        >
          <>
            <AlertOverlay />
            <NotificationsHandler />
            <ConnectedRouter history={history}>
              <Switch>

                {/* Login Page */}
                <Route strict path='/login' component={Login} />

                {/* Panel */}
                <Route strict path='/' component={Panel} />

                {/* NotFound */}
                <Route component={NotFound} />
              </Switch>
            </ConnectedRouter>
          </>
        </Grommet>
      </Provider>
    )
  }
}

export default App
