import React from 'react'

const logo = `${process.env.PUBLIC_URL}/images/emsi-logo.png`
const logoSize = 11

export default ({
  size = 1,
  cache = true,
  src,
  pointer,
  ...props
}) => <img {...props} src={src || logo} alt='EMSI' style={{ height: `${logoSize * size}vh`, objectFit: 'cover', ...(pointer ? { cursor: 'pointer' } : {}) }} />
