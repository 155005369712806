import {
  combineCycles
} from 'redux-cycles'
import {
  Types,
  Actions
} from '../../duck/operations'
import {
  NotificationsActions
} from 'app/notifications/duck'
import xs from 'xstream'
import combine from 'xstream/extra/sampleCombine'
import debounce from 'xstream/extra/debounce'
import {
  RoutesActions
} from 'app/routes/duck'
import {
  getToken,
  handleHTTPError,
  ifTypeIs
} from 'helpers/cycles'

const {
  REACT_APP_API
} = process.env

const UFGetAccountCosts = ({
  ACTION,
  STATE,
  HTTP,
  STORAGE
}) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})
  const search$ = STATE.map(state => ({
    search: state.projects.searchText,
    searchType: state.projects.searchInternalType
  }))
  const handleRequest$ = ACTION // UFGetAccountCosts
    .filter(ifTypeIs(Types.U_F_GET_ACCOUNT_COSTS))
    .compose(combine(userToken$, org$, search$))
    .map(([{
      params
    }, token, org, { search: iSearch, searchType: iSearchType }]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/account-costs-index-alt/${params.project}/${params.budget}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      responseType: 'json',
      method: 'POST',
      send: {
        ...params,
        ...((iSearch) ? { search: (iSearch) } : {}),
        ...((iSearchType) ? { searchType: (iSearchType) } : {})
      },
      category: 'UFGET_ACOUNTCOSTS'
    }))

  const getResponse$ = HTTP
    .select('UFGET_ACOUNTCOSTS')
    .map(handleHTTPError)
    .flatten()
    .map(({
      body
    }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.UFSetAccountCosts(body))

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

const UFGetAccountCostsGraph = ({
  ACTION,
  STATE,
  HTTP,
  STORAGE
}) => {
  const userToken$ = getToken(STORAGE)
  let auxId = null
  const org$ = STATE.map(state => state.auth.orgSelected || {})
  const handleRequest$ = ACTION // UFGetAccountCosts
    .filter(ifTypeIs(Types.U_F_GET_ACCOUNT_COSTS_GRAPH))
    .compose(combine(userToken$, org$))
    .map(([{
      params
    }, token, org]) => {
      auxId = params.index
      return {
        url: `${REACT_APP_API}/organization/${org.id || 1}/account-costs-index-alt-all/${params.project}/${params.budget}`,
        headers: {
          Authorization: `Bearer ${token}`
        },
        responseType: 'json',
        method: 'POST',
        send: {
          withPeriods: params.withPeriods
        },
        category: 'UFGET_ACOUNTCOSTS_GRAPH'
      }
    })

  const getResponse$ = HTTP
    .select('UFGET_ACOUNTCOSTS_GRAPH')
    .map(handleHTTPError)
    .flatten()
    .map((
      data
    ) => {
      console.log(data)
      if (data.body.error) {
        return RoutesActions.handleError(data.body.error)
      } else {
        data.body.auxIndex = data.request.send.index
        return Actions.UFSetAccountCostsGraph(data.body)
      }
    })

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

const UFGetAccountCostsSecond = ({
  ACTION,
  STATE,
  HTTP,
  STORAGE
}) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})

  const handleRequest$ = ACTION // UFGetAccountCosts
    .filter(ifTypeIs(Types.U_F_GET_ACCOUNT_COSTS_SECOND))
    .compose(combine(userToken$, org$))
    .map(([{
      params
    }, token, org]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/account-costs-index-alt/${params.project}/${params.budget}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      responseType: 'json',
      method: 'POST',
      send: params,
      category: 'UFGET_ACOUNTCOSTS_SECOND'
    }))

  const getResponse$ = HTTP
    .select('UFGET_ACOUNTCOSTS_SECOND')
    .map(handleHTTPError)
    .flatten()
    .map(({
      body
    }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.UFSetAccountCostsSecond(body))

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

const UFGetFactorsExcel = ({
  ACTION,
  STATE,
  HTTP,
  STORAGE
}) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})

  const handleRequest$ = ACTION // UFGetAccountCosts
    .filter(ifTypeIs(Types.U_F_GET_FACTORS_EXCEL))
    .compose(combine(userToken$, org$))
    .map(([{
      params
    }, token, org]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/makeExcelFactor/${params.project}/${params.budget}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      responseType: 'arraybuffer',
      method: 'POST',
      send: params,
      category: 'UFGET_FACTORSEXCEL'
    }))

  const getResponse$ = HTTP
    .select('UFGET_FACTORSEXCEL')
    .flatten()
    .map((
      data
    ) => {
      const type = data.headers['content-type']
      const blob = new Blob([data.body], { type: type, encoding: 'UTF-8' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      const time = new Date()
      link.download = 'Factores-' + time.toLocaleString().replace(' ', '-') + '.xlsx'
      link.click()
      data
        ? NotificationsActions.requestNotification('Listo', 'info')
        : RoutesActions.handleError('went wrong')
    })

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

const updateFactorsExcel = ({
  ACTION,
  STATE,
  HTTP,
  STORAGE
}) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})

  const handleRequest$ = ACTION // UFGetAccountCosts
    .filter(ifTypeIs(Types.UPDATE_FACTORS_EXCEL))
    .compose(combine(userToken$, org$))
    .map(([{
      params
    }, token, org]) => {
      var formData = new FormData()
      formData.append('import_file', params.file)
      return ({
        url: `${REACT_APP_API}/organization/${org.id || 1}/updateExcelFactor2/${params.project}/${params.budget}`,
        headers: {
          Authorization: `Bearer ${token}`
        },
        contentType: 'multipart/form-data',
        method: 'POST',
        send: formData,
        category: 'UPDATE_FACTORS_EXCEL'
      })
    })

  const getResponse$ = HTTP
    .select('UPDATE_FACTORS_EXCEL')
    .flatten()
    .map((
      data
    ) => {
      if (data.body.message === 'Hecho') {
        setTimeout(() => {
          window.location.reload()
        }, 1000);
        return NotificationsActions.requestNotification('Factores mapeados correctamente', 'info')
      }
      return RoutesActions.handleError('Algo salio mal')
    })

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

const getChargeTypeExcel = ({
  ACTION,
  STATE,
  HTTP,
  STORAGE
}) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})

  const handleRequest$ = ACTION // UFGetAccountCosts
    .filter(ifTypeIs(Types.GET_CHARGE_TYPE_EXCEL))
    .compose(combine(userToken$, org$))
    .map(([{
      params
    }, token, org]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/makeExcelChargeType/${params.project}/${params.budget}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      responseType: 'arraybuffer',
      method: 'POST',
      send: params,
      category: 'GET_CHARGETYPEEXCEL'
    }))

  const getResponse$ = HTTP
    .select('GET_CHARGETYPEEXCEL')
    .flatten()
    .map((
      data
    ) => {
      const type = data.headers['content-type']
      const blob = new Blob([data.body], { type: type, encoding: 'UTF-8' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      const time = new Date()
      link.download = 'Tipo-de-Costo-' + time.toLocaleString().replace(' ', '-') + '.xlsx'
      link.click()
      if (data) {
        return Actions.getExcelSuccess()
      } else {
        return RoutesActions.handleError('Hubo un error en el servidor ')
      }
    })

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

const getChargeTypeExcelCompare = ({
  ACTION,
  STATE,
  HTTP,
  STORAGE
}) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})

  const handleRequest$ = ACTION // UFGetAccountCosts
    .filter(ifTypeIs(Types.GET_CHARGE_TYPE_EXCEL_COMPARE))
    .compose(combine(userToken$, org$))
    .map(([{
      params
    }, token, org]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/excelTypeCompareB/${params.budget}/${params.project}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      responseType: 'arraybuffer',
      method: 'POST',
      send: params,
      category: 'GET_CHARGETYPEEXCELCOMPARE'
    }))

  const getResponse$ = HTTP
    .select('GET_CHARGETYPEEXCELCOMPARE')
    .flatten()
    .map((
      data
    ) => {
      const type = data.headers['content-type']
      const blob = new Blob([data.body], { type: type, encoding: 'UTF-8' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      const time = new Date()
      link.download = 'Tipo-de-Costo-Compararación' + time.toLocaleString().replace(' ', '-') + '.xlsx'
      link.click()
      if (data) {
        return Actions.getExcelSuccess()
      } else {
        return RoutesActions.handleError('Hubo un error en el servidor ')
      }
    })

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

const UFGetAccountCostsById = ({
  ACTION,
  STATE,
  HTTP,
  STORAGE
}) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})

  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.U_F_GET_ACCOUNT_COSTS_BY_ID))
    .compose(combine(userToken$, org$))
    .map(([{
      params
    }, token, org]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/account-costs-index-alt/${params.project}/${params.budget}/${params.accountCostParent}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      responseType: 'json',
      method: 'POST',
      category: 'UFGET_ACOUNTCOSTSBYID'
    }))

  const getResponse$ = HTTP
    .select('UFGET_ACOUNTCOSTSBYID')
    .map(handleHTTPError)
    .flatten()
    .map(({
      body
    }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.UFSetAccountCostsById(body))

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}
const UFGetAccountCostsMagic = ({
  ACTION,
  STATE,
  HTTP,
  STORAGE
}) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})

  const handleRequest$ = ACTION // UFGetAccountCosts
    .filter(ifTypeIs(Types.U_F_GET_ACCOUNT_COSTS_MAGIC))
    .compose(combine(userToken$, org$))
    .map(([{
      params
    }, token, org]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/show-magic-chunked/${params.budget}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      responseType: 'json',
      method: 'GET',
      category: 'UFGET_ACOUNTCOSTSMAGIC'
    }))

  const getResponse$ = HTTP
    .select('UFGET_ACOUNTCOSTSMAGIC')
    .map(handleHTTPError)
    .flatten()
    .map(({
      body
    }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.UFSetAccountCosts(body))

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}
// Obtener data de conceptos por su cuenta de costo
const UFGetConceptByAccountCost = ({
  ACTION,
  STATE,
  HTTP,
  STORAGE
}) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})

  const handleRequest$ = ACTION // UFGetConceptByAccountCost
    .filter(ifTypeIs(Types.U_F_GET_CONCEPT_BY_ACCOUNT_COST))
    .compose(combine(userToken$, org$))
    .map(([{
      params
    }, token, org]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/budget/${params.budgetId}/account_cost/${params.accountCostId}/concepts`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      responseType: 'json',
      method: 'GET',
      category: 'UFGET_CONCEPT_BY_ACOUNT_COST'
    }))

  const getResponse$ = HTTP
    .select('UFGET_CONCEPT_BY_ACOUNT_COST')
    .map(handleHTTPError)
    .flatten()
    .map(({
      body
    }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.UFSetConceptsToEditing(body.data))

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}
const UFSaveAccountCost = ({
  ACTION,
  STATE,
  HTTP,
  STORAGE
}) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})

  const handleRequest$ = ACTION // UFSaveAccountCost
    .filter(ifTypeIs(Types.U_F_SAVE_ACCOUNT_COST))
    .compose(combine(userToken$, org$))
    .map(([{
      params
    }, token, org]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/${params.endpoint}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      responseType: 'json',
      method: 'PUT',
      category: 'UFPUT_SAVE_ACOUNT_COST',
      send: params
    }))

  const getResponse$ = HTTP
    .select('UFPUT_SAVE_ACOUNT_COST')
    .map(handleHTTPError)
    .flatten()
    .map(({
      body
    }) => {
      if (body.error) {
        Actions.UFUpdateLoading(false)
        return RoutesActions.handleError(body.error)
      } else {
        return Actions.UFSaveAccountCostSuccess(body.data)
      }
    })

  const onSuccess$ = ACTION
    .filter(ifTypeIs(Types.U_F_SAVE_ACCOUNT_COST_SUCCESS))

  const sendNotification$ = onSuccess$
    .mapTo(NotificationsActions.requestNotification('Presupuesto mapeado correctamente', 'info'))

  return {
    HTTP: handleRequest$,
    ACTION: xs.merge(getResponse$, sendNotification$)
  }
}
export default combineCycles(
  UFGetAccountCosts,
  UFGetAccountCostsGraph,
  UFGetAccountCostsSecond,
  UFGetAccountCostsById,
  UFGetAccountCostsMagic,
  UFSaveAccountCost,
  UFGetFactorsExcel,
  getChargeTypeExcel,
  UFGetConceptByAccountCost,
  getChargeTypeExcelCompare,
  updateFactorsExcel
)
