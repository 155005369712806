import React, { Component } from 'react'
import styled from 'styled-components'
import { Input, FormGroup, FormText, FormFeedback, Label, InputGroup, InputGroupAddon } from 'reactstrap'

import Button from 'components/Controlled/Button'
import SvgOjoCerrado from '../../icons/OjoCerrado'
import SvgAbiertoojo from '../../icons/Abiertoojo'

class InputComponent extends Component {
  state = {
    showPassword: false
  }

  show = () => {
    this.setState(prevState => ({
      showPassword: !prevState.showPassword
    }))
  }

  render = () => {
    const { children, label, help, error, add, full, password, ...props } = this.props
    const { showPassword } = this.state

    const validProps = !error ? { valid: true } : { invalid: true }

    return (
      <FormGroup style={{ marginBottom: 'none !important', width: full && '100%' }}>
        {label && <Label>{label}</Label>}
        <InputGroup>
          <Input autoComplete='off' {...validProps} {...props} type={(password && showPassword) ? 'text' : props.type}>{children}</Input>
          {add}
          {password && (
            <InputGroupAddon onClick={this.show} addonType='prepend'>
              {password && <Button type='button' color={props.color}>{!showPassword ? <SvgOjoCerrado fill='white' width={25} /> : <SvgAbiertoojo fill='white' width={25} />}</Button>}
            </InputGroupAddon>
          )}
          {(error) && <FormFeedback style={{ background: 'whitesmoke', padding: '5px 10px' }} {...validProps}>{error}</FormFeedback>}
          {help && <FormText>{help}</FormText>}
        </InputGroup>
      </FormGroup>
    )
  }
}

export default styled(InputComponent)`
  border:1px solid ${({ color }) => color || '#EA1D23'} !important;
  outline: 0;
  
  box-shadow: none !important;
  &:focus {
    box-shadow: none;
    outline: 0;
  }
`
