import React, { Component } from 'react'
import { Box, Heading, Text } from 'grommet'
import { connect } from 'react-redux'
import { Document, Plan, CatalogOption } from 'grommet-icons'

import withTheme from 'components/HOC/withTheme'
import InfoBox from 'components/InfoBox'
import Logo from 'components/Logo'

import Button from 'components/Button'
import Icon from 'components/Icon'

import { ReactComponent as DaysLeft } from 'assets/icons/diasrestantes.svg'
import { ReactComponent as Projects } from 'assets/icons/proyectos.svg'
import { ReactComponent as Package } from 'assets/icons/tipopaquete.svg'

const state = state => ({
  user: state.auth.user || {},
  org: state.auth.orgSelected || {}
})

@withTheme
@connect(state, null)
class Welcome extends Component {
  render = () => {
    const { user, org, theme } = this.props
    console.log(org)

    return (
      <Box
        fill
        justify='between'
      >
        <Heading level={1} weight={100} margin={{ vertical: 'small', horizontal: 'medium' }}>Bienvenid@, {user.name}</Heading>
        <Box
          fill='horizontal'
          direction='row'
          justify='center'
          align='center'
          margin={{ vertical: 'medium' }}
        >
          <Logo src={theme.logo} size={2} />
        </Box>
        <Box
          fill='horizontal'
          justify='between'
          direction='row'
          margin={{ top: 'medium' }}
          pad={{ horizontal: 'medium' }}
          align='center'
        >
          <InfoBox
            direction='row'
            justify='between'
            margin={{ horizontal: 'small' }}
            align='center'
            color={theme.primary}
          >
            <Box>
              <Heading margin={{ vertical: 'none' }} level={3}>{org.remaining_projects ? (org.remaining_projects < 0 ? 'Ilimitado' : org.remaining_projects) : 'Ilimitado'}</Heading>
              <Text>Proyectos restantes</Text>
            </Box>
            <Box
              margin={{ left: 'medium' }}
            >
              <Package width={100} height={100} style={{ fill: '#999999' }} />
            </Box>
          </InfoBox>
          <InfoBox
            direction='row'
            justify='between'
            margin={{ horizontal: 'small' }}
            align='center'
            color={theme.primary}
          >
            <Box>
              <Heading margin={{ vertical: 'none' }} level={3}>{org.remaining_days >= 0 ? (org.remaining_days <= 0 ? 'Ilimitado' : org.remaining_days) : 'Ilimitado'}</Heading>
              <Text>Días restantes</Text>
            </Box>
            <Box
              margin={{ left: 'medium' }}
            >
              <DaysLeft width={100} height={100} style={{ fill: '#999999' }} />
            </Box>
          </InfoBox>
          <InfoBox
            direction='row'
            justify='between'
            margin={{ horizontal: 'small' }}
            align='center'
            color={theme.primary}
          >
            <Box>
              <Heading margin={{ vertical: 'none' }} level={3}>{org.allowed_projects >= 0 ? (org.allowed_projects < 0 ? 'Ilimitado' : org.allowed_projects) : 'Ilimitado'}</Heading>
              <Text>Total de proyectos</Text>
            </Box>
            <Box
              margin={{ left: 'medium' }}
            >
              <Projects width={100} height={100} style={{ fill: '#999999' }} />
            </Box>
          </InfoBox>
        </Box>
      </Box>
    )
  }
}

export default Welcome
