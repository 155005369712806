import React, { Component } from 'react'
import { Add, Close, Trash, Edit } from 'grommet-icons'
import { Box, Heading, TableBody, TableCell, TableHeader, TableRow, Text, TextInput, CheckBox, Button as ButtonG } from 'grommet'
import { Table } from 'reactstrap'
import { connect } from 'react-redux'
import { EmailInputField, Form, SelectField, TextInputField, validators } from 'grommet-controls'
import { Input, Select } from 'antd'
import hexToRGBA from 'hex-to-rgba'
import { UsersActions } from './duck'
import Overlay from '../../components/OverlayLoading'

import Permission from 'components/Permission'
import withTheme from 'components/HOC/withTheme'

import Button from 'components/Button'
import Icon from 'components/Icon'
import Divider from 'components/Divider'
import InputField from 'components/Controlled/Input'
import SvgNuevo from '../../icons/Nuevo'
import SvgGuardar from '../../icons/Guardar'
import SvgReportes from '../../icons/Correo'
import OverlayLoading from 'components/OverlayLoading'
import { formToArray } from '../../helpers'
import SvgLapiz from '../../icons/Lapiz'
import SvgBasura from '../../icons/Basura'
import withFetching from 'components/HOC/withFetching'
import { Typography } from 'antd'
import { AiOutlineFileAdd } from 'react-icons/ai/index'
import Modal from '../../components/Modal'

const { Title } = Typography

const state = state => ({
  users: state.users.users,
  loading: state.users.fetchingUsers,
  ready: state.auth.ready,
  errorModal: false,
  errorModalIndex: null,
  inputConfirm: ''
})

const dispatch = {
  getUsers: UsersActions.fetchUsers,
  send: UsersActions.requestCreation,
  delete: UsersActions.deleteUser,
  resend: UsersActions.resendInvite
}

const INITIAL_STATE = {
  creating: false,
  editing: false,
  user: null,
  selections: {},
  form: {}
}

const getRole = user => (user.pivot || {}).is_admin === '1'
    ? 'Administrador'
    : 'Usuario'

@withTheme
@connect(state, dispatch)
@withFetching(UsersActions.fetchUsers)
class Users extends Component {
  state = INITIAL_STATE

  shouldComponentUpdate = nextProps => nextProps.ready

  open = e => {
    this.setState({
      creating: true,
      editing: false
    })
  }

  updateField = name => e => {
    const value = e.target.value

    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [name]: value
      }
    }))
  }

  handleCloseEdit = () => {
    this.setState(prevState => ({
      creating: !prevState.showCreate
    }))
  }

  closeEdit = () => {
    this.setState(INITIAL_STATE)
  }

  select = (type, id) => e => {
    const checked = e.target.checked

    this.setState(prevState => ({
      selections: {
        ...prevState.selections,
        [id]: checked ? type : null
      }
    }))
  }

  edit = (userId, stack) => () => {
    const { users } = this.props

    this.setState(prevState => {
      const user = (stack ? users[stack] : users).filter(({ id }) => id === userId)[0] || {}
      return {
        editing: true,
        creating: false,
        form: {
          email: user.email,
          rol: getRole(user)
        },
        user: user,
        selections: {}
      }
    })
  }

  deleteData = id => () => {
    this.props.delete(id)
  }

  resend = id => () => {
    this.props.resend(id)
  }

  send = e => {
    e.preventDefault()
    const values = formToArray(e.target)
    let dataAux = { ...this.state.selections }
    Object.keys(dataAux).forEach((key) => {
      if (dataAux[key] === null) {
        delete dataAux[key]
      }
    })
    this.props.send({ ...values }, dataAux, (this.state.user || {}).id)
    this.closeEdit()
  }

  componentWillUnmount = () => {
    this.closeEdit()
  }

  renderProjects = (values) => {
    return values && (
      <Permission>
        {({ role }) => (
          <>
            <Box
              direction='row'
              align='center'
              justify='between'
              margin={{ bottom: 'small', top: 'medium' }}
            >
              <Title
                level={3}
                style={{ marginBottom: '30px' }}
              >
                Proyectos asignados
              </Title>
            </Box>
            <div style={{ maxHeight: 400, overflowY: 'auto' }}>
              <Table style={{ width: '100%' }} caption='Organizaciones'>
                <thead>
                  <tr>
                    <th color='secondary' weight='bold'>Nombre</th>
                    <th color='secondary' weight='bold'>Visor</th>
                    <th color='secondary' weight='bold'>Usuario</th>
                  </tr>
                </thead>
                <tbody>
                  {values.map(({ name, project_users = [], id }, i) => (
                    <tr key={i}>
                      <th><Text>{name}</Text></th>
                      <th><InputField type='checkbox' onChange={this.select('Viewer', id)} checked={this.state.selections[id] ? (this.state.selections[id] === 'Viewer') : ((project_users[0] || {}).role === 'Viewer')} /></th>
                      <th><InputField type='checkbox' onChange={this.select('User', id)} checked={this.state.selections[id] ? (this.state.selections[id] === 'User') : ((project_users[0] || {}).role === 'User')} /></th>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </>
        )}
      </Permission>
    )
  }

  render = () => {
    const { users, loading, theme } = this.props
    const { user, editing, creating, form } = this.state
    const deleteD = this.deleteData
    console.log(users)
    return (
      <Box
        direction='row'
        flex
        align='start'
        justify='between'
        fill='horizontal'
        margin='xsmall'
      >
        <Box
          pad='medium'
          background='white'
          round='xsmall'
          fill='vertical'
          responsive='false'
          style={{ width: creating || editing ? '50%' : '100%', position: 'relative', overflowY: 'auto' }}
        >
          <OverlayLoading overlayColor={theme.primary} loaderColor={theme.font} pose={(loading || !users) ? 'in' : 'out'} />
          <Box
            direction='row'
            align='center'
            justify='between'
          >
            <Title
              level={3}
            >
              Usuarios creados
            </Title>
          </Box>
          <Divider color='black' />
          <Box
            margin='small'
            flex='false'
          >
            <ButtonG
              disabled={creating || editing}
              // color={theme.primary}
              style={{ width: '30px' }}
              onClick={this.open}
            >
              <AiOutlineFileAdd fill={theme.font} style={{ fontSize: '30px' }} />
              {/* <SvgNuevo fill={theme.font} width={80} /> */}
            </ButtonG>
          </Box>
          <div style={{ overflowY: 'auto', height: '100%' }} >
            {users
              ? (
                <Table responsive borderless>
                  <thead style={{ borderRadius: '10px 10px 0 0', backgroundColor: theme.primary }}>
                    <tr>
                      <th style={{ width: '33%' }}><Text color={theme.font} weight='bold'>Nombre</Text></th>
                      <th style={{ width: '33%' }}><Text color={theme.font} weight='bold'>Rol</Text></th>
                      <th style={{ width: '33%' }}><Text color={theme.font} weight='bold'>Acciones</Text></th>
                    </tr>
                  </thead>
                  <tbody>
                    {(users.length) && users.map((u, i) => (
                      <tr key={u.id} style={{ borderBottom: `1px solid ${hexToRGBA(theme.primary, 0.2)}` }}>
                        <td>
                          <Text>{u.name || 'Sin nombre'}</Text>
                        </td>
                        <td>
                          <Text weight='bold'>{getRole(u)}</Text>
                        </td>
                        <td>
                          <Permission>
                            {({ role }) => (
                              <Box
                                direction='row'
                                align='start'
                              >
                                {u && !u.email_verified_at && <Button
                                  icon
                                  disabled={
                                    (getRole(u) === 'Super' && role === 'admin') ||
                                    (getRole(u) === 'Administrador' && role === 'user')
                                  }
                                  onClick={this.resend(u.id)}
                                  color={theme.primary}
                                >
                                  <SvgReportes fill={theme.font} width={65} />
                                </Button>}
                                <Button
                                  icon
                                  style={{ marginLeft: '12px' }}
                                  disabled={
                                    (getRole(u) === 'Super' && role === 'admin') ||
                                    (getRole(u) === 'Administrador' && role === 'user')
                                  }
                                  onClick={this.edit(u.id)}
                                  color={theme.primary}
                                >
                                  <SvgLapiz fill={theme.font} width={65} />
                                </Button>
                                <Box margin={{ left: 'small' }}>
                                  <Modal onClose={() => this.setState({ errorModal: false, inputConfirm: '' })} show={this.state.errorModal && this.state.errorModalIndex === i} title='¡Advertencia!'>
                                    <Box onClick={(e) => {
                                      e.preventDefault()
                                      e.stopPropagation()
                                    }} width='medium'>
                                      <p>
                                        Esta acción no se puede deshacer, se borraran todos los datos referentes a este usuario y no se podrán recuperar, para confirmar escribe BORRAR
                                      </p>
                                      <input onChange={(e) => {
                                        this.setState({ inputConfirm: e.target.value })
                                      }} placeholder={'Introduce "BORRAR"'} />
                                      <Box
                                        direction='row'
                                        justify='end'
                                        align='center'
                                      >
                                        <Button
                                          type='submit'
                                          required
                                          style={{
                                            margin: '10px'
                                          }}
                                          onClick={() => {
                                            this.setState({ errorModal: false, inputConfirm: '', errorModalIndex: null }, () => {
                                              this.props.delete(u.id)
                                            })
                                          }}
                                          disabled={this.state.inputConfirm !== 'BORRAR'}
                                          color={theme.primary}
                                        >
                                          Confirmar
                                        </Button>
                                      </Box>
                                    </Box>
                                  </Modal>
                                  <Button
                                    icon
                                    disabled={
                                      (getRole(u) === 'Super' && role === 'admin') ||
                                      (getRole(u) === 'Administrador' && role === 'user')
                                    }
                                    color={theme.primary}
                                    onClick={(ev) => {
                                      ev.preventDefault()
                                      ev.stopPropagation()
                                      this.setState({ errorModal: true, errorModalIndex: i })
                                      return true
                                    }}
                                  >
                                    <SvgBasura fill={theme.font} width={65} />
                                  </Button>
                                </Box>
                              </Box>
                            )}
                          </Permission>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <Text>No hay usuarios.</Text>
              )}
          </div>
        </Box>
        {(editing || creating) && (
          <Box
            background='white'
            pad='medium'
            margin={{ horizontal: 'small' }}
            fill={false}
            width='48%'
            round='xsmall'
          >
            <Box
              direction='row'
              align='center'
              justify='between'
              margin={{ bottom: 'none' }}
            >
              <Heading
                margin={{ vertical: 'small' }}
                color='black'
                level={3}
              >
                {editing ? 'Actualizar' : 'Crear'}
              </Heading>
              <Button color='white' icon onClick={this.closeEdit}>
                <Close color='black' />
              </Button>
            </Box>
            <Divider color='black' />
            <Permission>
              {({ role }) => (
                <form
                  onSubmit={this.send}
                >
                  <Box
                    margin={{ vertical: 'small' }}
                  >
                    <InputField
                      required
                      value={form.email}
                      onChange={this.updateField('email')}
                      name='email'
                      color={theme.primary}
                      placeholder='ejemplo@mail.com'
                      label='Correo'
                    />
                  </Box>
                  <Box
                    margin={{ vertical: 'small' }}
                  >
                    <InputField
                      type='select'
                      name='rol'
                      value={form.rol}
                      onChange={this.updateField('rol')}
                      color={theme.primary}
                      label='Rol'
                      required
                    >
                      <option value=''>Selecciona</option>
                      <option value='Administrador'>Administrador</option>
                      <option value='Usuario'>Usuario</option>
                    </InputField>
                  </Box>
                  {editing && <Box
                    margin={{ vertical: 'small' }}
                  >
                    <p>Estado</p>
                    {user && user.email_verified_at
                      ? <p style={{ background: 'lightgreen', padding: '15px', textAlign: 'left' }}>Verificado</p>
                      : <p style={{ background: 'whitesmoke', padding: '15px', textAlign: 'left' }}>Sin verificar, contraseña: {user && user.temp_password }</p>
                    }

                  </Box>}
                  <Box margin={{ vertical: 'small' }}>
                    <Divider color='rgba(0, 0, 0, 0.3)' />
                  </Box>
                  {(editing && getRole(user) === 'Usuario') && this.renderProjects(user.projects)}
                  <Box
                    direction='row'
                    align='center'
                    justify='end'
                  >
                    <Button
                      icon
                      required
                      type='submit'
                      disabled={!form.email || !form.rol}
                      color={theme.primary}
                    >
                      <SvgGuardar fill={theme.font} width={80} />
                    </Button>
                  </Box>
                </form>
              )}
            </Permission>
          </Box>
        )}
      </Box>
    )
  }
}

export default Users
