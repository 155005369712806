import { combineCycles } from 'redux-cycles'
import { Types, Actions } from './operations'
import { NotificationsActions } from 'app/notifications/duck'
import xs from 'xstream'
import { push } from 'connected-react-router'
import combine from 'xstream/extra/sampleCombine'
import debounce from 'xstream/extra/debounce'
import { RoutesActions } from 'app/routes/duck'
import { getToken, handleHTTPError, ifTypeIs } from 'helpers/cycles'

import { default as ciclesUnitFactors } from '../UnitFactors/Redux/cyclesUnitFactors'
import { default as ciclesBudgetView } from '../BudgetView/Redux/cyclesBudgetView'

const { REACT_APP_API } = process.env

const fetchProjects = ({ ACTION, STATE, HTTP, STORAGE }) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})

  const handleAction$ = ACTION
    .filter(({ type }) => type === '@@router/LOCATION_CHANGE')
    .filter(({ payload: { location: { pathname } } }) => pathname.includes('projects'))
    .mapTo(Actions.fetchProjects())

  const triggerFetch$ = ACTION
    .filter(({ type }) => type === Types.FETCH_PROJECTS)
    .compose(combine(userToken$, org$))
    .map(([, token, org]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/projects`,
      headers: { 'Authorization': `Bearer ${token}` },
      method: 'GET',
      responseType: 'json',
      category: 'PROJECTS_FETCH'
    }))

  const updateUsers$ = HTTP
    .select('PROJECTS_FETCH')
    .map(handleHTTPError)
    .flatten()
    .map(({ body }) => {
      return !body.error
        ? Actions.updateProjects(body.projects)
        : RoutesActions.handleError(body.error)
    })
  return {
    HTTP: triggerFetch$,
    ACTION: xs.merge(updateUsers$, handleAction$)
  }
}

const updateProgressExcel = ({
  ACTION,
  STATE,
  HTTP,
  STORAGE
}) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})

  const handleRequest$ = ACTION // UFGetAccountCosts
    .filter(ifTypeIs(Types.UPDATE_PROGRESS_EXCEL))
    .compose(combine(userToken$, org$))
    .map(([{
      params
    }, token, org]) => {
      var formData = new FormData()
      formData.append('import_file', params.file)
      return ({
        url: `${REACT_APP_API}/organization/${org.id || 1}/excelProgressUpdate/${params.budget}/${params.project}`,
        headers: {
          Authorization: `Bearer ${token}`
        },
        contentType: 'multipart/form-data',
        method: 'POST',
        send: formData,
        category: 'UPDATE_PROGRESSEXCEL'
      })
    })

  const getResponse$ = HTTP
    .select('UPDATE_PROGRESSEXCEL')
    .flatten()
    .map((
      data
    ) => {
      if (data.body.message === 'Hecho') {
        setTimeout(() => {
          window.location.reload()
        }, 1000)
        return NotificationsActions.requestNotification('Avance físico - Excel cargado correctamente', 'info')
      }
      return RoutesActions.handleError('Algo salio mal')
    })

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

const updateProgressRealExcel = ({
  ACTION,
  STATE,
  HTTP,
  STORAGE
}) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})

  const handleRequest$ = ACTION // UFGetAccountCosts
    .filter(ifTypeIs(Types.UPDATE_PROGRESS_REAL_EXCEL))
    .compose(combine(userToken$, org$))
    .map(([{
      params
    }, token, org]) => {
      var formData = new FormData()
      formData.append('import_file', params.file)
      return ({
        url: `${REACT_APP_API}/organization/${org.id || 1}/excelProgressRealUpdate/${params.budget}/${params.project}`,
        headers: {
          Authorization: `Bearer ${token}`
        },
        contentType: 'multipart/form-data',
        method: 'POST',
        send: formData,
        category: 'UPDATE_PROGRESSREALEXCEL'
      })
    })

  const getResponse$ = HTTP
    .select('UPDATE_PROGRESSREALEXCEL')
    .flatten()
    .map(({ body }) => {
      if (body.error) {
        return RoutesActions.handleError(body.error)
      } else {
        setTimeout(() => {
          window.location.reload()
        }, 1000)
        return NotificationsActions.requestNotification('Costo Real - Excel cargado correctamente', 'info')
      }
    })
  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

const createProject = ({ ACTION, STATE, HTTP, STORAGE }) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})
  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.REQUEST_CREATION))
    .compose(combine(userToken$, org$))
    .map(([{ payload, users, id }, token, org]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/projects${id ? `/${id}` : ''}`,
      headers: { Authorization: `Bearer ${token}` },
      method: id ? 'PUT' : 'POST',
      category: 'CREATE_PROJECT',
      send: {
        ...payload,
        organization_users: Object.keys(users)
          .reduce((accum, id) => {
            const role = users[id]
            return [
              ...accum,
              {
                id,
                role
              }
            ]
          }, [])
      },
      responseType: 'json'
    }))

  const getResponse$ = HTTP
    .select('CREATE_PROJECT')
    .map(handleHTTPError)
    .flatten()
    .map(({ body }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.creationSuccess())

  const onSuccess$ = ACTION
    .filter(ifTypeIs(Types.CREATION_SUCCESS))

  const sendNotification$ = onSuccess$
    .mapTo(NotificationsActions.requestNotification('Proyecto actualizado', 'info'))

  const updateOrgs$ = onSuccess$
    .mapTo(Actions.fetchProjects())

  return {
    HTTP: handleRequest$,
    ACTION: xs.merge(getResponse$, sendNotification$, updateOrgs$)
  }
}

const fetchProject = ({ ACTION, HTTP, STATE, STORAGE }) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected
    ? state.auth.orgSelected.id
    : location.pathname.split('/')[2])

  const handleFetch$ = ACTION
    .filter(ifTypeIs(Types.FETCH_PROJECT))
    .map(({ id }) => id)

  const requestProject$ = handleFetch$
    .compose(combine(userToken$, org$))
    .map(([id, token, org]) => {
      return ({
        url: `${REACT_APP_API}/organization/${org || 1}/projects/${id}`,
        headers: { Authorization: `Bearer ${token}` },
        category: 'FETCH_PROJECT',
        method: 'GET'
      })
    })

  const fetchProject$ = HTTP
    .select('FETCH_PROJECT')
    .map(handleHTTPError)
    .flatten()
    .map(({ body }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.projectFetched(body))

  return {
    HTTP: requestProject$,
    ACTION: fetchProject$
  }
}

const deleteProject = ({ ACTION, HTTP, STATE, STORAGE }) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})

  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.DELETE_PROJECT))
    .compose(combine(userToken$, org$))
    .map(([{ id }, token, org]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/projects/${id}`,
      headers: { Authorization: `Bearer ${token}` },
      method: 'DELETE',
      category: 'DELETE_PROJECT'
    }))

  const getResponse$ = HTTP
    .select('DELETE_PROJECT')
    .map(handleHTTPError)
    .flatten()
    .map(({ body }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.fetchProjects())

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

const deleteBudget = ({ ACTION, HTTP, STATE, STORAGE }) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})

  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.DELETE_BUDGET))
    .compose(combine(userToken$, org$))
    .map(([{ id }, token, org]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/budgets/${id}`,
      headers: { Authorization: `Bearer ${token}` },
      method: 'DELETE',
      category: 'DELETE_BUDGET'
    }))

  const getResponse$ = HTTP
    .select('DELETE_BUDGET')
    .map(handleHTTPError)
    .flatten()
    .map(({ body }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.fetchProjects())

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

const getBudget = ({ ACTION, STATE, HTTP, STORAGE }) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})
  const search$ = STATE.map(state => ({
    search: state.projects.searchText,
    searchType: state.projects.searchInternalType
  }))

  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.GET_BUDGET_BY_ID))
    .compose(combine(userToken$, org$, search$))
    .map(([{ id, search, searchType, compareBudgets, compareBudgetsAdvance }, token, org, { search: iSearch, searchType: iSearchType }]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/show-chunked/${id}`,
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'json',
      method: 'POST',
      category: 'GET_BUDGET',
      send: ((iSearch || search) && (iSearchType || searchType)) ? {
        search: (iSearch || search),
        ...((iSearch || search) ? { search_type: (iSearchType || searchType) } : {})
      } : {
        compareBudgets: compareBudgets,
        compareBudgetsAdvance: compareBudgetsAdvance
      }
    }))

  const getResponse$ = HTTP
    .select('GET_BUDGET')
    .map(handleHTTPError)
    .flatten()
    .map(({ body }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.getBudgetSuccess(body.budget_items))

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

const getBudgetSecond = ({ ACTION, STATE, HTTP, STORAGE }) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})
  const search$ = STATE.map(state => ({
    search: state.projects.searchText,
    searchType: state.projects.searchInternalType
  }))

  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.GET_BUDGET_SECOND_BY_ID))
    .compose(combine(userToken$, org$, search$))
    .map(([{ id, search, searchType, compareBudgets, compareBudgetsAdvance }, token, org, { search: iSearch, searchType: iSearchType }]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/show-chunked/${id}`,
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'json',
      method: 'POST',
      category: 'GET_BUDGET_SECOND',
      send: ((iSearch || search) && (iSearchType || searchType)) ? {
        search: (iSearch || search),
        ...((iSearch || search) ? { search_type: (iSearchType || searchType) } : {})
      } : {
        compareBudgets: compareBudgets,
        compareBudgetsAdvance: compareBudgetsAdvance
      }
    }))

  const getResponse$ = HTTP
    .select('GET_BUDGET_SECOND')
    .map(handleHTTPError)
    .flatten()
    .map(({ body }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.getBudgetSecondSuccess(body))

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

const getGeneralHistory = ({ ACTION, STATE, HTTP, STORAGE }) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})
  const search$ = STATE.map(state => ({
    search: state.projects.searchText,
    searchType: state.projects.searchInternalType
  }))

  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.GET_GENERAL_HISTORY))
    .compose(combine(userToken$, org$, search$))
    .map(([{ id, search, searchType, compareBudgets, compareBudgetsAdvance }, token, org, { search: iSearch, searchType: iSearchType }]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/generalHistory/${id}`,
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'json',
      method: 'POST',
      category: 'GET_GENERALHISTORY'
    }))

  const getResponse$ = HTTP
    .select('GET_GENERALHISTORY')
    .map(handleHTTPError)
    .flatten()
    .map(({ body }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.getHistorySuccess(body))

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

const getBudgetProgress = ({ ACTION, STATE, HTTP, STORAGE }) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})
  const search$ = STATE.map(state => ({
    search: state.projects.searchText,
    searchType: state.projects.searchInternalType
  }))

  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.GET_BUDGET_PROGRESS_BY_ID))
    .compose(combine(userToken$, org$, search$))
    .map(([{ id, search, searchType }, token, org, { search: iSearch, searchType: iSearchType }]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/show-chunked-progress/${id}`,
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'json',
      method: 'POST',
      category: 'GET_BUDGET',
      send: ((iSearch || search) && (iSearchType || searchType)) && {
        search: (iSearch || search),
        ...((iSearch || search) ? { search_type: (iSearchType || searchType) } : {})
      }
    }))

  const getResponse$ = HTTP
    .select('GET_BUDGET')
    .map(handleHTTPError)
    .flatten()
    .map(({ body }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.getBudgetSuccess(body))

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

const getExcelBudget = ({
  ACTION,
  STATE,
  HTTP,
  STORAGE
}) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})

  const handleRequest$ = ACTION // UFGetAccountCosts
    .filter(ifTypeIs(Types.GET_EXCEL_BUDGET))
    .compose(combine(userToken$, org$))
    .map(([{
      id, period, colors
    }, token, org]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/excelProgress/${id}/${period}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      responseType: 'arraybuffer',
      method: 'POST',
      send: { colors },
      category: 'GET_EXCELBUDGET'
    }))

  const getResponse$ = HTTP
    .select('GET_EXCELBUDGET')
    .flatten()
    .map((
      data
    ) => {
      const type = data.headers['content-type']
      const blob = new Blob([data.body], { type: type, encoding: 'UTF-8' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      const time = new Date()
      link.download = 'Avance-Físico-' + time.toLocaleString().replace(' ', '-') + '.xlsx'
      link.click()
      if (data) {
        return Actions.getExcelSuccess()
      } else {
        return RoutesActions.handleError('went wrong')
      }
    })

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

const getExcelAccountExt = ({
  ACTION,
  STATE,
  HTTP,
  STORAGE
}) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})

  const handleRequest$ = ACTION // UFGetAccountCosts
    .filter(ifTypeIs(Types.GET_EXCEL_EXT))
    .compose(combine(userToken$, org$))
    .map(([{
      projectId, colors
    }, token, org]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/excelExt/${projectId}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      responseType: 'arraybuffer',
      method: 'POST',
      send: { colors },
      category: 'GET_EXCEL_EXT'
    }))

  const getResponse$ = HTTP
    .select('GET_EXCEL_EXT')
    .flatten()
    .map((
      data
    ) => {
      const type = data.headers['content-type']
      const blob = new Blob([data.body], { type: type, encoding: 'UTF-8' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      const time = new Date()
      link.download = 'Exportar-Sistema-' + time.toLocaleString().replace(' ', '-') + '.xlsx'
      link.click()
      data
        ? NotificationsActions.requestNotification('Listo', 'info')
        : RoutesActions.handleError('went wrong')
    })

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

const getBudgetBaseExcel = ({
  ACTION,
  STATE,
  HTTP,
  STORAGE
}) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})

  const handleRequest$ = ACTION // UFGetAccountCosts
    .filter(ifTypeIs(Types.GET_BUDGET_BASE_EXCEL))
    .compose(combine(userToken$, org$))
    .map(([{
      projectId, budgetId, colors
    }, token, org]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/excelBudget/${budgetId}/${projectId}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      responseType: 'arraybuffer',
      method: 'POST',
      send: { colors },
      category: 'GET_BUDGETBASEEXCEL'
    }))

  const getResponse$ = HTTP
    .select('GET_BUDGETBASEEXCEL')
    .flatten()
    .map((
      data
    ) => {
      const type = data.headers['content-type']
      const blob = new Blob([data.body], { type: type, encoding: 'UTF-8' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      const time = new Date()
      link.download = 'Presupuesto-' + time.toLocaleString().replace(' ', '-') + '.xlsx'
      link.click()
      if (data) {
        return Actions.getExcelSuccess()
      } else {
        return RoutesActions.handleError('Hubo un error en el servidor ')
      }
    })

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

const getBudgetBaseExcelCompare = ({
  ACTION,
  STATE,
  HTTP,
  STORAGE
}) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})

  const handleRequest$ = ACTION // UFGetAccountCosts
    .filter(ifTypeIs(Types.GET_BUDGET_BASE_EXCEL_COMPARE))
    .compose(combine(userToken$, org$))
    .map(([{
      projectId, budgetId, colors, secondBudgetId
    }, token, org]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/excelBudgetCompare/${budgetId}/${projectId}/${secondBudgetId}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      responseType: 'arraybuffer',
      method: 'POST',
      send: { colors },
      category: 'GET_BUDGETBASEEXCELCOMPARE'
    }))

  const getResponse$ = HTTP
    .select('GET_BUDGETBASEEXCELCOMPARE')
    .flatten()
    .map((
      data
    ) => {
      const type = data.headers['content-type']
      const blob = new Blob([data.body], { type: type, encoding: 'UTF-8' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      const time = new Date()
      link.download = 'Presupuesto-' + time.toLocaleString().replace(' ', '-') + '.xlsx'
      link.click()
      if (data) {
        return Actions.getExcelSuccess()
      } else {
        return RoutesActions.handleError('Hubo un error en el servidor ')
      }
    })

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

const getCostExcel = ({
  ACTION,
  STATE,
  HTTP,
  STORAGE
}) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})

  const handleRequest$ = ACTION // UFGetAccountCosts
    .filter(ifTypeIs(Types.GET_COST_EXCEL))
    .compose(combine(userToken$, org$))
    .map(([{
      projectId, budgetId, colors
    }, token, org]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/excelCost/${budgetId}/${projectId}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      responseType: 'arraybuffer',
      method: 'POST',
      send: { colors },
      category: 'GET_COSTEXCEL'
    }))

  const getResponse$ = HTTP
    .select('GET_COSTEXCEL')
    .flatten()
    .map((
      data
    ) => {
      const type = data.headers['content-type']
      const blob = new Blob([data.body], { type: type, encoding: 'UTF-8' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      const time = new Date()
      link.download = 'Cuenta-de-costo-' + time.toLocaleString().replace(' ', '-') + '.xlsx'
      link.click()
      if (data) {
        return Actions.getExcelSuccess()
      } else {
        return RoutesActions.handleError('Hubo un error en el servidor ')
      }
    })

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

const getCostExcelCompare = ({
  ACTION,
  STATE,
  HTTP,
  STORAGE
}) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})

  const handleRequest$ = ACTION // UFGetAccountCosts
    .filter(ifTypeIs(Types.GET_COST_EXCEL_COMPARE))
    .compose(combine(userToken$, org$))
    .map(([{
      projectId, budgetId, periodIdF, periodIdS, colors
    }, token, org]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/excelCostCompare/${budgetId}/${projectId}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      responseType: 'arraybuffer',
      method: 'POST',
      send: {
        periodStart: periodIdF,
        periodFinish: periodIdS,
        colors: colors
      },
      category: 'GET_COSTEXCELCOMPARE'
    }))

  const getResponse$ = HTTP
    .select('GET_COSTEXCELCOMPARE')
    .flatten()
    .map((
      data
    ) => {
      const type = data.headers['content-type']
      const blob = new Blob([data.body], { type: type, encoding: 'UTF-8' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      const time = new Date()
      link.download = 'Cuenta-de-costo-AvC-Reporte-' + time.toLocaleString().replace(' ', '-') + '.xlsx'
      link.click()
      if (data) {
        return Actions.getExcelSuccess()
      } else {
        return RoutesActions.handleError('Hubo un error en el servidor ')
      }
    })

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

const getCostExcelCompareUnitCost = ({
  ACTION,
  STATE,
  HTTP,
  STORAGE
}) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})

  const handleRequest$ = ACTION // UFGetAccountCosts
    .filter(ifTypeIs(Types.GET_COST_EXCEL_COMPARE_UNIT_COST))
    .compose(combine(userToken$, org$))
    .map(([{
      projectId, budgetId, periodIdF, periodIdS, colors
    }, token, org]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/excelUnitCostCompare/${budgetId}/${projectId}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      responseType: 'arraybuffer',
      method: 'POST',
      send: {
        periodStart: periodIdF,
        periodFinish: periodIdS,
        colors: colors
      },
      category: 'GET_COSTEXCELCOMPAREUNITCOST'
    }))

  const getResponse$ = HTTP
    .select('GET_COSTEXCELCOMPAREUNITCOST')
    .flatten()
    .map((
      data
    ) => {
      const type = data.headers['content-type']
      const blob = new Blob([data.body], { type: type, encoding: 'UTF-8' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      const time = new Date()
      link.download = 'Costos-Unitarios-por-Cuenta-' + time.toLocaleString().replace(' ', '-') + '.xlsx'
      link.click()
      if (data) {
        return Actions.getExcelSuccess()
      } else {
        return RoutesActions.handleError('Hubo un error en el servidor ')
      }
    })

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}
const getCostExcelCompareType = ({
  ACTION,
  STATE,
  HTTP,
  STORAGE
}) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})

  const handleRequest$ = ACTION // UFGetAccountCosts
    .filter(ifTypeIs(Types.GET_COST_EXCEL_COMPARE_TYPE))
    .compose(combine(userToken$, org$))
    .map(([{
      projectId, budgetId, periodIdF, periodIdS, colors
    }, token, org]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/excelTypeCompare/${budgetId}/${projectId}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      responseType: 'arraybuffer',
      method: 'POST',
      send: {
        periodStart: periodIdF,
        periodFinish: periodIdS,
        colors: colors
      },
      category: 'GET_COSTEXCELCOMPARETYPE'
    }))

  const getResponse$ = HTTP
    .select('GET_COSTEXCELCOMPARETYPE')
    .flatten()
    .map((
      data
    ) => {
      const type = data.headers['content-type']
      const blob = new Blob([data.body], { type: type, encoding: 'UTF-8' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      const time = new Date()
      link.download = 'TIpos-de-cargo-AvC-Reporte-' + time.toLocaleString().replace(' ', '-') + '.xlsx'
      link.click()
      if (data) {
        return Actions.getExcelSuccess()
      } else {
        return RoutesActions.handleError('Hubo un error en el servidor ')
      }
    })

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

const getExcelReal = ({
  ACTION,
  STATE,
  HTTP,
  STORAGE
}) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})

  const handleRequest$ = ACTION // UFGetAccountCosts
    .filter(ifTypeIs(Types.GET_EXCEL_REAL))
    .compose(combine(userToken$, org$))
    .map(([{
      projectId, budgetId, periodId, colors
    }, token, org]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/excelProgressReal/${projectId}/${budgetId}/${periodId}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      responseType: 'arraybuffer',
      method: 'POST',
      send: { colors },
      category: 'GET_EXCELREAL'
    }))

  const getResponse$ = HTTP
    .select('GET_EXCELREAL')
    .flatten()
    .map((
      data
    ) => {
      const type = data.headers['content-type']
      const blob = new Blob([data.body], { type: type, encoding: 'UTF-8' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      const time = new Date()
      link.download = 'Costo-Real-' + time.toLocaleString().replace(' ', '-') + '.xlsx'
      link.click()
      if (data) {
        return Actions.getExcelSuccess()
      } else {
        return RoutesActions.handleError('went wrong')
      }
    })

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

const getBudgetPeriod = ({ ACTION, STATE, HTTP, STORAGE }) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})
  const search$ = STATE.map(state => ({
    search: state.projects.searchText,
    searchType: state.projects.searchInternalType
  }))

  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.GET_BUDGET_PROGRESS_BY_PERIOD))
    .compose(combine(userToken$, org$, search$))
    .map(([{ id, search, searchType, period }, token, org, { search: iSearch, searchType: iSearchType }]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/show-chunked-progress/${id}/${period}`,
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'json',
      method: 'POST',
      category: 'GET_BUDGET',
      send: ((iSearch || search) && (iSearchType || searchType)) && {
        search: (iSearch || search),
        ...((iSearch || search) ? { search_type: (iSearchType || searchType) } : {})
      }
    }))

  const getResponse$ = HTTP
    .select('GET_BUDGET')
    .map(handleHTTPError)
    .flatten()
    .map(({ body }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.getBudgetSuccess(body.budget_items))

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}
const getBudgetSecondPeriod = ({ ACTION, STATE, HTTP, STORAGE }) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})
  const search$ = STATE.map(state => ({
    search: state.projects.searchText,
    searchType: state.projects.searchInternalType
  }))

  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.GET_BUDGET_SECOND_PROGRESS_BY_PERIOD))
    .compose(combine(userToken$, org$, search$))
    .map(([{ id, search, searchType, period }, token, org, { search: iSearch, searchType: iSearchType }]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/show-chunked-progress/${id}/${period}`,
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'json',
      method: 'POST',
      category: 'GET_SECOND_BUDGET',
      send: ((iSearch || search) && (iSearchType || searchType)) && {
        search: (iSearch || search),
        ...((iSearch || search) ? { search_type: (iSearchType || searchType) } : {})
      }
    }))

  const getResponse$ = HTTP
    .select('GET_SECOND_BUDGET')
    .map(handleHTTPError)
    .flatten()
    .map(({ body }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.getBudgetSecondSuccess(body))

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

const mapAccount = ({ ACTION, STATE, HTTP, STORAGE }) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})

  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.MAP_ACCOUNT_COST))
    .compose(combine(userToken$, org$))
    .map(([{ payload, budget }, token, org]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/attach-concepts/${budget}`,
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'json',
      method: 'POST',
      category: 'ATTACH_ACCOUNT',
      send: {
        account_cost_map: payload
      }
    }))

  const getResponse$ = HTTP
    .select('ATTACH_ACCOUNT')
    .map(handleHTTPError)
    .flatten()
    .map(({ body }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.mapAccountCostSuccess())

  const handleSuccess$ = ACTION
    .filter(ifTypeIs(Types.MAP_ACCOUNT_COST_SUCCESS))

  const sendAlert$ = handleSuccess$
    .mapTo(NotificationsActions.requestNotification('Presupuesto mapeado correctamente', 'info'))

  const goToProjects$ = handleSuccess$
    .mapTo(push(`/projects/${location.pathname.split('/')[2]}`))

  return {
    HTTP: handleRequest$,
    ACTION: xs.merge(getResponse$, sendAlert$)
  }
}

const getChildBudget = ({ ACTION, STATE, HTTP, STORAGE }) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})
  const search$ = STATE.map(state => ({
    search: state.projects.searchText,
    searchType: state.projects.searchInternalType
  }))

  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.GET_BUDGET_CHILD))
    .compose(combine(userToken$, org$, search$))
    .map(([{ id, requestType, search, searchType }, token, org, { search: iSearch, searchType: iSearchType }]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/show-chunked/${location.pathname.split('/')[4]}`,
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'json',
      method: 'POST',
      category: 'GET_BUDGET_CHILD',
      send: {
        type: requestType,
        id,
        ...((iSearch || search) ? { search: (iSearch || search) } : {}),
        ...((iSearchType || searchType) && (iSearch || search) ? { search_type: (iSearchType || searchType) } : {})
      }
    }))

  const body = bod => bod || {}

  const getLists = ({ budget_items, concepts, supplies }) => {
    const hasItems = budget_items && budget_items.length
    const hasConcepts = concepts && concepts.length
    const hasSupplies = supplies && supplies.length

    return hasItems ? budget_items : hasConcepts ? concepts : hasSupplies ? supplies : []
  }

  const getResponse$ = HTTP
    .select('GET_BUDGET_CHILD')
    .map(handleHTTPError)
    .flatten()
    .map(({ ok, ...res }) => !ok || body(res.body).error
      ? RoutesActions.handleError(body(res.body).error || 'Un error ha ocurrido')
      : Actions.getBudgetChildSuccess(getLists(body(res.body))))

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

const getChildBudgetSecond = ({ ACTION, STATE, HTTP, STORAGE }) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})
  const search$ = STATE.map(state => ({
    search: state.projects.searchText,
    searchType: state.projects.searchInternalType
  }))

  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.GET_BUDGET_CHILD_SECOND))
    .compose(combine(userToken$, org$, search$))
    .map(([{ id, requestType, search, searchType, budgetId }, token, org, { search: iSearch, searchType: iSearchType }]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/show-chunked/${budgetId}`,
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'json',
      method: 'POST',
      category: 'GET_BUDGET_CHILD_SECOND',
      send: {
        type: requestType,
        id,
        ...((iSearch || search) ? { search: (iSearch || search) } : {}),
        ...((iSearchType || searchType) && (iSearch || search) ? { search_type: (iSearchType || searchType) } : {})
      }
    }))

  const body = bod => bod || {}

  const getLists = ({ budget_items, concepts, supplies }) => {
    const hasItems = budget_items && budget_items.length
    const hasConcepts = concepts && concepts.length
    const hasSupplies = supplies && supplies.length

    return hasItems ? budget_items : hasConcepts ? concepts : hasSupplies ? supplies : []
  }

  const getResponse$ = HTTP
    .select('GET_BUDGET_CHILD_SECOND')
    .map(handleHTTPError)
    .flatten()
    .map(({ ok, ...res }) => !ok || body(res.body).error
      ? RoutesActions.handleError(body(res.body).error || 'Un error ha ocurrido')
      : Actions.getBudgetChildSecondSuccess(getLists(body(res.body))))

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

const getChildProgressBudget = ({ ACTION, STATE, HTTP, STORAGE }) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})
  const search$ = STATE.map(state => ({
    search: state.projects.searchText,
    searchType: state.projects.searchInternalType
  }))

  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.GET_BUDGET_PROGRESS_CHILD))
    .compose(combine(userToken$, org$, search$))
    .map(([{ id, requestType, period, search, searchType }, token, org, { search: iSearch, searchType: iSearchType }]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/show-chunked-progress/${location.pathname.split('/')[4]}/${period}`,
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'json',
      method: 'POST',
      category: 'GET_BUDGET_PROGRESS_CHILD',
      send: {
        type: requestType,
        id,
        ...((iSearch || search) ? { search: (iSearch || search) } : {}),
        ...((iSearchType || searchType) && (iSearch || search) ? { search_type: (iSearchType || searchType) } : {})
      }
    }))

  const body = bod => bod || {}

  const getLists = ({ budget_items, concepts, supplies }) => {
    const hasItems = budget_items && budget_items.length
    const hasConcepts = concepts && concepts.length
    const hasSupplies = supplies && supplies.length

    return hasItems ? budget_items : hasConcepts ? concepts : hasSupplies ? supplies : []
  }

  const getResponse$ = HTTP
    .select('GET_BUDGET_PROGRESS_CHILD')
    .map(handleHTTPError)
    .flatten()
    .map(({ ok, ...res }) => !ok || body(res.body).error
      ? RoutesActions.handleError(body(res.body).error || 'Un error ha ocurrido')
      : Actions.getBudgetChildProgressSuccess(getLists(body(res.body))))

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

const getChildSecondProgressBudget = ({ ACTION, STATE, HTTP, STORAGE }) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})
  const search$ = STATE.map(state => ({
    search: state.projects.searchText,
    searchType: state.projects.searchInternalType
  }))

  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.GET_BUDGET_SECOND_PROGRESS_CHILD))
    .compose(combine(userToken$, org$, search$))
    .map(([{ id, requestType, period, search, searchType, budgetId }, token, org, { search: iSearch, searchType: iSearchType }]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/show-chunked-progress/${budgetId}/${period}`,
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'json',
      method: 'POST',
      category: 'GET_BUDGET_SECOND_PROGRESS_CHILD',
      send: {
        type: requestType,
        id,
        ...((iSearch || search) ? { search: (iSearch || search) } : {}),
        ...((iSearchType || searchType) && (iSearch || search) ? { search_type: (iSearchType || searchType) } : {})
      }
    }))

  const body = bod => bod || {}

  const getLists = ({ budget_items, concepts, supplies }) => {
    const hasItems = budget_items && budget_items.length
    const hasConcepts = concepts && concepts.length
    const hasSupplies = supplies && supplies.length

    return hasItems ? budget_items : hasConcepts ? concepts : hasSupplies ? supplies : []
  }

  const getResponse$ = HTTP
    .select('GET_BUDGET_SECOND_PROGRESS_CHILD')
    .map(handleHTTPError)
    .flatten()
    .map(({ ok, ...res }) => !ok || body(res.body).error
      ? RoutesActions.handleError(body(res.body).error || 'Un error ha ocurrido')
      : Actions.getBudgetChildSecondSuccess(getLists(body(res.body))))

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

const editAccountExt = ({ ACTION, STATE, HTTP, STORAGE }) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})

  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.EDIT_ACCOUNT_EXT))
    .compose(combine(userToken$, org$))
    .map(([{ parent, pId, id, payload }, token, org]) => ({
      // url: `${REACT_APP_API}/organization/${org.id || 1}/proyectos/${id}/charge_type`,
      url: `${REACT_APP_API}/organization/${org.id}/account-costs${(parent || (!parent && pId)) ? '' : `/${payload.id}`}`,
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'json',
      method: 'PUT',
      category: 'SEND_ACCOUNT',
      send: {
        ext_system_id: payload.ext_system_id,
        ext_desc: payload.ext_desc
      }
    }))

  const getResponse$ = HTTP
    .select('SEND_ACCOUNT')
    .map(handleHTTPError)
    .flatten()
    .map(({ body }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.accountSuccess())

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}
const sendAccount = ({ ACTION, HTTP, STATE, STORAGE }) => {
  const userToken$ = getToken(STORAGE)
  const projectId$ = STATE.map(state => state.projects.projectSelected)
  const org$ = STATE.map(state => state.auth.orgSelected || {})

  const add$ = ACTION
    .filter(ifTypeIs(Types.ADD_ACCOUNT))

  const edit$ = ACTION
    .filter(ifTypeIs(Types.EDIT_ACCOUNT))

  const delete$ = ACTION
    .filter(ifTypeIs(Types.DELETE_ACCOUNT))

  const handleAction$ = xs.merge(add$, edit$, delete$)
    .compose(combine(userToken$, org$))
    .map(([ { parent, id, name, deleteId, pId }, token, org ]) => ({
      url: `${REACT_APP_API}/organization/${org.id}/account-costs${((parent && !deleteId) || (!parent && pId)) ? '' : `/${id || deleteId}`}`,
      headers: { Authorization: `Bearer ${token}` },
      method: (parent || (!parent && pId)) ? 'POST' : deleteId ? 'DELETE' : 'PUT',
      send: !deleteId
        ? ({
          ...(parent ? { parent_id: parent } : {}),
          ...((!parent && pId) ? { project_id: pId } : {}),
          description: name
        }) : null,
      category: 'SEND_ACCOUNT',
      responseType: 'json'
    }))

  const getResponse$ = HTTP
    .select('SEND_ACCOUNT')
    .map(handleHTTPError)
    .flatten()
    .map(({ body }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.accountSuccess())

  const updateOnSuccess$ = ACTION
    .filter(ifTypeIs(Types.ACCOUNT_SUCCESS))
    .compose(combine(userToken$, org$, projectId$))
    .map(([, token, org, pId]) => ({
      url: `${REACT_APP_API}/organization/${org.id}/account-costs/${pId}`,
      headers: { Authorization: `Bearer ${token}` },
      category: 'GET_ACCOUNT',
      responseType: 'json'
    }))

  const getUpdatedAccount$ = HTTP
    .select('GET_ACCOUNT')
    .map(handleHTTPError)
    .flatten()
    .map(({ body }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.updateAccount(body.account_cost_structure))

  const messageOnUpdate$ = ACTION
    .filter(ifTypeIs(Types.UPDATE_ACCOUNT))
    .mapTo(NotificationsActions.requestNotification('Operacion exitosa', 'info'))

  return {
    HTTP: xs.merge(handleAction$, updateOnSuccess$),
    ACTION: xs.merge(getResponse$, getUpdatedAccount$, messageOnUpdate$)
  }
}

const startSearching = ({ ACTION }) => {
  const handleAction$ = ACTION
    .filter(ifTypeIs(Types.SEARCH_SOMETHING))
    .compose(debounce(300))
    .map(({ id, search, searchType }) => Actions.getBudgetById(id, search, searchType))

  return {
    ACTION: handleAction$
  }
}

/*******************************************************/
/*******************************************************/
/*******************************************************/
/** ************** Daniel Guadalupe *********************/
/*******************************************************/
/*******************************************************/

/** ******** Consumo de un servicio de pruebas********* */
const getChargeTypesCicles = ({ ACTION, STATE, HTTP, STORAGE }) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})

  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.GET_CHARGE_TYPES_OPERATION))
    .compose(combine(userToken$, org$))
    .map(([{ id }, token, org]) => ({
      // url: `${REACT_APP_API}/organization/${org.id || 1}/proyectos/${id}/charge_type`,
      url: `${REACT_APP_API}/organization/${org.id || 1}/charge_type`,
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'json',
      method: 'GET',
      category: 'GET_CHARGE_TYPES'
    }))

  const getResponse$ = HTTP
    .select('GET_CHARGE_TYPES')
    .map(handleHTTPError)
    .flatten()
    .map(({ body }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.setChargeTypesOperation(body.charge_type))

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}
const updateArrayChargeType = ({ ACTION, STATE, HTTP, STORAGE }) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})
  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.UPDATE_ARRAY_CHARGE_TYPES_OPERATION))
    .compose(combine(userToken$, org$))
    .map(([{ payload, id }, token, org]) => ({
      url: `${REACT_APP_API}/organization/${org.id || 1}/charge_types`,
      headers: { Authorization: `Bearer ${token}` },
      method: 'PUT',
      category: 'UPDATE_ARRAY_CHARGE_TYPE',
      send: {
        data: payload,
        proyect_id: id
      },
      responseType: 'json'
    }))

  const getResponse$ = HTTP
    .select('UPDATE_ARRAY_CHARGE_TYPE')
    .map(handleHTTPError)
    .flatten()
    .map(({ body }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.creationSuccess())

  const onSuccess$ = ACTION
    .filter(ifTypeIs(Types.CREATION_SUCCESS))

  const sendNotification$ = onSuccess$
    .mapTo(NotificationsActions.requestNotification('Tipos de cargo actualizados', 'info'))

  const updateOrgs$ = onSuccess$
    .mapTo(Actions.fetchProjects())

  return {
    HTTP: handleRequest$,
    ACTION: xs.merge(getResponse$, sendNotification$, updateOrgs$)
  }
}

const getPeriodsFromBudget = ({ ACTION, STATE, HTTP, STORAGE }) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})

  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.GET_PERIODS_OF_BUDGET))
    .compose(combine(userToken$, org$))
    .map(([{ budgetId }, token, org]) => ({
      url: `${REACT_APP_API}/budgets/${budgetId || 1}/periods`,
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'json',
      method: 'GET',
      category: 'GET_PERIOD_OF_BUDGET'
    }))

  const getResponse$ = HTTP
    .select('GET_PERIOD_OF_BUDGET')
    .map(handleHTTPError)
    .flatten()
    .map(({ body }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.getPeriodsOfBudgetSuccess(body.periods.sort((a, b) => a.created_at < b.create_at)))

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

const togglePeriod = ({ ACTION, STATE, HTTP, STORAGE }) => {
  const userToken$ = getToken(STORAGE)

  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.TOGGLE_PERIOD))
    .compose(combine(userToken$))
    .map(([{ budgetId, periodId }, token]) => ({
      url: `${REACT_APP_API}/budgets/${budgetId || 1}/periods/${periodId}/toggle`,
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'json',
      method: 'PUT',
      category: 'TOGGLE_PERIOD'
    }))

  const getResponse$ = HTTP
    .select('TOGGLE_PERIOD')
    .map(handleHTTPError)
    .flatten()
    .map(({ body }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.getPeriodsOfBudget(body.budget_id))

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

const createNewPeriod = ({ ACTION, STATE, HTTP, STORAGE }) => {
  const userToken$ = getToken(STORAGE)

  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.CREATE_PERIOD))
    .compose(combine(userToken$))
    .map(([{ projectId, budgetId }, token]) => ({
      url: `${REACT_APP_API}/budgets/${budgetId || 1}/periods`,
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'json',
      method: 'POST',
      category: 'CREATE_NEW_PERIOD',
      send: {
        project_id: projectId
      }
    }))

  const getResponse$ = HTTP
    .select('CREATE_NEW_PERIOD')
    .map(handleHTTPError)
    .flatten()
    .map(({ body }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.createPeriodSuccess(body.periods))

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

const deletePeriod = ({ ACTION, STATE, HTTP, STORAGE }) => {
  const userToken$ = getToken(STORAGE)

  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.DELETE_PERIOD))
    .compose(combine(userToken$))
    .map(([{ budgetId, periodId }, token]) => ({
      url: `${REACT_APP_API}/budgets/${budgetId || 1}/periods/${periodId}`,
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'json',
      method: 'DELETE',
      category: 'DELETE_PERIOD'
    }))

  const getResponse$ = HTTP
    .select('DELETE_PERIOD')
    .map(handleHTTPError)
    .flatten()
    .map(({ body }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.getPeriodsOfBudget(body.budget_id))

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

const updateProgress = ({ ACTION, STATE, HTTP, STORAGE }) => {
  const userToken$ = getToken(STORAGE)

  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.UPDATE_PROGRESS))
    .compose(combine(userToken$))
    .map(([{ conceptId, periodId, data }, token]) => ({
      url: `${REACT_APP_API}/periods/${periodId}/concepts/${conceptId}/progress`,
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'json',
      method: 'PUT',
      category: 'UPDATE_PROGRESS',
      send: data
    }))

  const getResponse$ = HTTP
    .select('UPDATE_PROGRESS')
    .map(handleHTTPError)
    .flatten()
    .map(({ body }) => {
      if (!body.error) {
        return Actions.updateProgressDone()
      } else {
        return RoutesActions.handleError(body.message)
      }
    })

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

const updateProgressReal = ({ ACTION, STATE, HTTP, STORAGE }) => {
  const userToken$ = getToken(STORAGE)

  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.UPDATE_PROGRESS_REAL))
    .compose(combine(userToken$))
    .map(([{ supplyCode, periodId, data }, token]) => ({
      url: `${REACT_APP_API}/periods/${periodId}/supply/${typeof supplyCode === 'string' && supplyCode.includes('%') ? supplyCode.replace('%', '') : supplyCode}/progressReal`,
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'json',
      method: 'PUT',
      category: 'UPDATE_PROGRESS_REAL',
      send: data
    }))

  const getResponse$ = HTTP
    .select('UPDATE_PROGRESS_REAL')
    .map(handleHTTPError)
    .flatten()
    .map(({ body }) => {
      if (!body.error) {
        return Actions.updateProgressRealSuccess()
      } else {
        return RoutesActions.handleError(body.error)
      }
    })

  const onSuccess$ = ACTION
    .filter(ifTypeIs(Types.UPDATE_PROGRESS_REAL_SUCCESS))

  const sendNotification$ = onSuccess$
    .mapTo(NotificationsActions.requestNotification('Progreso de costo real guardado', 'info'))

  return {
    HTTP: handleRequest$,
    ACTION: xs.merge(getResponse$, sendNotification$)
  }
}

const closeProgress = ({ ACTION, STATE, HTTP, STORAGE }) => {
  const userToken$ = getToken(STORAGE)

  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.CLOSE_PROGRESS))
    .compose(combine(userToken$))
    .map(([{ budgetId, periodId }, token]) => ({
      url: `${REACT_APP_API}/budgets/${budgetId}/periods/${periodId}/close`,
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'json',
      method: 'PUT',
      category: 'CLOSE_PROGRESS'
    }))

  const getResponse$ = HTTP
    .select('CLOSE_PROGRESS')
    .map(handleHTTPError)
    .flatten()
    .map(({ body }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.getPeriod(body.period.budget_id, body.period.id))

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

const openProgress = ({ ACTION, STATE, HTTP, STORAGE }) => {
  const userToken$ = getToken(STORAGE)
  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.OPEN_PROGRESS))
    .compose(combine(userToken$))
    .map(([{ budgetId, periodId }, token]) => ({
      url: `${REACT_APP_API}/budgets/${budgetId}/periods/${periodId}/open`,
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'json',
      method: 'PUT',
      category: 'OPEN_PROGRESS'
    }))

  const getResponse$ = HTTP
    .select('OPEN_PROGRESS')
    .map(handleHTTPError)
    .flatten()
    .map(({ body }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.getPeriod(body.period.budget_id, body.period.id))

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

const closeProgressReal = ({ ACTION, STATE, HTTP, STORAGE }) => {
  const userToken$ = getToken(STORAGE)

  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.CLOSE_PROGRESS_REAL))
    .compose(combine(userToken$))
    .map(([{ budgetId, periodId }, token]) => ({
      url: `${REACT_APP_API}/budgets/${budgetId}/periods/${periodId}/closeReal`,
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'json',
      method: 'PUT',
      category: 'CLOSE_PROGRESSREAL'
    }))

  const getResponse$ = HTTP
    .select('CLOSE_PROGRESSREAL')
    .map(handleHTTPError)
    .flatten()
    .map(({ body }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.getPeriod(body.period.budget_id, body.period.id))

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

const openProgressReal = ({ ACTION, STATE, HTTP, STORAGE }) => {
  const userToken$ = getToken(STORAGE)
  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.OPEN_PROGRESS_REAL))
    .compose(combine(userToken$))
    .map(([{ budgetId, periodId }, token]) => ({
      url: `${REACT_APP_API}/budgets/${budgetId}/periods/${periodId}/openReal`,
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'json',
      method: 'PUT',
      category: 'OPEN_PROGRESSREAL'
    }))

  const getResponse$ = HTTP
    .select('OPEN_PROGRESSREAL')
    .map(handleHTTPError)
    .flatten()
    .map(({ body }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.getPeriod(body.period.budget_id, body.period.id))

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

const updatePeriod = ({ ACTION, STATE, HTTP, STORAGE }) => {
  const userToken$ = getToken(STORAGE)

  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.UPDATE_PERIOD))
    .compose(combine(userToken$))
    .map(([{ budgetId, periodId, endDate }, token]) => ({
      url: `${REACT_APP_API}/budgets/${budgetId || 1}/periods/${periodId}`,
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'json',
      method: 'PUT',
      category: 'SEND_UPDATE_PERIOD',
      send: {
        end_date: endDate
      }
    }))

  const getResponse$ = HTTP
    .select('SEND_UPDATE_PERIOD')
    .map(handleHTTPError)
    .flatten()
    .map(({ body }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.getPeriodsOfBudget(body.budget_id))

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

const getPeriod = ({ ACTION, STATE, HTTP, STORAGE }) => {
  const userToken$ = getToken(STORAGE)
  const org$ = STATE.map(state => state.auth.orgSelected || {})

  const handleRequest$ = ACTION
    .filter(ifTypeIs(Types.GET_PERIOD))
    .compose(combine(userToken$, org$))
    .map(([{ budgetId, periodId }, token, org]) => ({
      url: `${REACT_APP_API}/budgets/${budgetId || 1}/periods/${periodId}`,
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'json',
      method: 'GET',
      category: 'GET_PERIOD'
    }))

  const getResponse$ = HTTP
    .select('GET_PERIOD')
    .map(handleHTTPError)
    .flatten()
    .map(({ body }) => body.error
      ? RoutesActions.handleError(body.error)
      : Actions.getPeriodSuccess(body))

  return {
    HTTP: handleRequest$,
    ACTION: getResponse$
  }
}

export default combineCycles(
  ciclesUnitFactors,
  ciclesBudgetView,

  fetchProjects,
  mapAccount,
  createProject,
  fetchProject,
  deleteProject,
  getBudget,
  getBudgetSecond,
  getBudgetPeriod,
  getBudgetSecondPeriod,
  getBudgetProgress,
  sendAccount,
  getChildBudget,
  getChildBudgetSecond,
  getChildProgressBudget,
  getChildSecondProgressBudget,
  startSearching,
  getChargeTypesCicles,
  updateArrayChargeType,
  getExcelBudget,
  getPeriodsFromBudget,
  togglePeriod,
  getBudgetBaseExcelCompare,
  getBudgetBaseExcel,
  getCostExcel,
  getCostExcelCompare,
  getCostExcelCompareType,
  getCostExcelCompareUnitCost,
  createNewPeriod,
  deletePeriod,
  updatePeriod,
  updateProgressReal,
  getPeriod,
  getExcelReal,
  updateProgress,
  openProgress,
  closeProgress,
  openProgressReal,
  closeProgressReal,
  deleteBudget,
  getGeneralHistory,
  editAccountExt,
  getExcelAccountExt,
  updateProgressExcel,
  updateProgressRealExcel
)
