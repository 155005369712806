import React, { Component } from 'react'
import { FormNext, FormPrevious } from 'grommet-icons'
import posed from 'react-pose'
import { Box } from 'grommet'
import styled from 'styled-components'

const transition = {
  ease: 'circOut',
  duration: 200
}

const SidebarPose = posed.div({
  open: { x: '0%', marginRight: 0, transition },
  closed: { x: '-70%', marginRight: `-${265 * 0.67}px`, transition }
})

const SidebarContainer = styled(SidebarPose)`
  z-index: 0;
  height: calc(100vh - 64px);
  width: 265px;
  background-color: ${({ color }) => color || '#2D3B46'};
`

const SidebarContent = styled(Box)`
  height: calc(100% - 50px);
  width: 100%;
  overflow-y: auto;
`

class Sidebar extends Component {
  state = {
    opened: false
  }

  toggleSidebar = () => {
    this.setState(prevState => ({
      opened: !prevState.opened
    }))
  }

  render = () => {
    const { children, color, ...props } = this.props
    const { opened } = this.state

    const togglerProps = {
      onClick: this.toggleSidebar,
      color: 'rgba(255, 255, 255, 0.7)',
      style: { cursor: 'pointer' }
    }

    return (
      <SidebarContainer pose={opened ? 'open' : 'closed'} {...props}>
        <SidebarContent>{children}</SidebarContent>
        <Box
          align='end'
          pad='small'
          height='50px'
          background={color}
        >
          {opened
            ? (
              <FormPrevious {...togglerProps} />
            ) : (
              <FormNext {...togglerProps} />
            )}
        </Box>
      </SidebarContainer>
    )
  }
}

export default Sidebar
