import styled from 'styled-components'

const Button = styled.button`
  border-radius: 5px;
  padding: ${({ icon }) => icon ? '5px' : '5px'};
  width: ${({ icon }) => icon ? '40px' : 'auto'};
  height: ${({ icon }) => icon ? '40px' : 'auto'};
  min-height: ${({ icon }) => icon ? '40px' : 'auto'};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ disabled, color }) => disabled ? '#DDDDDD' : (color || '#EA1D23')};
  box-shadow: none;
  cursor: pointer;
  border: none;
  
  &:focus {
    box-shadow: none;
  }
  
  &:disabled > svg {
    fill: rgba(0, 0, 0, 0.25);
  }
`

export default Button
