import { createActions } from 'reduxsauce'

const { Creators, Types } = createActions({
  requestNotification: ['message', 'messageType'],
  hideNotification: ['id']
}, {
  prefix: 'notifications/'
})

export { Creators as Actions, Types }
