import { createReducer } from 'reduxsauce'
import { Types } from './operations'
import {RoutesTypes} from "../../routes/duck"

const INITIAL_STATE = {
  users: null,
  fetchingUsers: false
}

const updateUsers = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  users: payload,
  fetchingUsers: false
})

const fetchingUsers = (state = INITIAL_STATE) => ({
  ...state,
  fetchingUsers: true
})

const handleError = (state = INITIAL_STATE) => ({
  ...state,
  fetchingUsers: false
})

const HANDLERS = {
  [Types.FETCH_USERS]: fetchingUsers,
  [Types.UPDATE_USERS]: updateUsers,
  [Types.ERROR]: handleError,
  [Types.DELETE_USER]: fetchingUsers,
  [RoutesTypes.HANDLE_ERROR]: handleError
}

export default createReducer(INITIAL_STATE, HANDLERS)
