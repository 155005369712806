import React, { Component } from 'react'
import { Box, Heading, Text, Table, TableBody, TableHeader, TableRow, TableCell } from 'grommet'
import { Close, Edit, Trash } from 'grommet-icons'
import { InputGroupAddon, InputGroupText, Input as InputStrap } from 'reactstrap'
import { connect } from 'react-redux'
import { OrgsActions } from './duck'
import moment from 'moment'
import hexToRGBA from 'hex-to-rgba'

import Button from 'components/Button'
import Divider from 'components/Divider'
import withTheme from 'components/HOC/withTheme'
import InputField from 'components/Controlled/Input'
import OverlayLoading from 'components/OverlayLoading'

import SvgNuevo from 'icons/Nuevo'
import SvgGuardar from 'icons/Guardar'

import { formToArray, isEmail, optionalProperty } from 'helpers'
import DayPickerInput from 'components/Controlled/InputDate'
import SvgBasura from '../../icons/Basura'
import SvgLapiz from '../../icons/Lapiz'
import Modal from '../../components/Modal'

const state = state => ({
  fetchOrgs: state.orgs.fetchingOrgs,
  orgs: state.orgs.orgs,
  ready: state.auth.logged,
  errorModal: false,
  errorModalIndex: null,
  inputConfirm: ''

})

const dispatch = {
  getOrgs: OrgsActions.fetchOrgs,
  create: OrgsActions.requestCreation,
  delete: OrgsActions.deleteOrg
}

@withTheme
@connect(state, dispatch)
class Organizations extends Component {
  state = {
    form: {},
    creating: false,
    editing: false,
    selected: null
  }

  shouldComponentUpdate = nextProps => nextProps.ready

  updateField = field => e => {
    const event = e.nativeEvent

    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [field]: event.target.value
      }
    }))
  }

  updateDate = name => day => {
    const date = moment(day).format('DD/MMMM/YYYY')

    this.updateField(name)({ nativeEvent: { target: { value: date } } })
  }

  handleClose = () => {
    this.setState(prevState => ({
      creating: !prevState.creating
    }))
  }

  closeEdit = () => {
    this.setState({
      editing: false,
      creating: false,
      selected: null,
      form: {}
    })
  }

  updateLimit = target => e => {
    const event = e.target

    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [target]: event.checked
      }
    }))
  }

  sendCreation = e => {
    const { form, selected } = this.state
    const values = formToArray(e.target)

    this.props.create({
      name: values.name,
      ...(values.adminEmail ? { admin_email: values.adminEmail } : {}),
      allowed_projects: form.limitless ? -1 : values.allowedProjects,
      allowed_budgets: form.limitlessBudgets ? -1 : values.allowedBudgets,
      duration_days: form.limitlessDays ? null : moment(form.durationDays, 'DD/MMMM/YYYY').diff(moment(), 'days')
    }, (selected || {}).id || null)

    this.closeEdit()
  }

  edit = userId => () => {
    const { orgs } = this.props

    this.setState(() => {
      const user = (orgs || []).filter(({ id }) => id === userId)[0] || {}

      return {
        editing: true,
        form: {
          name: user.name,
          user: user.users[0],
          limitlessDays: !user.expiration_date,
          limitless: user.allowed_projects === -1,
          limitlessBudgets: user.allowed_budgets === -1,
          allowedBudgets: user.allowed_budgets,
          allowedProjects: user.allowed_projects,
          durationDays: user.expiration_date ? moment(user.expiration_date, 'YYYY-MM-DD').format('DD/MMMM/YYYY') : null
        },
        selected: user,
        creating: false
      }
    })
  }

  validate = (values) => {
    const { editing, form } = this.state

    const nameErrors = {
      ...optionalProperty(values.name ? null : 'Rellena este campo', 'name')
    }

    const dateErrors = {
      ...optionalProperty(values.durationDays || form.limitlessDays ? null : 'Rellena este campo', 'durationDays')
    }

    const projectErrors = {
      ...optionalProperty((values.allowedProjects && values.allowedProjects > 0) || form.limitless
        ? null
        : 'Debe ser mayor a 0', 'allowedProjects'
      ),
      ...optionalProperty(values.allowedProjects || form.limitless
        ? null
        : 'Rellena este campo', 'allowedProjects'
      )
    }

    const budgetErrors = {
      ...optionalProperty((values.allowedBudgets && values.allowedBudgets > 0) || form.limitlessBudgets
        ? null
        : 'Debe ser mayor a 0', 'allowedBudgets'
      ),
      ...optionalProperty(values.allowedBudgets || form.limitlessBudgets
        ? null
        : 'Rellena este campo', 'allowedBudgets'
      )
    }

    const emailErrors = {
      ...optionalProperty(isEmail(values.adminEmail) || editing ? null : 'No es un email', 'adminEmail'),
      ...optionalProperty(values.adminEmail || editing ? null : 'Rellena este campo', 'adminEmail')
    }

    return {
      ...nameErrors,
      ...dateErrors,
      ...projectErrors,
      ...budgetErrors,
      ...emailErrors
    }
  }

  deleteData = (id) => {
    this.props.delete(id)
  }

  componentDidMount = () => { this.props.getOrgs() }

  componentWillUnmount = () => { this.closeEdit() }

  render = () => {
    const { fetchOrgs, orgs, theme } = this.props
    const { form, creating, editing } = this.state

    const formValidated = this.validate(form)

    return (
      <Box
        direction='row'
        flex
        align='start'
        justify='baseline'
        wrap
        fill
      >
        <Box
          pad='medium'
          background='white'
          round='xsmall'
          responsive
          fill='vertical'
          style={{ width: creating || editing ? '50%' : '100%', position: 'relative', overflowY: 'auto' }}
        >
          <OverlayLoading overlayColor={theme.primary} loaderColor={theme.font} pose={fetchOrgs ? 'in' : 'out'} />
          <Box
            direction='row'
            align='center'
            justify='between'
          >
            <Heading
              margin={{ vertical: 'small' }}
              level={3}
              color='black'
            >
              Organizaciones creadas
            </Heading>
          </Box>
          <Divider color='black' />
          <Box
            margin={{ vertical: 'medium' }}
            flex='false'
          >
            <Button
              disabled={editing || creating}
              icon
              color={theme.primary}
              onClick={this.handleClose}
            >
              <SvgNuevo fill={theme.font} width={80} />
            </Button>
          </Box>
          <div style={{ overflowY: 'auto' }}>
            {orgs.length === 0
              ? (
                <Text>No hay organizaciones</Text>
              ) : (
                <Table style={{ width: '100%' }} caption='Organizaciones'>
                  <TableHeader style={{ backgroundColor: theme.primary }}>
                    <TableRow>
                      <TableCell style={{ margin: '10px 0' }} scope='col'>
                        <Text color={theme.font} weight='bold'>Nombre</Text>
                      </TableCell>
                      <TableCell style={{ margin: '10px 0' }} scope='col'>
                        <Text color={theme.font} weight='bold'>Fecha Vencimiento</Text>
                      </TableCell>
                      <TableCell style={{ margin: '10px 0' }} scope='col'>
                        <Text color={theme.font} weight='bold'>Proyectos Restantes</Text>
                      </TableCell>
                      <TableCell style={{ margin: '10px 0' }} scope='col'>
                        <Text color={theme.font} weight='bold'>Proyectos permitidos</Text>
                      </TableCell>
                      <TableCell style={{ margin: '10px 0' }} scope='col'>
                        <Text color={theme.font} weight='bold'>Presupuestos permitidos</Text>
                      </TableCell>
                      <TableCell style={{ margin: '10px 0' }} scope='col'>
                        <Text color={theme.font} weight='bold'>Acciones</Text>
                      </TableCell>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {orgs.map(({ id, name, allowed_projects: allowedProjects, allowed_budgets: allowedBudgets, remaining_projects: remainingProjects, expiration_date: expiration }, i) => (
                      <TableRow key={i}>
                        <TableCell style={{ borderBottom: `1px solid ${hexToRGBA(theme.primary, 0.3)}` }} scope='row'>
                          <Text weight='normal'>{name}</Text>
                        </TableCell>
                        <TableCell style={{ borderBottom: `1px solid ${hexToRGBA(theme.primary, 0.3)}` }} scope='col'>
                          <Text weight='normal'>{expiration || 'Ilimitado'}</Text>
                        </TableCell>
                        <TableCell style={{ borderBottom: `1px solid ${hexToRGBA(theme.primary, 0.3)}` }} scope='col'>
                          <Text weight='normal'>{remainingProjects >= 0 ? remainingProjects : 'Ilimitado'}</Text>
                        </TableCell>
                        <TableCell style={{ borderBottom: `1px solid ${hexToRGBA(theme.primary, 0.3)}` }} scope='col'>
                          <Text weight='normal'>{allowedProjects >= 0 ? allowedProjects : 'Ilimitado'}</Text>
                        </TableCell>
                        <TableCell style={{ borderBottom: `1px solid ${hexToRGBA(theme.primary, 0.3)}` }} scope='col'>
                          <Text weight='normal'>{allowedBudgets >= 0 ? allowedBudgets : 'Ilimitado'}</Text>
                        </TableCell>
                        <TableCell style={{ borderBottom: `1px solid ${hexToRGBA(theme.primary, 0.3)}` }} scope='col'>
                          <Box
                            direction='row'
                            justify='end'
                            align='center'
                          >
                            <Button
                              icon
                              disabled={id === 1}
                              onClick={this.edit(id)}
                              color={theme.primary}
                            >
                              <SvgLapiz fill={theme.font} width={65} />
                            </Button>
                            <Box margin={{ left: 'small' }}>
                              <Button
                                disabled={id === 1}
                                icon
                                color={theme.primary}
                                onClick={(ev) => {
                                  ev.preventDefault()
                                  ev.stopPropagation()
                                  this.setState({ errorModal: true, errorModalIndex: i })
                                  return true
                                }}
                              >
                                <SvgBasura fill={theme.font} width={65} />
                              </Button>
                              <Modal onClose={() => this.setState({ errorModal: false, inputConfirm: '' })} show={this.state.errorModal && this.state.errorModalIndex === i} title='¡Advertencia!'>
                                <Box onClick={(e) => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                }} width='medium'>
                                  <p>
                                    Esta acción no se puede deshacer, se borraran todos los datos referentes a este organización(con todos sus presupuestos, usuarios, avances, etc...) y no se podrán recuperar, para confirmar escribe BORRAR
                                  </p>
                                  <input onChange={(e) => {
                                    this.setState({ inputConfirm: e.target.value })
                                  }} placeholder={'Introduce "BORRAR"'} />
                                  <Box
                                    direction='row'
                                    justify='end'
                                    align='center'
                                  >
                                    <Button
                                      type='submit'
                                      required
                                      style={{
                                        margin: '10px'
                                      }}
                                      onClick={() => {
                                        this.deleteData(id)
                                        this.setState({ errorModal: false, inputConfirm: '', errorModalIndex: null })
                                      }}
                                      disabled={this.state.inputConfirm !== 'BORRAR'}
                                      color={theme.primary}
                                    >
                                      Confirmar
                                    </Button>
                                  </Box>
                                </Box>
                              </Modal>
                            </Box>
                          </Box>
                        </TableCell>

                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
          </div>
        </Box>
        {(editing || creating) && (
          <Box
            pad='medium'
            background='white'
            round='xsmall'
            responsive
            fill={false}
            style={{ width: '49%', marginLeft: '1%', position: 'relative' }}
          >
            <Close onClick={this.closeEdit} color='black' style={{ position: 'absolute', top: 20, right: 20, cursor: 'pointer' }} />
            <Heading
              margin={{ vertical: 'none', bottom: 'small' }}
              color='black'
              level={3}
            >
              {creating ? 'Crear' : editing ? 'Editar' : 'Organizacion'}
            </Heading>
            <Divider color='black' />
            <form onSubmit={this.sendCreation}>
              <Box
                margin={{ vertical: 'small' }}
              >
                <InputField
                  value={form.name}
                  onChange={this.updateField('name')}
                  error={formValidated.name}
                  color={theme.primary}
                  type='text'
                  name='name'
                  label='Nombre'
                />
                <DayPickerInput
                  value={form.limitlessDays ? 'Ilimitado' : form.durationDays}
                  disabled={form.limitlessDays}
                  onDayChange={this.updateDate('durationDays')}
                  error={formValidated.durationDays}
                  label='Fecha límite'
                  placeholder='Selecciona una fecha'
                  name='durationDays'
                  color={theme.primary}
                  add={
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText style={{ backgroundColor: theme.primary, border: 'none' }}>
                        <InputStrap value={form.limitlessDays} checked={form.limitlessDays} onChange={this.updateLimit('limitlessDays')} addon type='checkbox' />
                      </InputGroupText>
                    </InputGroupAddon>
                  }
                  required
                />
                <InputField
                  value={form.limitless ? 'Ilimitado' : form.allowedProjects}
                  error={formValidated.allowedProjects}
                  type={form.limitless ? 'text' : 'number'}
                  onChange={this.updateField('allowedProjects')}
                  disabled={form.limitless}
                  min={1}
                  name='allowedProjects'
                  color={theme.primary}
                  label='Proyectos permitidos'
                  add={
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText style={{ backgroundColor: theme.primary, border: 'none' }}>
                        <InputStrap value={form.limitless} checked={form.limitless} onChange={this.updateLimit('limitless')} addon type='checkbox' />
                      </InputGroupText>
                    </InputGroupAddon>
                  }
                />
                <InputField
                  value={form.limitlessBudgets ? 'Ilimitado' : form.allowedBudgets}
                  error={formValidated.allowedBudgets}
                  onChange={this.updateField('allowedBudgets')}
                  type={form.limitlessBudgets ? 'text' : 'number'}
                  style={{ margin: '5px' }}
                  disabled={form.limitlessBudgets}
                  min={1}
                  name='allowedBudgets'
                  color={theme.primary}
                  label='Presupuestos permitidos'
                  add={
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText style={{ backgroundColor: theme.primary, border: 'none' }}>
                        <InputStrap value={form.limitlessBudgets} checked={form.limitlessBudgets} onChange={this.updateLimit('limitlessBudgets')} addon type='checkbox' />
                      </InputGroupText>
                    </InputGroupAddon>
                  }
                />
                {
                  form.user && form.user.email &&
                  <>
                    <InputField
                      value={form.user.email}
                      onChange={this.updateField('name')}
                      color={theme.primary}
                      disabled
                      type='text'
                      name='name'
                      label='Correo administrador'
                    />
                    {
                      editing && form.user && form.user.email_verified_at
                        ? <p style={{ background: 'lightgreen', padding: '15px', textAlign: 'left' }}>Verificado</p>
                        : <p style={{ background: 'whitesmoke', padding: '15px', textAlign: 'left' }}>Sin verificar, contraseña: {form.user && form.user.temp_password }</p>
                    }
                  </>
                }
                {!editing && (
                  <>
                    <Box fill='horizontal' margin={{ vertical: 'small' }}>
                      <Divider style={{ margin: '0 10px' }} color={theme.primary} />
                    </Box>
                    <InputField
                      value={form.adminEmail}
                      onChange={this.updateField('adminEmail')}
                      error={formValidated.adminEmail}
                      type='email'
                      required
                      name='adminEmail'
                      label='Correo de Admin'
                      color={theme.primary}
                    />
                  </>
                )}
                <Button
                  type='submit'
                  icon
                  disabled={Object.keys(formValidated).length}
                  color={theme.primary}
                >
                  <SvgGuardar fill={theme.font} width={80} />
                </Button>
              </Box>
            </form>
          </Box>
        )}
      </Box>
    )
  }
}

export default Organizations
