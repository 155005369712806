import { createActions } from 'reduxsauce'

const { Creators, Types } = createActions({

  // AUTH ROUTE
  userLogged: null,
  userNotLogged: null,

  // INITIAL
  requestLogin: ['email', 'password', 'remember'],
  requestCreation: ['payload'],
  requestSuccess: ['token'],
  firstTimeLogin: ['token', 'reset', 'first'],
  clearLogin: null,
  unsetOrg: null,

  // ON ERROR
  loginError: ['error'],

  loginSuccess: ['token'],

  setAuthStatus: ['status'],

  updateUserData: ['payload'],

  getLocalToken: ['token'],

  requestLogout: null,
  logoutSuccess: null,

  tokenDestroyed: null,
  tokenExpired: null,

  setUserOrganizations: ['organizations'],
  setTemplate: ['templates'],
  selectOrganization: ['id'],
  organizationConfigured: ['payload'],
  changeOrganization: null,

  sendPasswordReset: ['email'],
  sendPasswordResetSuccess: null,
  resetStatus: null,
  fetchUser: null
}, {
  prefix: 'auth/'
})

export { Creators as Actions, Types }
