import React from 'react'

export default React.memo(({
  name,
  width = 30,
  height = 30,
  ...props
}) => {
  if (!name) return null

  const icon = require(`assets/icons/${name}.svg`)

  return (
    <object width={width} height={height} data={icon} type='image/svg+xml' {...props}>
      Your browser doesn't support SVG
    </object>
  )
})
