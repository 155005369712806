import React, { Component } from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import { Box, Text, Menu } from 'grommet'
import { Paint, Down, Sync, FormPrevious } from 'grommet-icons'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { AuthActions } from 'app/auth/duck'
import { UsersActions } from 'app/users/duck'

// Components
import Logo from 'components/Logo'
import Toolbar from 'components/Panel/Toolbar'
import Sidebar from 'components/Panel/Sidebar'
import UserData from 'components/UserData'
import HoverMenu from 'components/HoverMenu'
import Modal from 'components/Modal'
import EditProfileForm from 'forms/EditProfileForm'
import ButtonCore from 'components/Button'
import Input from 'components/Controlled/Input'

// Routes to handle
import routes from 'app/routes/panel'
import Permission from 'components/Permission'
import Customization from 'components/Panel/Customization'
import withTheme from 'components/HOC/withTheme'
import withAuthentication from 'components/HOC/withAuthentication'
import Welcome from 'components/Panel/Welcome'
import SvgGuardar from '../../icons/Guardar'

const dispatch = {
  navigate: push,
  logout: AuthActions.requestLogout,
  changeOrg: AuthActions.changeOrganization,
  editProfile: UsersActions.editUser
}

const state = state => ({
  user: state.auth.user,
  org: state.auth.orgSelected
})

@withAuthentication
@withTheme
@connect(state, dispatch)
class Panel extends Component {
  state = {
    path: null,
    showCustomization: false,
    showEdit: false
  }

  sendEdit = values => {
    this.setState({ showEdit: false }, () => {
      this.props.editProfile(values)
    })
  }

  customization = () => {
    this.setState(prevState => ({
      showEdit: !prevState.showEdit
    }))
  }

  handleCustomization = () => {
    this.setState(prevState => ({
      showCustomization: !prevState.showCustomization
    }))
  }

  goTo = path => () => { this.props.navigate(path) }

  render = () => {
    const { location: { pathname }, logout, user = {}, org, theme, changeOrg } = this.props
    const { showCustomization, showEdit } = this.state

    const routesToRender = routes.filter(({ shouldShow }) => shouldShow ? shouldShow(user) : true)
    const organization = org || {}
    return (
      <Box
        fill
        flex
        width='100vw'
        height='100vh'
      >
        <Customization show={showCustomization} onClose={this.handleCustomization} />
        <Modal show={showEdit} onClose={this.customization} title='EDITAR PERFIL'>
          <Box width='medium'>
            <EditProfileForm onSubmit={this.sendEdit}>
              {({ isValid, fields }) => (
                <>
                  <input type='hidden' value='prayer' />
                  <Input
                    {...fields.name.props}
                    error={fields.name.error}
                    placeholder={user.name}
                    valid={!fields.name.error}
                    label='Cambiar Nombre'
                    name='editName'
                    autoComplete='new-password'
                    color={theme.primary}
                  />
                  <Input
                    {...fields.password.props}
                    error={fields.password.error}
                    label='Contraseña nueva'
                    name='editPassword'
                    autoComplete='new-password'
                    type='password'
                    color={theme.primary}
                    password
                  />
                  <Input
                    {...fields.password_confirmation.props}
                    error={fields.password_confirmation.error}
                    label='Confirmar contraseña nueva'
                    color={theme.primary}
                    type='password'
                    password
                  />
                  <Box
                    direction='row'
                    justify='end'
                    align='center'
                  >
                    <ButtonCore
                      icon
                      type='submit'
                      required
                      disabled={(!fields.name.props.value && !fields.password.props.value)}
                      color={theme.primary}
                    >
                      <SvgGuardar fill={theme.font} width={65} />
                    </ButtonCore>
                  </Box>
                </>
              )}
            </EditProfileForm>
          </Box>
        </Modal>
        <Toolbar
          align='center'
          direction='row'
          elevation='xsmall'
          alignContent='center'
          color={theme.primary}
          justify='between'
        >
          <Box
            direction='row'
            align='center'
            pad={{ horizontal: 'medium' }}
          >
            <ButtonCore color={theme.primary} style={{ marginRight: '15px' }} onClick={() => { window.history.back() }}>
              <FormPrevious />
            </ButtonCore>
            <Logo src={theme.logo} onClick={this.goTo('/')} pointer size={5 / 12} />
            <Text
              weight='bold'
              size='large'
              color={organization.name ? theme.font : 'emsi'}
              margin={{ left: 'large' }}
            >
              {organization.name || 'EMSI Costos'}
            </Text>
          </Box>
          <Box
            direction='row'
            align='center'
            margin={{ horizontal: 'small' }}
          >
            <UserData>
              {({ name = '' }) => (
                <Menu
                  margin={{ horizontal: 'small' }}
                  icon={<Down size='small' style={{ width: 15, height: 15 }} color={organization.name ? theme.font : 'emsi'} />}
                  color={theme.primary}
                  label={<Text color={theme.font}>{name}</Text>}
                  items={[
                    { label: 'EDITAR PERFIL', onClick: this.customization },
                    { label: 'CERRAR SESIÓN', onClick: logout }
                  ]}
                />
              )}
            </UserData>
          </Box>
        </Toolbar>
        <Box
          direction='row'
          flex
        >
          <Sidebar color={theme.primary}>
            <Box margin={{ horizontal: 'small' }}>
              <Permission>
                {({ role }) => {
                  return routesToRender.filter(({ role: pathrole }) => !pathrole ? true : pathrole.some(tr => tr === role)).map(({ title, icon: Icon, path }, i) => (
                    <Box key={i} margin={{ vertical: 'medium', right: 'medium' }} style={{ cursor: 'pointer' }}>
                      <HoverMenu
                        fill
                        direction='row'
                        align='center'
                        justify='between'
                        alignContent='between'
                        onClick={this.goTo(path)}
                        margin={{ horizontal: 'small' }}
                      >
                        <Text color='#586975' weight='bold' size='medium'>{title}</Text>
                        <Icon width={26} height={26} style={{ fill: '#586975' }} />
                      </HoverMenu>
                    </Box>
                  ))
                }}
              </Permission>
              <Permission role='admin'>
                {({ role }) => (role === 'admin' || role === 'super') && (
                  <Box margin={{ vertical: 'medium', right: 'medium' }} style={{ cursor: 'pointer' }}>
                    <HoverMenu
                      fill
                      direction='row'
                      align='center'
                      justify='between'
                      alignContent='between'
                      margin={{ horizontal: 'small' }}
                      onClick={this.handleCustomization}
                    >
                      <Text color='#586975' weight='bold' size='medium'>Personalizar</Text>
                      <Paint size='medium' color='#586975' />
                    </HoverMenu>
                  </Box>
                )}
              </Permission>
              <Box margin={{ vertical: 'medium', right: 'medium' }} style={{ cursor: 'pointer' }}>
                <HoverMenu
                  fill
                  direction='row'
                  align='center'
                  onClick={changeOrg}
                  justify='between'
                  alignContent='between'
                  margin={{ horizontal: 'small' }}
                >
                  <Text color='#586975' weight='bold' size='medium'>Cambiar</Text>
                  <Sync size='medium' color='#586975' />
                </HoverMenu>
              </Box>
            </Box>
          </Sidebar>
          <Box
            fill='horizontal'
            pad='small'
            background='light-2'
            style={{ overflowY: 'auto' }}
          >
            <Permission>
              {({ role }) => {
                return (
                  <Switch>
                    {routes.filter(({ role: pathrole }) => !pathrole ? true : pathrole.some(tr => tr === role)).map(({ path, component }) => (
                      <Route key={path} path={path} component={component} />
                    ))}

                    {/* DEFAULT ROUTE: ORGANIZATIONS */}
                    <Route path='/' component={Welcome} />
                  </Switch>
                )
              }}
            </Permission>
          </Box>
        </Box>
      </Box>
    )
  }
}

export default withRouter(Panel)
