import { createReducer, resettableReducer } from 'reduxsauce'
import { Types } from './operations'
import {RoutesTypes} from 'app/routes/duck'

const INITIAL_STATE = {
  token: null,
  logged: false,
  loading: false,
  user: null,
  userOrganizations: null,
  settingOrg: false,
  orgSelected: { accessControl:{} },
  firstTime: false,
  emailSent: false,
  noName: false,
  ready: false,
  templates: null
}

const toggleLoading = loading => (state = INITIAL_STATE) => ({
  ...state,
  loading
})

const successLogin = (state = INITIAL_STATE, { token }) => ({
  ...state,
  loading: false,
  logged: true,
  ready: true,
  token
})

const tokenFetch = (state = INITIAL_STATE, { token }) => ({
  ...state,
  token
})

const firstTime = (state = INITIAL_STATE, { token, reset, first }) => ({
  ...state,
  token,
  loading: false,
  firstTime: !!first || !!reset,
  noName: !!reset
})

const updateUserData = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  user: payload
})

const fetchOrganizations = (state = INITIAL_STATE, { organizations }) => ({
  ...state,
  userOrganizations: organizations,
  settingOrg: false
})

const selectOrganization = (state = INITIAL_STATE, { payload }) => {
  return ({
  ...state,
  orgSelected: payload
})}

const deleteOrg = (state = INITIAL_STATE) => ({
  ...state,
  orgSelected: null
})

const resetSuccess = (state = INITIAL_STATE) => ({
  ...state,
  emailSent: true,
  loading: false
})

const reset = (state = INITIAL_STATE) => ({
  ...state,
  emailSent: false
})

const loadingOrg = (state = INITIAL_STATE) => ({
  ...state,
  settingOrg: true
})

const notLoading = (state = INITIAL_STATE) => ({
  ...state,
  loading: false
})

const logout = (state = INITIAL_STATE) => ({
  ...state,
  ...INITIAL_STATE,
  orgSelected: state.orgSelected
})

const checked = (state = INITIAL_STATE) => ({
  ...state,
  ready: true
})

const clear = (state = INITIAL_STATE) => ({
  ...state,
  emailSent: false
})

const unsetOrg = (state = INITIAL_STATE) => ({
  ...state,
  userOrganizations: null,
  settingOrg: false,
  orgSelected: null
})

const setTemplate = (state = INITIAL_STATE, { templates }) => ({
  ...state,
  templates
})

const HANDLERS = {
  // INITIAL
  [Types.REQUEST_LOGIN]: toggleLoading(true),
  [Types.REQUEST_SUCCESS]: tokenFetch,
  [Types.FIRST_TIME_LOGIN]: firstTime,
  [Types.REQUEST_CREATION]: toggleLoading(true),
  [Types.LOGIN_ERROR]: notLoading,
  [Types.LOGIN_SUCCESS]: successLogin,
  [Types.UPDATE_USER_DATA]: updateUserData,
  [Types.SET_USER_ORGANIZATIONS]: fetchOrganizations,
  [Types.ORGANIZATION_CONFIGURED]: selectOrganization,
  [Types.SEND_PASSWORD_RESET]: toggleLoading(true),
  [Types.SEND_PASSWORD_RESET_SUCCESS]: resetSuccess,
  [Types.RESET_STATUS]: reset,
  [Types.FETCH_USER]: loadingOrg,
  [Types.LOGOUT_SUCCESS]: logout,
  [Types.USER_NOT_LOGGED]: checked,
  [Types.CLEAR_LOGIN]: clear,
  [Types.UNSET_ORG]: unsetOrg,
  [Types.SET_TEMPLATE]: setTemplate
}

export default createReducer(INITIAL_STATE, HANDLERS)
