import { createReducer } from 'reduxsauce'
import { Types } from './operations'

const INITIAL_STATE = {
  alert: null,
  showing: false
}

const destroyAlert = (state = INITIAL_STATE) => ({
  ...state,
  alert: null,
  showing: false
})

const setAlert = (state = INITIAL_STATE, { title, message: text, redirect }) => ({
  ...state,
  alert: { title, text, redirect },
  showing: true
})

const HANDLERS = {
  [Types.HIDE_ALERT]: destroyAlert,
  [Types.REQUEST_ALERT]: setAlert
}

export default createReducer(INITIAL_STATE, HANDLERS)
