import { combineReducers } from 'redux'

// Reducers
import notifications from 'app/notifications/duck'
import alerts from 'app/alerts/duck'
import auth from 'app/auth/duck/reducers'
import routes from 'app/routes/duck'
import orgs from 'app/orgs/duck'
import users from 'app/users/duck'
import projects from 'app/projects/duck'

export default combineReducers({
  notifications,
  auth,
  routes,
  orgs,
  alerts,
  users,
  projects
})
