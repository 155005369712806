import React, { Component } from 'react'
import { connect } from 'react-redux'

export default (WrappedComponent) => {
  const state = state => ({
    organization: state.auth.orgSelected || {}
  })

  @connect(state)
  class withTheme extends Component {
    render = () => {
      const { organization, ...props } = this.props
      let auxString = organization.logo_url ? organization.logo_url: null
      const themeProps = {
        primary: organization.primary_color || '#EA1D23',
        font: organization.font_color || '#EA1D23',
        logo: auxString || organization.logo || null
      }

      return <WrappedComponent {...props} theme={themeProps} />
    }
  }

  return withTheme
}
