import posed from 'react-pose'

export default posed.div({
  enter: {
    opacity: 1,
    delay: 300,
    transition: { duration: 300 }
  },
  exit: {
    opacity: 0,
    transition: { duration: 150 }
  }
})
