import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { withRouter } from 'react-router-dom'

export default action => (WrappedComponent) => {
  const state = state => ({
    appReady: state.auth.ready,
    isLogged: state.auth.logged,
    token: state.auth.token
  })

  const dispatchers = {
    trigger: action
  }

  @withRouter
  @connect(state, dispatchers)
  class withFetching extends Component {
    state = {
      triggered: false
    }

    checkData = () => {
      const { isLogged, appReady, trigger, token } = this.props

      if (isLogged && appReady && token && !this.state.triggered) {
        this.setState({
          triggered: true
        }, () => { trigger() })
      }
    }

    componentDidUpdate = () => {
      this.checkData()
    }

    componentDidMount = () => {
      this.checkData()
    }

    render = () => {
      const { ...props } = this.props

      return <WrappedComponent {...props} />
    }
  }

  return withFetching
}
