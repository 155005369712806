import { Component } from 'react'
import { connect } from 'react-redux'
import { isAFunction } from 'helpers'

export const selectRole = (user, organization) => user
  ? (organization && user.is_super && organization.id === 1
    ? 'super' : organization && organization.is_admin === '1'
      ? 'admin' : 'user')
  : 'user'

const state = state => ({
  user: state.auth.user,
  organization: state.auth.orgSelected
})

@connect(state)
class Permission extends Component {
  render = () => {
    const { children, user, role = 'user', organization = {} } = this.props
    return isAFunction(children)
      ? children({ role: selectRole(user, organization) })
      : selectRole(user, organization) === role
        ? children
        : null
  }
}

export default Permission
