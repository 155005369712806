import React from "react";

const SvgBasura = props => (
  <svg viewBox="0 0 280.7 280.7"  {...props}>
    <path
      className="Basura_svg__st0"
      d="M220.7 39.7H176v-6.4c0-10.5-8.6-19.1-19.1-19.1h-25.5c-10.5 0-19.1 8.6-19.1 19.1v6.4H67.6c-10.5 0-19.1 8.6-19.1 19.1v12.8c0 8.3 5.3 15.4 12.8 18v160.5c0 10.5 8.6 19.1 19.1 19.1H208c10.5 0 19.1-8.6 19.1-19.1V89.6c7.4-2.6 12.8-9.7 12.8-18V58.8c-.1-10.5-8.7-19.1-19.2-19.1zM125 33.3c0-3.5 2.9-6.4 6.4-6.4h25.5c3.5 0 6.4 2.9 6.4 6.4v6.4H125v-6.4zm82.9 223.2H80.4c-3.5 0-6.4-2.9-6.4-6.4V90.7h140.3v159.4c0 3.6-2.9 6.4-6.4 6.4zM227 71.6c0 3.5-2.9 6.4-6.4 6.4h-153c-3.5 0-6.4-2.9-6.4-6.4V58.8c0-3.5 2.9-6.4 6.4-6.4h153.1c3.5 0 6.4 2.9 6.4 6.4v12.8z"
    />
  </svg>
);

export default SvgBasura;
