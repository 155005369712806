import { createActions } from 'reduxsauce'

const { Creators, Types } = createActions({
  fetchOrgs: null,
  updateOrgs: ['payload'],
  error: ['error'],
  requestOrgCustomization: ['payload'],
  requestOrgTemplate: ['payload'],
  customizationSuccess: ['org'],
  requestCreation: ['payload', 'id'],
  creationSuccess: null,
  deleteOrg: ['id'],
}, {
  prefix: 'orgs/'
})

export { Creators as Actions, Types }
