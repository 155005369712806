export const unit_factors_action = {
  UFUpdateLoading: ['status'],
  UFGetAccountCosts: ['params'],
  UFGetAccountCostsGraph: ['params'],
  UFGetAccountCostsSecond: ['params'],
  UFGetFactorsExcel: ['params'],
  updateFactorsExcel: ['params'],
  UFGetAccountCostsMagic: ['params'],
  UFUpdateExcelDone: ['params'],
  UFSetAccountCosts: ['params'],
  UFSetAccountCostsGraph: ['params'],
  UFSetAccountCostsSecond: ['params'],
  UFSetAccountCostsById: ['params'],
  UFGetAccountCostsById: ['params'],
  UFSetAccountCostEditing: ['params'],
  UFUpdateAccountCostEditing: ['params'],
  UFSaveAccountCost: ['params'],
  UFSaveAccountCostSuccess: ['params'],
  UFGetConceptByAccountCost: ['params'],
  UFGetBudgetItemByAccountCosts: ['params'],
  UFSetConceptsToEditing: ['params'],
  UFUpdateTextSearch: ['text'],
  UFUpdateSearchBy: ['value']
}
