/* eslint-disable no-useless-escape */
export const isAFunction = toCheck => Object.prototype.toString.call(toCheck) === '[object Function]'

export const areEquals = (one, two) => JSON.stringify(one) === JSON.stringify(two)

export const formToArray = form => [ ...form ]
  .filter(el => el.name)
  .reduce((form, el) => ({
    ...form,
    [el.name]: el.value
  }), {})

export const optionalProperty = (evaluate, name) => evaluate ? { [name]: evaluate } : {}

export const hasProperties = object => props => props === 'all'
  ? Object.keys(object).length !== 0
  : props.length
    ? props.map(name => object[name]).every(x => !!x)
    : false

const emailRE = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const isEmail = str => !!emailRE.test(str)
