import React from "react";

const SvgGuardar = props => (
  <svg viewBox="0 0 141.7 141.7" {...props}>
    <path
      className="guardar_svg__st0"
      d="M124.1 134.6H16.7c-5.6 0-10.1-4.5-10.1-10.1V17.1C6.6 11.5 11.1 7 16.7 7h107.4c5.6 0 10.1 4.5 10.1 10.1v107.4c0 5.6-4.5 10.1-10.1 10.1zM16.7 12.5c-2.5 0-4.6 2.1-4.6 4.6v107.4c0 2.5 2.1 4.6 4.6 4.6h107.4c2.5 0 4.6-2.1 4.6-4.6V17.1c0-2.5-2.1-4.6-4.6-4.6H16.7z"
    />
    <path
      className="guardar_svg__st0"
      d="M110.3 134.6H28.7c-5.6 0-10.1-4.5-10.1-10.1v-40c0-5.6 4.5-10.1 10.1-10.1h81.6c5.6 0 10.1 4.5 10.1 10.1v40c.1 5.6-4.5 10.1-10.1 10.1zM28.7 79.9c-2.5 0-4.6 2.1-4.6 4.6v40c0 2.5 2.1 4.6 4.6 4.6h81.6c2.5 0 4.6-2.1 4.6-4.6v-40c0-2.5-2.1-4.6-4.6-4.6H28.7zM112.4 67.2H50.1c-5.6 0-10.1-4.5-10.1-10.1v-40C39.9 11.5 44.5 7 50.1 7h62.3c5.6 0 10.1 4.5 10.1 10.1v40c0 5.6-4.5 10.1-10.1 10.1zM50.1 12.5c-2.5 0-4.6 2.1-4.6 4.6v40c0 2.5 2.1 4.6 4.6 4.6h62.3c2.5 0 4.6-2.1 4.6-4.6v-40c0-2.5-2.1-4.6-4.6-4.6H50.1z"
    />
    <path
      className="guardar_svg__st0"
      d="M59.4 9.7h10.2c1.2 0 2.3 1 2.3 2.3v50.2c0 1.2-1 2.3-2.3 2.3H59.4c-1.2 0-2.3-1-2.3-2.3V12c.1-1.3 1.1-2.3 2.3-2.3z"
    />
  </svg>
);

export default SvgGuardar;
