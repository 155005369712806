import { ReactComponent as Organization } from 'assets/icons/organizaciones.svg'
import { ReactComponent as User } from 'assets/icons/usuarios.svg'
import { ReactComponent as DocumentText } from 'assets/icons/proyectos.svg'
import { ReactComponent as Download } from 'assets/icons/neodata.svg'

// Route View
import Organizations from 'app/orgs/Organizations'
import Users from 'app/users/Users'
import Projects from 'app/projects/Container'
import NeoData from 'app/neodata/NeoData'

export default [
  {
    title: 'Organizaciones',
    path: '/orgs',
    icon: Organization,
    component: Organizations,
    role: ['super']
  },
  {
    title: 'Usuarios',
    path: '/users',
    icon: User,
    component: Users,
    role: ['super', 'admin']
  },
  {
    title: 'Proyectos',
    path: '/projects',
    icon: DocumentText,
    component: Projects
  },
  {
    title: 'Extractor',
    path: '/neodata',
    icon: Download,
    component: NeoData
  }
]
