import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ProjectsActions } from './duck'
import { Box, DropButton, Heading, Table, TableBody, TableCell, TableHeader, TableRow, Text } from 'grommet'
import { withRouter } from 'react-router-dom'
import OverlayLoading from 'components/OverlayLoading'
import Button from 'components/Button'
import { push } from 'connected-react-router'
import withTheme from 'components/HOC/withTheme'
import moment, { months } from 'moment'
import Divider from 'components/Divider'
import DayPickerInput from 'components/Controlled/InputDate'

import SvgReportes from '../../icons/Reportes'
import SvgEstructurascuentas from '../../icons/Estructurascuentas'
import SvgMapeoexterno from '../../icons/Mapeoexterno'
import SvgCostoavance from '../../icons/Costoavance'
import SvgCosto from '../../icons/Costo'
import SvgFactores from '../../icons/Factores'
import SvgTiposdecargo from '../../icons/Tiposdecargo'
import SvgInsumo from '../../icons/Insumo'
import { UncontrolledTooltip, Table as TableStrap } from 'reactstrap'
import hexToRgba from 'hex-to-rgba'
import { Close, StopFill, PlayFill } from 'grommet-icons/es6'
import { Flex } from 'reflexbox/src'
import SvgNuevo from '../../icons/Nuevo'
import SvgGuardar from '../../icons/Guardar'
import { formToArray, optionalProperty } from '../../helpers'
import SvgBasura from '../../icons/Basura'
import SvgLapiz from '../../icons/Lapiz'
import TypeChargeComponent from './Projects/Components/type_charge'
import TypeChargeComponentRead from './Projects/Components/type_charge_read'
import { AiOutlineDelete } from 'react-icons/ai'
import { Drag } from 'grommet-icons'
import { Button as GButton } from 'grommet/components/Button'
import Modal from '../../components/Modal'
import ButtonCore from '../../components/Button'

const dispatch = {
  fetchProject: ProjectsActions.fetchProject,
  fetchProjects: ProjectsActions.fetchProjects,
  clean: ProjectsActions.cleanView,
  getBudget: ProjectsActions.getBudgetById,
  navigate: push,
  getPeriods: ProjectsActions.getPeriodsOfBudget,
  togglePeriod: ProjectsActions.togglePeriod,
  updatePeriod: ProjectsActions.updatePeriod,
  createPeriod: ProjectsActions.createPeriod,
  deletePeriod: ProjectsActions.deletePeriod,
  deleteBudget: ProjectsActions.deleteBudget,
  getChargeTypesLocal: ProjectsActions.getChargeTypesOperation,
  getChargeType: ProjectsActions.getChargeTypeByAccountCostCT,

  getAccountCosts: ProjectsActions.getAccountCosts,
  setProyectSelected: ProjectsActions.setProjectSelectedOperation
}

const state = state => ({
  fetching: state.projects.fetchingProject,
  fetchingPeriods: state.projects.fetchingPeriods,
  periods: state.projects.periodsFromBudget,
  project: state.projects.project,
  majorProjects: state.projects.projects,
  hasProject: state.auth.orgSelected,
  accessControl: state.auth.orgSelected.accessControl
})

@withRouter
@withTheme
@connect(state, dispatch)
class ProjectViewer extends Component {
  state = {
    showVersions: false,
    selected: null,
    budgetSelected: null,
    editingTypeChange: false,
    showPeriods: false,
    lastTime: null,
    creatingPeriod: false,
    periodForm: {},
    periodBeingUpdated: null
  }

  componentWillUnmount = () => {
    this.props.clean()
  }

  componentWillReceiveProps (nextProps, nextContext) {
    const { match: { params: { id = 1 } }, fetchProject, project, fetching } = this.props

    if (nextProps.hasProject && id && !fetching) fetchProject(id)
  }

  componentDidMount = () => {
    const { match: { params: { id = 1 } }, fetchProject, fetchProjects, hasProject, project, fetching } = this.props
    if (hasProject && id && !project && !fetching) {
      fetchProject(id)
    }
  }

  edit = (id) => {
    this.setState(prevState => ({
      selected: !id ? null : id,
      showVersions: !prevState.showPeriods ? (!prevState.showVersions) : false,
      showReports: false
    }))
  }
  reports = (id) => {
    this.setState(prevState => ({
      selected: !id ? null : id,
      showReports: !prevState.showPeriods ? (!prevState.showReports) : false,
      showVersions: false
    }))
  }

  togglePeriods = id => () => {
    this.setState(prevState => ({
      showPeriods: !prevState.showPeriods,
      showVersions: false,
      showReports: false,
      lastTime: Date.now(),
      selected: id,
      budgetSelected: !prevState.showPeriods && id
    }), () => {
      this.state.showPeriods && this.props.getPeriods(id)
    })
  }

  clearForm = () => {
    this.setState({
      periodForm: {}
    })
  }

  goTo = path => () => {
    this.props.navigate(path)
  }

  goToProgress = (periodId, budgetId) => () => {
    const { match } = this.props

    this.props.navigate(`${match.url}/budgets/${budgetId}/progress/${periodId}`)
  }

  goToReal = (periodId, id) => () => {
    const { match } = this.props

    this.props.navigate(`${match.url}/budgets/${id}/real/${periodId}`)
  }

  togglePeriodUpdate = (start_date, periodId) => () => {
    this.setState(prevState => ({
      creatingPeriod: !prevState.creatingPeriod,
      periodForm: {
        ...prevState.periodForm,
        ...(!prevState.creatingPeriod ? { start_date } : {})
      },
      periodBeingUpdated: !prevState.creatingPeriod ? periodId : null
    }))
  }

  viewBudgetAux = (event, id) => {
    let auxString = event.target.getAttribute('class')
    this.setState(() => {
      budgetSelected: id
    })
    if (auxString && auxString.includes('clickAux')) {
      this.viewBudget(id)
    }
  }

  viewBudget = (id) => {
    const { match } = this.props
    this.props.navigate(`${match.url}/budgets/${id}`)
  }

  viewReport = (id, report) => {
    const { match } = this.props
    this.props.navigate(`${match.url}/budgets/${id}/report/${report}`)
  }

  validatePeriodForm = values => {
    const { periodForm } = this.state

    const startDateValidations = {
      ...optionalProperty(values.start_date ? null : 'Rellena este campo', 'start_date')
    }

    const endDateValidations = {
      ...optionalProperty(values.end_date ? null : 'Rellena este campo', 'end_date')
    }

    return {
      ...startDateValidations,
      ...endDateValidations
    }
  }

  updateField = field => e => {
    const event = e.nativeEvent

    this.setState(prevState => ({
      periodForm: {
        ...prevState.periodForm,
        [field]: event.target.value
      }
    }))
  }

  updateDate = name => day => {
    const date = moment(day).format('DD/MMMM/YYYY')

    this.updateField(name)({ nativeEvent: { target: { value: date } } })
  }

  sendCreation = e => {
    const { budgetSelected } = this.state
    const { match } = this.props

    this.props.createPeriod(
      +match.params.id,
      budgetSelected
    )
  }

  sendUpdate = e => {
    e.preventDefault()

    const { budgetSelected, periodForm, periodBeingUpdated } = this.state

    this.props.updatePeriod(
      moment(periodForm.end_date, 'DD/MMMM/YYYY').toDate().getTime(),
      budgetSelected,
      periodBeingUpdated
    )

    this.togglePeriodUpdate()()
    this.clearForm()
  }

  setTypeChange = (id) => {
    this.props.getChargeTypesLocal(id)
    this.props.getAccountCosts(id)
    this.props.setProyectSelected(id)
    this.setState(prevState => ({
      ...prevState.selections,
      width50: true,
      editingTypeChange: true,
      showReports: false,
      selected: id
    }))
  }

  closeTypeChange = () => {
    this.setState(prevState => ({
      ...prevState.selections,
      width50: false,
      editingTypeChange: false
    }))
  }

  render = () => {
    const { fetching, project, theme, match, fetchingPeriods, periods, majorProjects, accessControl, hasProject } = this.props
    const { periodForm } = this.state

    const canInteract = accessControl ? accessControl[match.params.id] : false
    const projectSelected = this.state.selected && project[this.state.selected - 1]

    const editingTypeChange = this.state.editingTypeChange

    const activePeriod = (periods || []).filter(({ active: isActive }) => isActive).pop()

    const majorProjectSelected = (this.props.majorProjects || []).filter(({ id }) => id === +match.params.id).pop() || null
    const formValidated = this.validatePeriodForm(periodForm)
    const { params: { id } } = this.props.match
    let nameProject
    majorProjects && majorProjects.forEach((e) => {
      if (Number(e.id) === Number(id)) {
        nameProject = e.name
      }
    })

    const getCorrectBudget = () => {
      const lengthAux = projectSelected.periods.length
      if ((projectSelected.periods[lengthAux - 1].active_advance.toString() === '1' || projectSelected.periods[lengthAux - 1].active_real.toString() === '1') && projectSelected.periods[lengthAux - 2]) {
        return projectSelected.periods[lengthAux - 2].budget_id
      } else {
        return projectSelected.periods[lengthAux - 1].budget_id
      }
    }

    return (
      <Box
        direction='row'
        flex
        align='start'
        justify='baseline'
        wrap
        fill
      >
        <Box
          pad='medium'
          background='white'
          round='xsmall'
          flex
          fill='vertical'
          width='large'
          style={{ position: 'relative' }}
        >
          {majorProjects
            ? <div style={{ fontSize: '9pt', color: 'gray' }}>
              <span style={{ fontSize: '9pt', color: 'black', cursor: 'pointer' }} onClick={this.goTo('/projects')}>Proyectos</span>
              <span style={{ fontSize: '9pt', color: 'gray' }}> / {nameProject} </span>
            </div>
            : null
          }
          <OverlayLoading pose={fetching || !majorProjectSelected ? 'in' : 'out'} />
          <Box
            direction='row'
            align='center'
            justify='between'
          >
            <Heading
              margin={{ vertical: 'xsmall' }}
              level={3}
            >
              Presupuestos
            </Heading>
          </Box>
          <Box margin={{ vertical: 'small' }}>
            <Divider color='black' />
          </Box>
          <div style={{ overflowY: 'auto' }}>
            {(!project || project.length === 0)
              ? (
                <Text>No hay presupuestos</Text>
              ) : (
                <Table style={{ width: '100%' }} caption='Organizaciones'>
                  <TableHeader style={{ backgroundColor: theme.primary }}>
                    <TableRow>
                      <TableCell scope='col'>
                        <Text color={theme.font} weight='bold'>Nombre</Text>
                      </TableCell>
                      <TableCell scope='col'>
                        <Text color={theme.font} weight='bold'>Versiones</Text>
                      </TableCell>
                      <TableCell scope='col'>
                        <Text color={theme.font} weight='bold'>Acciones</Text>
                      </TableCell>
                    </TableRow>
                  </TableHeader>
                  <tbody>
                    {project.map(({ name, versions, id }, i) => (
                      // <TableRow className={'clickAux'} key={i} style={{ borderBottom: `1px solid ${hexToRgba(theme.primary, 0.3)}`, cursor: 'pointer', zIndex: -1, backgroundColor: (this.state.selected && this.state.selected === i + 1) && 'rgba(0, 0, 0, 0.1)' }} onClick={(e) => this.viewBudgetAux(e, versions[0].id)}>
                      <TableRow className={'clickAux'} key={i} style={{ borderBottom: `1px solid ${hexToRgba(theme.primary, 0.3)}`, cursor: 'pointer', zIndex: -1, backgroundColor: (this.state.selected && this.state.selected === i + 1) && 'rgba(0, 0, 0, 0.1)' }} onClick={(e) => { this.edit(i + 1); this.closeTypeChange() }}>
                        <TableCell className={'clickAux'} scope='row'>
                          <Text className={'clickAux'}>{name}</Text>
                        </TableCell>
                        <TableCell className={'clickAux'} scope='row'>
                          <Text className={'clickAux'}> {versions.length || 0}</Text>
                        </TableCell>
                        <TableCell className={'clickAux'} scope='col'>
                          <Box
                            direction='row'
                            justify='end'
                            align='center'
                            className={'clickAux'}
                          >
                            {/* <Box margin={{ horizontal: 'xsmall' }}> */}
                            {/*  <Button */}
                            {/*    icon */}
                            {/*    color={theme.primary} */}
                            {/*    id={`consumable-${i}`} */}
                            {/*    onClick={() => this.setTypeChange(id)} */}
                            {/*  > */}
                            {/*    <SvgInsumo style={{ stroke: theme.font }} width={80} /> */}
                            {/*    <UncontrolledTooltip delay={0} placement='bottom' target={`consumable-${i}`} >Insumo</UncontrolledTooltip> */}
                            {/*  </Button> */}
                            {/* </Box> */}
                            {canInteract && <Box margin={{ horizontal: 'xsmall' }}>
                              <Button
                                icon
                                color={theme.primary}
                                id={`chargeType-${i}`}
                                onClick={(e) => { this.setTypeChange(versions[0].id); e.stopPropagation() }}
                              >
                                <UncontrolledTooltip delay={0} placement='bottom' target={`chargeType-${i}`}>Tipos de Cargo</UncontrolledTooltip>
                                <SvgTiposdecargo style={{ stroke: theme.font }} width={80} />
                              </Button>
                            </Box>}
                            <Box margin={{ horizontal: 'xsmall' }}>
                              <Button
                                icon
                                color={theme.primary}
                                onClick={() => {
                                  const version = (versions || []).sort(({ version: nVersion }, { version: oVersion }) => oVersion - nVersion)[0].id
                                  this.props.navigate(`${match.url}/budgets/${version}/factors`)
                                }}
                                id={`factors-${i}`}
                              >
                                <SvgFactores style={{ stroke: theme.font }} width={80} />
                                <UncontrolledTooltip delay={0} placement='bottom' target={`factors-${i}`}>Factores</UncontrolledTooltip>
                              </Button>
                            </Box>
                            <Box margin={{ horizontal: 'xsmall' }}>
                              <Button
                                icon
                                onClick={() => {
                                  this.closeTypeChange()
                                  const version = (versions || []).sort(({ version: nVersion }, { version: oVersion }) => oVersion - nVersion)[0].id
                                  this.togglePeriods(version)()
                                }}
                                color={theme.primary}
                                id={`advanceCost-${i}`}
                              >
                                <SvgCostoavance style={{ stroke: theme.font }} width={80} />
                                <UncontrolledTooltip delay={0} placement='bottom' target={`advanceCost-${i}`}>Costo avance</UncontrolledTooltip>
                              </Button>
                            </Box>
                            <Box margin={{ horizontal: 'xsmall' }}>
                              <Button
                                icon
                                color={theme.primary}
                                onClick={() => {
                                  const version = (versions || []).sort(({ version: nVersion }, { version: oVersion }) => oVersion - nVersion)[0].id
                                  this.props.navigate(`${match.url}/budgets/${version}/map`)
                                }}
                                id={`externalMapping-${i}`}
                              >
                                <SvgMapeoexterno style={{ stroke: theme.font }} width={80} />
                                <UncontrolledTooltip delay={0} placement='bottom' target={`externalMapping-${i}`}>Mapeo</UncontrolledTooltip>
                              </Button>
                            </Box>
                            <Box margin={{ horizontal: 'xsmall' }}>
                              <Button
                                icon
                                onClick={(e) => {
                                  this.reports(i + 1)
                                  this.closeTypeChange()
                                  e.stopPropagation()
                                }}
                                color={theme.primary}
                                id={`reports-${i}`}
                              >
                                <SvgReportes style={{ stroke: theme.font }} width={80} />
                                <UncontrolledTooltip delay={0} placement='bottom' target={`reports-${i}`}>Reportes</UncontrolledTooltip>
                              </Button>
                            </Box>
                            <Box margin={{ horizontal: 'xsmall' }}>
                              <Modal onClose={() => this.setState({ errorModal: false, inputConfirm: '' })} show={this.state.errorModal && this.state.errorModalIndex === i} title='¡Advertencia!'>
                                <Box onClick={(e) => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                }} width='medium'>
                                  <p>
                                    Esta acción no se puede deshacer, se borraran todos los datos referentes a este presupuesto(con todas sus versiones) y no se podrán recuperar, para confirmar escribe BORRAR
                                  </p>
                                  <input onChange={(e) => {
                                    this.setState({ inputConfirm: e.target.value })
                                  }} placeholder={'Introduce "BORRAR"'} />
                                  <Box
                                    direction='row'
                                    justify='end'
                                    align='center'
                                  >
                                    <Button
                                      type='submit'
                                      required
                                      style={{
                                        margin: '10px'
                                      }}
                                      onClick={() => {
                                        versions.forEach((e) => {
                                          this.props.deleteBudget(e.id)
                                          this.setState({ errorModal: false, inputConfirm: '', errorModalIndex: null })
                                          setTimeout(() => {
                                            window.location.reload()
                                          }, 750)
                                        })
                                      }}
                                      disabled={this.state.inputConfirm !== 'BORRAR'}
                                      color={theme.primary}
                                    >
                                      Confirmar
                                    </Button>
                                  </Box>
                                </Box>
                              </Modal>
                              {canInteract && <><DropButton
                                plain
                                onClick={(e) => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                }}
                                label={<Drag size='medium' />}
                                id={`options-${i}`}
                                dropContent={
                                  <Box
                                    round='small'
                                  >
                                    <Box margin='small'>
                                      <GButton
                                        plain
                                        onClick={(ev) => {
                                          ev.preventDefault()
                                          ev.stopPropagation()
                                          this.setState({ errorModal: true, errorModalIndex: i })
                                          return true
                                        }}
                                      >
                                        <Text color={theme.primary}>Eliminar</Text>
                                      </GButton>
                                    </Box>
                                  </Box>
                                }
                                dropAlign={{ bottom: 'top', top: 'bottom', left: 'left' }}
                              />
                                <UncontrolledTooltip delay={0} placement='bottom' target={`options-${i}`}>Opciones</UncontrolledTooltip></>}
                            </Box>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </tbody>
                </Table>
              )}
          </div>
        </Box>
        {this.state.showVersions && (
          <Box
            pad='medium'
            background='white'
            round='xsmall'
            flex
            fill='vertical'
            width='large'
            style={{ position: 'relative' }}
            margin={{ horizontal: 'small' }}
          >
            <OverlayLoading pose={fetching ? 'in' : 'out'} />
            <Box
              direction='row'
              align='center'
              justify='between'
            >
              <Heading
                margin={{ vertical: 'xsmall' }}
                level={3}
              >
                Versiones de {projectSelected && projectSelected.name}
              </Heading>
              <Button color='white' icon onClick={() => this.edit()}>
                <Close color='black' />
              </Button>
            </Box>
            <Box margin={{ vertical: 'xsmall' }}>
              <Divider color='black' />
            </Box>
            <div style={{ overflowY: 'auto' }}>
              {(!projectSelected.versions || !projectSelected.versions.length)
                ? (
                  <Text>No hay versiones</Text>
                ) : (
                  <Table style={{ width: '100%' }} caption='Organizaciones'>
                    <TableHeader style={{ backgroundColor: theme.primary }}>
                      <TableRow>
                        <TableCell scope='col'>
                          <Text color={theme.font} weight='bold'>Versión</Text>
                        </TableCell>
                        <TableCell scope='col'>
                          <Text color={theme.font} weight='bold'>Fecha de modificación</Text>
                        </TableCell>
                        {canInteract && <TableCell scope='col'>
                          <Text color={theme.font} weight='bold'>
                            Eliminar
                          </Text>
                        </TableCell>}
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {projectSelected.versions
                        .sort(({ version: nVersion }, { version: oVersion }) => oVersion - nVersion)
                        .map(({ updated_at, version, id }, i) => (
                          <TableRow key={i} style={{ borderBottom: `1px solid ${hexToRgba(theme.primary, 0.3)}`, cursor: 'pointer' }} onClick={() => this.viewBudget(id)}>
                            <TableCell scope='row'>
                              <Text>{version}</Text>
                            </TableCell>
                            <TableCell scope='row'>
                              <Text>{updated_at}</Text>
                            </TableCell>
                            <TableCell scope='row'>
                              <Modal onClose={() => this.setState({ errorModalOne: false, inputConfirm: '', errorModalOneIndex: null })} show={this.state.errorModalOne && this.state.errorModalOneIndex === i} title='¡Advertencia!'>
                                <Box onClick={(e) => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                }} width='medium'>
                                  <p>
                                    Esta acción no se puede deshacer, se borraran todos los datos referentes a esta versión del presupuesto y no se podrán recuperar, para confirmar escribe BORRAR
                                  </p>
                                  <input onChange={(e) => {
                                    this.setState({ inputConfirm: e.target.value })
                                  }} placeholder={'Introduce "BORRAR"'} />
                                  <Box
                                    direction='row'
                                    justify='end'
                                    align='center'
                                  >
                                    <Button
                                      type='submit'
                                      required
                                      style={{
                                        margin: '10px'
                                      }}
                                      onClick={() => {
                                        this.props.deleteBudget(id)
                                        this.setState({ errorModalOne: false, inputConfirm: '', errorModalOneIndex: null })
                                        setTimeout(() => {
                                          window.location.reload()
                                        }, 750)
                                      }}
                                      disabled={this.state.inputConfirm !== 'BORRAR'}
                                      color={theme.primary}
                                    >
                                      Confirmar
                                    </Button>
                                  </Box>
                                </Box>
                              </Modal>
                              {i === 0 && canInteract &&
                                <div onClick={(ev) => {
                                  ev.preventDefault()
                                  ev.stopPropagation()
                                  this.setState({ errorModalOne: true, errorModalOneIndex: i })
                                  return true
                                }}>
                                  <SvgBasura width={25} style={{ fill: 'red' }} />
                                  <Text>Borrar</Text>
                                </div>
                              }
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                )}
            </div>
          </Box>
        )}
        {this.state.showReports && (
          <Box
            pad='medium'
            background='white'
            round='xsmall'
            flex
            fill='vertical'
            width='large'
            style={{ position: 'relative' }}
            margin={{ horizontal: 'small' }}
          >
            <OverlayLoading pose={fetching ? 'in' : 'out'} />
            <Box
              direction='row'
              align='center'
              justify='between'
            >
              <Heading
                margin={{ vertical: 'xsmall' }}
                level={3}
              >
                Reportes de {projectSelected && projectSelected.name}
              </Heading>
              <Button color='white' icon onClick={() => this.reports()}>
                <Close color='black' />
              </Button>
            </Box>
            <Box margin={{ vertical: 'xsmall' }}>
              <Divider color='black' />
            </Box>
            <div style={{ overflowY: 'auto' }}>
              <Table style={{ width: '100%' }} caption='Organizaciones'>
                <TableHeader style={{ backgroundColor: theme.primary }}>
                  <TableRow>
                    <TableCell style={{ 'width': '350px' }} scope='col'>
                      <Text color={theme.font} weight='bold'>Reportes Tabulares</Text>
                    </TableCell>
                    <TableCell style={{ 'width': '150px' }} scope='col'>
                      <Text color={theme.font} weight='bold'>Acciones</Text>
                    </TableCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  <TableRow onClick={() => this.viewReport(projectSelected.versions[0].id, 'spreadAccounts')} style={{ borderBottom: `1px solid ${hexToRgba(theme.primary, 0.3)}`, cursor: 'pointer' }} >
                    <TableCell scope='col'>
                      <Text>Avance Vs. Costo - Cuentas de Costo</Text>
                    </TableCell>
                    <TableCell scope='col'>
                      <Text>Abrir</Text>
                    </TableCell>
                  </TableRow>
                  <TableRow onClick={() => this.viewReport(getCorrectBudget(), 'spreadTypes')} style={{ borderBottom: `1px solid ${hexToRgba(theme.primary, 0.3)}`, cursor: 'pointer' }} >
                    <TableCell scope='col'>
                      <Text>Avance Vs. Costo - Tipos de Cargo</Text>
                    </TableCell>
                    <TableCell scope='col'>
                      <Text>Abrir</Text>
                    </TableCell>
                  </TableRow>
                  <TableRow onClick={() => this.viewReport(getCorrectBudget(), 'spreadUnitCosts')} style={{ borderBottom: `1px solid ${hexToRgba(theme.primary, 0.3)}`, cursor: 'pointer' }} >
                    <TableCell scope='col'>
                      <Text>Costos Unitarios por Cuenta</Text>
                    </TableCell>
                    <TableCell scope='col'>
                      <Text>Abrir</Text>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Table style={{ width: '100%' }} caption='Organizaciones'>
                <TableHeader style={{ backgroundColor: theme.primary }}>
                  <TableRow>
                    <TableCell style={{ 'width': '350px' }} scope='col'>
                      <Text color={theme.font} weight='bold'>Reportes Gráficos</Text>
                    </TableCell>
                    <TableCell style={{ 'width': '150px' }} scope='col'>
                      <Text color={theme.font} weight='bold'>Acciones</Text>
                    </TableCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  <TableRow onClick={() => this.viewReport(projectSelected.versions[0].id, 'graphsHistory')} style={{ borderBottom: `1px solid ${hexToRgba(theme.primary, 0.3)}`, cursor: 'pointer' }} >
                    <TableCell scope='col'>
                      <Text>Avance Vs. Costo - Histórico</Text>
                    </TableCell>
                    <TableCell scope='col'>
                      <Text>Abrir</Text>
                    </TableCell>
                  </TableRow>
                  <TableRow onClick={() => this.viewReport(projectSelected.versions[0].id, 'graphsActual')} style={{ borderBottom: `1px solid ${hexToRgba(theme.primary, 0.3)}`, cursor: 'pointer' }} >
                    <TableCell scope='col'>
                      <Text>Avance Vs. Costo - Actual</Text>
                    </TableCell>
                    <TableCell scope='col'>
                      <Text>Abrir</Text>
                    </TableCell>
                  </TableRow>
                  <TableRow onClick={() => this.viewReport(projectSelected.versions[0].id, 'graphsKPI')} style={{ borderBottom: `1px solid ${hexToRgba(theme.primary, 0.3)}`, cursor: 'pointer' }} >
                    <TableCell scope='col'>
                      <Text>Indicadores Histórico</Text>
                    </TableCell>
                    <TableCell scope='col'>
                      <Text>Abrir</Text>
                    </TableCell>
                  </TableRow>
                  <TableRow onClick={() => this.viewReport(projectSelected.versions[0].id, 'graphsUnitsCosts')} style={{ borderBottom: `1px solid ${hexToRgba(theme.primary, 0.3)}`, cursor: 'pointer' }} >
                    <TableCell scope='col'>
                      <Text>Costos Unitarios por Cuenta</Text>
                    </TableCell>
                    <TableCell scope='col'>
                      <Text>Abrir</Text>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Table style={{ width: '100%' }} caption='Organizaciones'>
                <TableHeader style={{ backgroundColor: theme.primary }}>
                  <TableRow>
                    <TableCell style={{ 'width': '350px' }} scope='col'>
                      <Text color={theme.font} weight='bold'>Reportes Comparativos</Text>
                    </TableCell>
                    <TableCell style={{ 'width': '150px' }} scope='col'>
                      <Text color={theme.font} weight='bold'>Acciones</Text>
                    </TableCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  <TableRow onClick={() => this.viewReport(projectSelected.versions[0].id, 'compare')} style={{ borderBottom: `1px solid ${hexToRgba(theme.primary, 0.3)}`, cursor: 'pointer' }} >
                    <TableCell scope='col'>
                      <Text>Comparativa de Presupuestos</Text>
                    </TableCell>
                    <TableCell scope='col'>
                      <Text>Abrir</Text>
                    </TableCell>
                  </TableRow>
                  <TableRow onClick={() => this.viewReport(projectSelected.versions[0].id, 'compareAdvance')} style={{ borderBottom: `1px solid ${hexToRgba(theme.primary, 0.3)}`, cursor: 'pointer' }} >
                    <TableCell scope='col'>
                      <Text>Comparativa de Avances</Text>
                    </TableCell>
                    <TableCell scope='col'>
                      <Text>Abrir</Text>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </Box>
        )}
        {(editingTypeChange && (
          <TypeChargeComponentRead dataTypes={majorProjectSelected} budgetId={periodForm.selected} theme={theme} callBackSave={this.closeTypeChange.bind()} />
        ))}
        {this.state.showPeriods && (
          <Box
            pad='medium'
            background='white'
            round='xsmall'
            flex
            fill='vertical'
            width='large'
            style={{ position: 'relative' }}
            margin={{ horizontal: 'small' }}
          >
            <OverlayLoading pose={fetchingPeriods ? 'in' : 'out'} />
            <Box
              direction='row'
              align='center'
              justify='between'
            >
              <Heading
                margin={{ vertical: 'xsmall' }}
                level={3}
              >
                Periodos costo/avance {projectSelected && projectSelected.name}
              </Heading>
              <Button color='white' icon onClick={this.togglePeriods()}>
                <Close color='black' />
              </Button>
            </Box>
            <Box margin={{ vertical: 'xsmall' }}>
              <Divider color='black' />
            </Box>
            <div style={{ overflowY: 'auto', height: '100%' }}>
              {(!periods || !periods.length) && !this.state.creatingPeriod
                ? (
                  <>
                    {canInteract && <Flex
                      justify='flex-start'
                      w={1}
                      mb={2}
                    >
                      <Button
                        icon
                        color={theme.primary}
                        onClick={this.sendCreation}
                        disabled={periods.some((element) => element.active_advance.toString() === '1' || element.active_real.toString() === '1')}
                      >
                        <SvgNuevo width={80} style={{ fill: theme.font }} />
                      </Button>
                    </Flex>}
                    <Text>No hay periodos</Text>
                  </>
                ) : this.state.creatingPeriod
                  ? (
                    <form onSubmit={this.sendUpdate}>
                      <Box
                      >
                        <DayPickerInput
                          value={moment(periodForm.start_date).format('DD/MMMM/YYYY')}
                          label='Fecha inicio'
                          color={theme.primary}
                          name='start_date'
                          disabled
                          modifiers={{
                            disabled: {
                              before: new Date(majorProjectSelected.initial_date),
                              after: new Date(majorProjectSelected.close_date)
                            }
                          }}
                        />
                        <DayPickerInput
                          value={periodForm.end_date}
                          label='Fecha fin'
                          placeholder='Selecciona una fecha'
                          error={formValidated.end_date}
                          onDayChange={this.updateDate('end_date')}
                          color={theme.primary}
                          name='end_date'
                          month={new Date(periodForm.start_date)}
                          required//
                          modifiers={{
                            disabled: {
                              before: new Date(periodForm.start_date),
                              after: null
                            }
                          }}
                        />
                        <Flex
                          justify='space-between'
                        >
                          <Button
                            icon
                            color='white'
                            onClick={this.togglePeriodUpdate()}
                          >
                            <Close style={{ stroke: 'black' }} />
                          </Button>
                          <Button
                            icon
                            type='submit'
                            color={theme.primary}
                            disabled={Object.keys(formValidated).length}
                          >
                            <SvgGuardar width={80} style={{ fill: theme.font }} />
                          </Button>
                        </Flex>
                      </Box>
                    </form>
                  )
                  : (
                  <>
                    {canInteract && <Flex
                      justify='flex-start'
                      w={1}
                      mb={2}
                    >
                      <Button
                        icon
                        color={theme.primary}
                        onClick={this.sendCreation}
                        disabled={periods.some((element) => element.active_advance.toString() === '1' || element.active_real.toString() === '1')}
                      >
                        <SvgNuevo width={80} style={{ fill: theme.font }} />
                      </Button>
                    </Flex>}
                    <Table style={{ width: '100%' }} caption='Organizaciones'>
                      <TableHeader style={{ backgroundColor: theme.primary }}>
                        <TableRow>
                          <TableCell align='center' scope='col'>
                            <Text color={theme.font} weight='bold'>Núm.</Text>
                          </TableCell>
                          <TableCell align='center' scope='col'>
                            <Text color={theme.font} weight='bold'>Versión P.</Text>
                          </TableCell>
                          <TableCell scope='col'>
                            <Text color={theme.font} weight='bold'>Fecha inicio</Text>
                          </TableCell>
                          <TableCell scope='col'>
                            <Text color={theme.font} weight='bold'>Fecha fin</Text>
                          </TableCell>
                          <TableCell scope='col' />
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {periods
                        // .sort(({version: nVersion}, {version: oVersion}) => oVersion - nVersion)
                          .map(({ start_date, end_date, active_advance, active_real, id, budget_id, version }, i) => (
                            <TableRow key={i} style={{
                              borderBottom: `1px solid ${hexToRgba(theme.primary, 0.3)}`
                            }}>
                              <TableCell align='center' scope='row'>
                                <Text style={{ textAlign: 'center' }}>{periods.length - i}</Text>
                              </TableCell>
                              <TableCell align='center' scope='row'>
                                <Text alignSelf='center'>{version || 'NA'}</Text>
                              </TableCell>
                              <TableCell scope='row'>
                                <Text>{moment(new Date(start_date)).format('DD-MM-YYYY')}</Text>
                              </TableCell>
                              <TableCell scope='row'>
                                <Text>{moment(new Date(end_date)).format('DD-MM-YYYY')}</Text>
                              </TableCell>
                              <TableCell scope='row'>
                                <Flex
                                  align='center'
                                  justify='flex-end'
                                >
                                  <Box
                                    margin={{ left: 'small' }}
                                    style={{ backgroundColor: active_advance.toString() === '1' ? '#6ae06a' : 'grey', padding: '4px', borderRadius: '10px' }}
                                  >
                                    <Button
                                      icon
                                      color={theme.primary}
                                      onClick={this.goToProgress(id, budget_id)}
                                      id={`periodAdvance-${i}`}
                                    >
                                      <SvgCosto width={25} style={{ fill: theme.font }} />
                                      <UncontrolledTooltip delay={0} placement='bottom' target={`periodAdvance-${i}`}>Avance
                                        Físico { active_advance.toString() === '1' ? '(Abierto)' : '(Cerrado)'}</UncontrolledTooltip>
                                    </Button>
                                  </Box>
                                  <Box
                                    margin={{ left: 'small' }}
                                    style={{ backgroundColor: active_real.toString() === '1' ? '#6ae06a' : 'grey', padding: '4px', borderRadius: '10px' }}
                                  >
                                    <Button
                                      icon
                                      color={theme.primary}
                                      onClick={this.goToReal(id, budget_id)}
                                      id={`realAdvance-${i}`}
                                    >
                                      <SvgFactores width={25} style={{ fill: theme.font }} />
                                      <UncontrolledTooltip delay={0} placement='bottom' target={`realAdvance-${i}`}>Costo Real { active_real.toString() === '1' ? '(Abierto)' : '(Cerrado)'}
                                      </UncontrolledTooltip>
                                    </Button>
                                  </Box>
                                  { canInteract && (active_advance.toString() === '1' || active_real.toString() === '1')
                                    ? <Box
                                      margin={{ left: 'small' }}
                                    >
                                      <Button
                                        icon
                                        color={theme.primary}
                                        onClick={this.togglePeriodUpdate(start_date, id)}
                                        id={`editAdvance-${i}`}
                                      >
                                        <SvgLapiz width={25} style={{ fill: theme.font }} />
                                        <UncontrolledTooltip delay={0} placement='bottom' target={`editAdvance-${i}`}>Editar</UncontrolledTooltip>
                                      </Button>
                                    </Box>
                                    : null
                                  }
                                  {
                                    canInteract && (periods.some((element) => element.active_advance.toString() === '1' || element.active_real.toString() === '1') && i === 0)
                                      ? (
                                        <Box
                                          margin={{ left: 'small' }}
                                        >
                                          <Button
                                            icon
                                            color={theme.primary}
                                            onClick={() => {
                                              this.props.deletePeriod(id, budget_id)
                                            }}
                                            id={`deleteAdvance-${i}`}
                                          >
                                            <SvgBasura width={25} style={{ fill: theme.font }} />
                                            <UncontrolledTooltip delay={0} placement='bottom' target={`deleteAdvance-${i}`}>Eliminar</UncontrolledTooltip>
                                          </Button>
                                        </Box>
                                      )
                                      : null
                                  }
                                </Flex>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </>
                  )}
            </div>
          </Box>
        )}
      </Box>
    )
  }
}

export default ProjectViewer
