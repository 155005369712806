import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import Projects from './Projects/Projects'
import ProjectViewer from './ProjectViewer'
import {
  BudgetViewer, AccountCostsViewer, AccountMapViewer, BudgetMapperViewer, UnitFactorsViewer, ProgressViewer, RealViewer,
  ReportViewer, ReportViewerCA, ReportSpreadAccounts, ReportSpreadTypes, ReportSpreadUnitCosts, ReportGraphsHistory,
  ReportGraphsActual, ReportGraphsKPI, ReportGraphsUnitsCosts
} from './index'

class ProjectsContainer extends Component {
  render = () => {
    return (
      <Switch>
        <Route exact path='/projects/:id' component={ProjectViewer} />
        <Route exact path='/projects/costs/:id' component={AccountCostsViewer} />
        <Route exact path='/projects/map/:id' component={AccountMapViewer} />
        <Route exact path='/projects/:id/budgets/:bId' component={BudgetViewer} />
        <Route exact path='/projects/:id/budgets/:bId/report/compare' component={ReportViewer} />
        <Route exact path='/projects/:id/budgets/:bId/report/compareAdvance' component={ReportViewerCA} />
        <Route exact path='/projects/:id/budgets/:bId/report/spreadAccounts' component={ReportSpreadAccounts} />
        <Route exact path='/projects/:id/budgets/:bId/report/spreadTypes' component={ReportSpreadTypes} />
        <Route exact path='/projects/:id/budgets/:bId/report/spreadUnitCosts' component={ReportSpreadUnitCosts} />
        <Route exact path='/projects/:id/budgets/:bId/report/graphsHistory' component={ReportGraphsHistory} />
        <Route exact path='/projects/:id/budgets/:bId/report/graphsActual' component={ReportGraphsActual} />
        <Route exact path='/projects/:id/budgets/:bId/report/graphsKPI' component={ReportGraphsKPI} />
        <Route exact path='/projects/:id/budgets/:bId/report/graphsUnitsCosts' component={ReportGraphsUnitsCosts} />
        <Route exact path='/projects/:id/budgets/:bId/map' component={BudgetMapperViewer} />
        <Route exact path='/projects/:id/budgets/:bId/progress/:pId' component={ProgressViewer} />
        <Route exact path='/projects/:id/budgets/:bId/real/:pId' component={RealViewer} />
        <Route exact path='/projects/:id/budgets/:bId/factors' component={UnitFactorsViewer} />
        <Route component={Projects} />
      </Switch>
    )
  }
}

export default ProjectsContainer
