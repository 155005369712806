export const budget_view_action = {
  // UFUpdateLoading: ['status'],
  setProjectSelectedOperation: ['id'],
  getChargeTypesOperation: ['id'],    // Esta es mi función de pruebas para obtener daros de charge
  setChargeTypesOperation: ['payload'],
  getSuppliesByCharge: ['params'],
  setSuppliesByCharge: ['payload'],
  getConceptsByAccountCost: ['params'],
  getConceptsByAccountCostSecond: ['params'],
  setConceptsByAccountCosts: ['payload'],
  setConceptsByAccountCostsSecond: ['payload'],
  getSuppliesByConcept: ['params'],
  getSuppliesByConceptSecond: ['params'],
  setSuppliesByConcept: ['payload'],
  setSuppliesByConceptSecond: ['payload'],
  getMagicByAccountCost: ['payload'],
  getMagicByAccountCostGraph: ['payload'],
  getMagicByAccountCostSecond: ['payload'],
  setMagicByAccountCosts: ['payload'],
  setMagicByAccountCostsGraph: ['payload'],
  setMagicByAccountCostsSecond: ['payload'],
  updateArrayChargeTypesOperation: ['payload', 'id'],
  // Actions to view account cost
  getAccountCostChildAC: ['params'],
  getAccountCostChildACGraph: ['params'],
  getAccountCostChildACSecond: ['params'],
  setAccountCostChildAC: ['data'],
  setAccountCostChildACGraph: ['data'],
  setAccountCostChildACSecond: ['data'],

  // Actions to view charge type
  getChargeTypeByAccountCostCT: ['params'],
  getChargeTypeByAccountCostGraphCT: ['params'],
  getChargeTypeByAccountCostCTSecond: ['params'],
  setChargeTypeByAccountCostCT: ['data'],
  setChargeTypeByAccountCostGraphCT: ['data'],
  setChargeTypeByAccountCostCTSecond: ['data'],
  getAccountCostByChargeTypeCT: ['params'],
  getAccountCostByChargeTypeCTSecond: ['params'],
  setAccountCostByChargeTypeCT: ['data'],
  setAccountCostByChargeTypeCTSecond: ['data'],
  getSuppliesByChargeTypeCT: ['params'],
  setSuppliesByChargeTypeCT: ['data']

};
