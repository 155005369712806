import React from "react";

const SvgTiposdecargo = props => (
  <svg viewBox="0 0 141.7 141.7" {...props}>
    <path
      className="tiposdecargo_svg__st0"
      d="M124.1 134.2H17c-5.5 0-10-4.5-10-10V17.1c0-5.5 4.5-10 10-10h107.1c5.5 0 10 4.5 10 10v107.1c0 5.5-4.5 10-10 10zM17 13.8c-1.8 0-3.3 1.5-3.3 3.3v107.1c0 1.8 1.5 3.3 3.3 3.3h107.1c1.8 0 3.3-1.5 3.3-3.3V17.1c0-1.8-1.5-3.3-3.3-3.3H17z"
    />
    <path
      className="tiposdecargo_svg__st0"
      d="M114.1 30.1v.8c0 1.8-1.3 3.3-2.9 3.3H40.8c-1.6 0-2.9-1.5-2.9-3.3v-.8c0-1.8 1.3-3.3 2.9-3.3h70.3c1.6 0 3 1.5 3 3.3zM114.1 56.2v.8c0 1.8-1.3 3.3-2.9 3.3H40.8c-1.6 0-2.9-1.5-2.9-3.3v-.8c0-1.8 1.3-3.3 2.9-3.3h70.3c1.6 0 3 1.5 3 3.3zM114.1 82.3v.8c0 1.8-1.3 3.3-2.9 3.3H40.8c-1.6 0-2.9-1.5-2.9-3.3v-.8c0-1.8 1.3-3.3 2.9-3.3h70.3c1.6 0 3 1.5 3 3.3zM114.1 108.5v.8c0 1.8-1.3 3.3-2.9 3.3H40.8c-1.6 0-2.9-1.5-2.9-3.3v-.8c0-1.8 1.3-3.3 2.9-3.3h70.3c1.6-.1 3 1.4 3 3.3z"
    />
    <circle className="tiposdecargo_svg__st0" cx={28.2} cy={30.4} r={3.9} />
    <circle className="tiposdecargo_svg__st0" cx={28.2} cy={56.5} r={3.9} />
    <circle className="tiposdecargo_svg__st0" cx={28.2} cy={82.6} r={3.9} />
    <circle className="tiposdecargo_svg__st0" cx={28.2} cy={108.7} r={3.9} />
  </svg>
);

export default SvgTiposdecargo;
